import React from "react";

import { Link } from 'react-router-dom';

const CompanionHeader = ({companion, redirectLink=false, mobileOnly=false}) => {

  return (
    <div className={mobileOnly ? "mobile-only" : ""}>
      <div className="companion-info" >
        <div className="companion-top fr ac">
          <div className="fr ac">
            <img className="thumb-mini pad-right" src={require(`../../images/${companion.image}`)} alt="companion-logo"></img>
            <p className="bold">{companion.name}</p>
          </div>
          <div className="fr ac">
            { redirectLink && 
            <Link className="fr ac" to={`/companions/`} >
              <img className="thumb-mini pad-right" src={require(`../../images/undo.png`)} alt="companion-logo"></img>
              <p className="bold">companions</p>
            </Link> 
            }
          </div>
        </div>
        <p className="no-margin">{companion.description}</p>
      </div>
    </div>
  )

}

export default CompanionHeader;