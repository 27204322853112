import React from 'react';

import { Eligibility, SpotifyLink, AudioPreview } from '../Common';


const ShortlistTrack = ({imageSource, ranking, title, artist, audioSource, isEligible, 
    provided, triggerModalRemove, spotifyUrl}) => {

  return (
    <li className="top-track fr ac" ref={provided.innerRef} {...provided.draggableProps} 
      {...provided.dragHandleProps}>
      <img className="thumb-med" src={imageSource} alt="track"></img>
      <div className="track-info fc">
        <div>
          <div className="fr ac">
            <div className="ranking-title fr">
              <p className="ranking">{`#${ranking}.`}</p>
              <p className="default rank-title overflow-ellipsis">{title}</p>
            </div>
            <Eligibility eligibility={isEligible}/>
          </div>
          <p className="artist overflow-ellipsis bold">{artist}</p>
        </div>
        <div className="shortlist-actions">
          <AudioPreview audioSource={audioSource}/>
          <SpotifyLink url={spotifyUrl} />
          <div className="fr ac" onClick={() => triggerModalRemove(ranking)}>
            <img className="cross" src={require("../../images/close.png")} alt="cross" 
              ></img>
          </div>
        </div>
      </div>
    </li>
  )
};

export default ShortlistTrack;