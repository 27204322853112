import React, { useContext } from 'react';

import ShortlistTrack from './ShortlistTrack';

import { checkTrackEligibility } from '../../services/helpers';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import { TokenContext } from '../Providers/TokenProvider';
import { apiUpdateShortlist } from '../../services/functionApp';
import { RecordButtonClick } from '../../services/googleAnalyticsHelper';

const ShortlistHandler = ({ shortList, setShortList, triggerModalRemove }) => {

  const [ token ] = useContext(TokenContext);

  const handleDragEnd = async (result) => {
    
    RecordButtonClick(`Drag event triggered on Shortlist page`);

    const items = Array.from(shortList.tracks.sort((a, b) => a.index < b.index));
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    for (let i = 0; i < items.length; i++) items[i].index = i;
    const clone = JSON.parse(JSON.stringify(shortList));
    clone.tracks = items;
    setShortList(clone);
    await apiUpdateShortlist(token, items);
  }


  const renderShorlistTracks = () => {

    const sortedList = shortList.tracks.sort((a, b) => a.index < b.index)
    return sortedList.map((item, i) => {
      return (
        <Draggable key={i} draggableId={"" + i} index={i}>
          {(provided) => {
            return (
              <ShortlistTrack title={item.trackName} artist={item.artistName} 
                isEligible={checkTrackEligibility(item.releaseDate)} imageSource={item.imageSrc}
                audioSource={item.audioSrc} ranking={i+1}
                provided={provided} triggerModalRemove={triggerModalRemove}
                spotifyUrl={item.spotifyUrl} />
            )
          }}
        </Draggable>
      )
    })

  }

  return (
    <div>
      {shortList.tracks && shortList.tracks.length > 0 && 
        <p id="drag-reorder">*drag and drop tracks to reorder them</p> }

      {(!shortList.tracks || shortList.tracks.length === 0) && 
        <p data-test='no-shortlist-message'>No tracks in your shortlist yet. Search the Spotify API above and add some.</p>}

      {shortList.tracks && shortList.tracks.length > 0 && 
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="shortlist">
          {(provided) => (
            <ul id="top-tracks" className="fc" {...provided.droppableProps} ref={provided.innerRef}>
              {renderShorlistTracks()}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext> }
    </div>
  )
};

export default ShortlistHandler;