import axios from 'axios';

export async function axiosWrapper(method, url, data, headers={}) {
  //console.log(method, url, data, headers)
  const response = await axios({
    method,
    url,
    headers,
    data
  }).catch((error) => {
    if (error.response) return {error: error.response};
    if (error.request) return {error: error.request};
    else return {error};
  })
  //console.log(response);
  return response;
}


