import React from 'react';
import { Header } from '../Common';
import PastYearShortlist from './PastYearShortlist';
import "../../style/pastYearShortlist.css"


const PastYears = ({ pastYears }) => {


  const renderPastYears = () => {

    //Kinda weird because we're returning the pastyears as an object with the years as properties
    //Convert the object to a list so we can map over it
    let years = [];
    for (let year in pastYears) {
      const data = pastYears[year];
      data.year = year;
      years.push(data);
    }

    //Sort the years in descending order
    years.sort((a, b) => parseInt(a.year) < parseInt(b.year) ? 1 : -1);

    return years.map(a => {
      return (
        <PastYearShortlist year={a.year} tracks={a.tracks} key={a.year} />
      )
    });
    
  }


  return (
    <div id="pastYears">
      <Header 
        header="your past shortlists"
        subHeader="look back at your top picks for the previous years"
      />

      { renderPastYears() }
    </div>
  )
};

export default PastYears;