import React from 'react';

import { SpotifyLink } from '../Common';

const TopArtist = ({imageSource, index, artist, spotifyUrl}) => {
  
  return (
    <div className="top-artist fr ac">
      <img className="thumb-small" src={imageSource} alt="track"></img>
      <div className="album-content fc">
        <div className="artist-info">
          <p className="ranking">{`#${index}.`}</p>
          <p className="artist-title overflow-ellipsis bold">{artist}</p>
        </div>
        <SpotifyLink url={spotifyUrl} />
      </div>

    </div>

  )
};


export default TopArtist;