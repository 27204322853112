import React from "react";

import CountdownHandler from './CountdownHandler';
import CompanionHeader from "./CompanionHeader";
import { startTime } from "../../services/abc";
import { companionInfos } from "../Content/Content";

const CountdownTimer = ({companionIndex=0}) => {

  return (
    <>
      <CompanionHeader companion={companionInfos[companionIndex]}/>
      <CountdownHandler date={startTime} />
    </>
  );

}


export default CountdownTimer;