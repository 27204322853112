import React, { useState, useEffect, useContext } from 'react';

import { useParams, Link } from 'react-router-dom';
import { AuthPrompt, Loader } from '../../Common';
import Countdown from '../Countdown';
import NowPlaying from '../NowPlaying';
import Leaderboard from './Leaderboard';
import PlayerPicks from './PlayerPicks';
import { calculatePlayerScores } from '../../../services/GameHelpers';
import { top3Outside3MenuItems } from '../../Content/Content';
import NoLinkTopMenu from '../../Menu/NoLinkTopMenu';
import "../../../style/top3Outside3Live.css";
import CompanionHeader from '../CompanionHeader';
import { companionInfos } from '../../Content/Content';
import PlayHighlight from './PlayHighlight';
import { CompanionContext } from '../../Providers/CompanionProvider';
import { TokenContext } from '../../Providers/TokenProvider';
import CountdownTimer from '../CountdownTimer';
import { startTime } from '../../../services/abc';
import { TracksContext } from '../../Providers/TracksProvider';
import LiveRedirect from '../LiveRedirect';
import { apiGetCompanions } from '../../../services/functionApp';

const Top3Outside3Live = ({simulation=false}) => {
  
  const { liveId } = useParams();
  const [ companionData, setCompanionData ] = useContext(CompanionContext);
  const [ fetchingComp, setFetchingComp ] = useState(false);
  const [ playerScores, setPlayerScores ] = useState(false);
  const [ token ] = useContext(TokenContext);
  const [ tracks ] = useContext(TracksContext);
  const [ menuName, setMenuName ] = useState(top3Outside3MenuItems[0].name);
  const topMenuItems = top3Outside3MenuItems;



  useEffect(() => {

    const getPlayerScores = () => {
      if (companionData !== null) {  
        let clone = JSON.parse(JSON.stringify(companionData.top3Outside3.players));
        clone.players = calculatePlayerScores(tracks, companionData.top3Outside3.players);
        const sorted = clone.players.sort((a,b) => a.name < b.name ? 1 : -1);
        return sorted;
      }
      return null;
    }

    async function getCompanionData() {
      const companionsResponse = await apiGetCompanions(liveId);
      setCompanionData(companionsResponse);
      setFetchingComp(false);
    }

    if (liveId && !simulation && !companionData) { 
      getCompanionData(); 
      setFetchingComp(true);
    }

    if (companionData && tracks) {
      setPlayerScores(getPlayerScores());
    }

  }, [liveId, setCompanionData, simulation, playerScores, companionData, tracks]);




  const checkForPlayerUpdates = () => {

    const scores = playerScores;
    if (!scores) return [];

    //If the player has a track that has placing equal to the one playing now, then there is an update
    const updates = [];
    const placing = 101 - tracks.length;

    for (let player of scores) {
      for (let i = 0; i < player.tracks.length; i++) {
        if (player.tracks[i].placing === placing) updates.push({player, i, track: tracks[0]})
      }
    }

    return updates;

  }


  if (liveId && !simulation) {
    if (companionData === null || fetchingComp) {
      return <Loader content={"loading game..."} />
    }

    if (!companionData || companionData === "False") {
      return (
        <>   
          <CompanionHeader companion={companionInfos[0]} redirectLink={true} />     
          <AuthPrompt 
            header="sign up"
            subHeader="sign in through spotify to view your top 5 outside 5 companion game"
            redirectEndpoint="companions/top-5-outside-5/live"/>
          <h1>we couldn't find your game!</h1>
          <p>it looks like the id you entered "{liveId}" doesn't correspond with a registered game. feel free to try again below</p>
          <LiveRedirect companion={companionInfos[1].link} />
        </>
      );
    }

  }

  if (!token && !liveId) {
    return (
      <>
        <CompanionHeader companion={companionInfos[0]} redirectLink={true} />
        <AuthPrompt 
          header="sign up"
          subHeader="sign in through spotify to view your top 5 outside 5 companion game"
          redirectEndpoint="companions/top-5-outside-5/live"/>
        <LiveRedirect companion={companionInfos[0].link} />
      </>
    )
  }

  if (companionData && companionData.top3Outside3.players.length === 0) {
    return (
      <>
        <CompanionHeader companion={companionInfos[0]} redirectLink={true} />
        <h1>no data found</h1>
        <p>it looks like you don't have any players signed up for your top 5 outside 5 game</p>
        <Link to={`/companions/${companionInfos[0].link}/sign-up`}>
          <button className="btn">sign up here</button>
        </Link>
        <LiveRedirect companion={companionInfos[0].link} />
      </>
    );
  }

  if (Date.now() < startTime || tracks.length === 0) return <CountdownTimer />;

  return (
    <div id="playing-now">
   
      <div id="top-5-desktop-view">
        <CompanionHeader companion={companionInfos[0]} redirectLink={true} mobileOnly={true} />
        <NoLinkTopMenu topMenuItems={topMenuItems} menuName={menuName} setMenuName={setMenuName} mobileOnly={true} />

        <div className="fr">
          <Countdown tracks={tracks} selected={menuName === "playing"} />
          <PlayerPicks players={playerScores} selected={menuName === "players"} />
          <Leaderboard players={playerScores} selected={menuName === "ranking"} />
          { tracks.length > 0 && <NowPlaying tracks={tracks} />  }        
          
          { companionData &&  <PlayHighlight updates={checkForPlayerUpdates()} />}
        </div>
      </div>
    </div>
  )
}



export default Top3Outside3Live;