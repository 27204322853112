import React, { useRef } from 'react';

const TextInput = ({label, placeholder="", setState, validation, textBox=false }) => {

  const textState = useRef();

  const handleTextChange = () => {
    setState(textState.current.value);
  }

  const renderError = () => {
    if (!textState?.current) return;
    const text = textState.current.value;
    if (text === "") return <p className="error no-margin">field must not be empty</p>
    return; 
  }

  return (
    <div data-test={placeholder}>
      <div className="fr ac">
        <label className="form-label">{label}</label>
        {textBox && <textarea className="form-textarea" ref={textState} placeholder={placeholder} onChange={handleTextChange}></textarea>}
        {!textBox && <input className="form-text-input" type="text" placeholder={placeholder} ref={textState} onChange={handleTextChange}></input>}
      </div>
      {validation && renderError()}
    </div>
  );

};

export default TextInput;