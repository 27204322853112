import React from 'react';

import '../../style/header.css';

const Header = ({ header, subHeader }) => {

  return (
    <div id="header">
      <h1>{header}</h1>
      <p className="light-text header-subtitle">{subHeader}</p>
    </div>
  )
};

export default Header;