import React from 'react';

import ReactTooltip from 'react-tooltip';

const Eligibility = ({ eligibility }) => {

  return (
    <div>
      <div data-tip={`${eligibility ? 
            "eligible for countdown" : 
            "may not be eligible based on release date"}`}
            data-background-color={`${eligibility ? "#1b5616" : "#FF8A00"}`}>
        {eligibility && 
          <img className="eligibility fr" src={require("../../images/tick.png")} alt="eligibility"></img>
        }
        {!eligibility && 
          <img className="eligibility fr" src={require("../../images/warning.png")} alt="eligibility"></img>
        }
      </div>
      <ReactTooltip place="top" effect="float" />
    </div>
  )
};

export default Eligibility;
