import React, { useState, useEffect } from "react";
import { calculatePlayerBingoScores } from "../../../services/GameHelpers";

const PlayerBoards = ({boards, tracks, mobileView=false, selected=false, simulation=false}) => {
  
  const [ playerIndex, setPlayerIndex ] = useState(0);
  
  useEffect(() => {
    if (!mobileView && !simulation) setTimeout(() => setPlayerIndex(playerIndex + 1 === boards.length ? 0 : playerIndex + 1), 10000);
  }, [playerIndex, boards.length, mobileView, simulation])

  const renderBoard = (i) => {
    return (
      <>
        <h1>{boards[i].name}'s board</h1>
        <PlayerBoard board={calculatePlayerBingoScores(boards, tracks)[i]} />
      </>
    );
  }
  
  const renderAllBoards = () => {
    return boards.map((item, i) => {
      return (
        <div>
          <h1>{boards[i].name}'s board</h1>
          <PlayerBoard board={calculatePlayerBingoScores(boards, tracks)[i]} />
        </div>
      );
    });
  }
  
  const changeSelectedPlayer = (e) => {
    const newIndex = boards.findIndex((a) => a.name === e.target.value);
    setPlayerIndex(newIndex);
  }

  /**
  * For testing, want to be able to change which player is being shown so we can check the scores properly
  */
  const renderSimulationHelper = () => {
    return (  
      <div id="simulation-helper" onChange={changeSelectedPlayer}>
        <select>
        {boards.map((item, i) => {
          return <option value={item.name} key={item.name+i}>{item.name}</option>
        })}
        </select>
      </div>
    )
  }

  return (
    <div className={selected ? "desktop-section mobile-selected" : "desktop-section"}>
      {mobileView ? renderAllBoards() : renderBoard(playerIndex) }
      { simulation && renderSimulationHelper()}
    </div>
  );
}


const PlayerBoard = ({board}) => {

  const renderBoard = () => {

    return board.tracks.map((item, i) => {

      if (item?.type === "track") {
        return (
          <div className={`bingo-grid grid-${i%4} bingo-track fr ac jc point`} key={i} index={i}
              style={{ backgroundImage: `url(${item.imageSrc})` }}
              id={`bingo-track-${item.trackName.split(' ').join('-').toLowerCase()}`}>
              <div className={item.match ? "bingo-hold" : "full-width"}>
                <div className="bingo-pick-bg fc ac">
                  {item.match && <h3 className="ranking top-rank bingo-rank">#{item.match}</h3>}
                  <p className="default tac" >{item.trackName}</p>
                  <p className="bold tac">{item.artistName}</p>
                </div>
              </div>
          </div>
        )
      }

      if (item?.type === "artist") {
        return (
          <div className={`bingo-grid grid-${i%4} bingo-artist fr ac jc point`} key={i} index={i}
              style={{ backgroundImage: `url(${item.imageSrc})` }}
              id={`bingo-artist-${item.artistName.split(' ').join('-').toLowerCase()}`}>
            <div className={item.match ? "bingo-hold" : "full-width"}>
              <div className="bingo-pick-bg fc ac">
                {item.match && <h3 className="ranking top-rank bingo-rank">#{item.match}</h3>}
                <p className="bold tac">{item.artistName}</p>
              </div>
            </div>
          </div>
        )
      }
      
      return (
        <div className={`bingo-grid fr ac jc grid-${i%4}`} key={i} index={i} >
          <img className="add-s" src={require(`../../../images/close.png`)} alt="add player"></img>
          <p className="bold">N/A</p>
        </div>
      )

    });
  }

  return (
    <div id="bingo-board" className="fr">
      {renderBoard()}
    </div>
  )

}

export default PlayerBoards;