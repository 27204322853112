import React, { useState, useEffect } from 'react';

import { useLocation, Link } from 'react-router-dom';

import '../../style/topMenu.css';


const TopMenu = ({ topMenuItems }) => {


  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const location = useLocation();

  useEffect(() => {
    let index = topMenuItems.findIndex(e => {
      return location.pathname.includes(e.link);
    });

    if (index === selectedIndex || index === -1) return;

    setSelectedIndex(index);
  }, [location, selectedIndex, topMenuItems]);


  return (
    <div id="topMenu" className="fr ac">
      { topMenuItems.map((item, i) => <TopMenuItem key={i} item={item} isSelected={i===selectedIndex} />) }
    </div>
  )
};

const TopMenuItem = ({ item, isSelected }) => {


  return (
    <Link data-test={item.name.replaceAll(' ', '-')} to={item.link} className="point fc ac jc">
      <img className="top-menu-item-image" alt="menu item logo" 
      src={require(`../../images/menu-items/top-menu/${isSelected 
        ? item.imageSource + "-orang" 
        : item.imageSource}.png`)} >
      </img>

      <p className="top-menu-item-name">{item.name}</p>
      { isSelected && <div className="stroke-underline"></div> }
    </Link>
  )
};


export default TopMenu;