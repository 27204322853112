import React from 'react';

import '../../style/modal.css';

const Modal = ({ show, setShow, content }) => {

  if (!content.data) return (<div></div>);

  const renderModalMessage = () => {
    if (content.add) {
      return (
        <p>add <strong>{content.data.name}</strong> to your shortlist?</p>
      )
    }
    return (
      <p> remove <strong>{content.data.name}</strong> from your shortlist?</p>
    )
  }

  const triggerCallback = async () => {
    await content.confirmCallback(content.callbackPayload);
    setShow(false);
  }

  return (
    <div className={show ? "display-show" : "display-hide"}>
      <div className="modal-background"></div>
      <div className="modal">
        <img className="modal-image" src={content.data.imageSource} alt="track"></img>
        <div className="modal-content">
          {renderModalMessage()}
          <div className="modal-actions">
            <button className="confirm-button" onClick={triggerCallback}>confirm</button>
            <button className="exit-button" onClick={() => setShow(false)}>close</button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Modal;