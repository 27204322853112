import React from 'react';

import { Link } from 'react-router-dom';


const SlidingMenuItem = ({item, selected, setMenuOpen}) => {

  function checkRoute() {
    if (selected) { setMenuOpen(false) }
  }

  return (
    <Link className={`slidingMenuItem fr ac menu-${item.name.replaceAll(' ', '-')}`} 
        onClick={checkRoute} to={item.link} >
      { selected && <div className="sliding-menu-selected"></div> }
      <p className="sliding-menu-item-name" >{item.name}</p>
      <img className="sliding-menu-item-image" src={require(`../../images/menu-items/${item.imageSource}`)} alt="menu item logo"></img>
    </Link>
  )
};

export default SlidingMenuItem;