import React, { useContext, useState } from 'react';

import { Link } from 'react-router-dom';
import CompanionHeader from '../CompanionHeader';
import Loader from '../../Common/Loader';
import { companionInfos } from '../../Content/Content';

import { TokenContext } from '../../Providers/TokenProvider';
import AuthPrompt from '../../Common/AuthPrompt';
import { apiUpdateCompanions } from '../../../services/functionApp';
import Top3Outside3AddPlayer from './Top3Outside3AddPlayer';
import { CompanionContext } from '../../Providers/CompanionProvider';
import { PICKS } from '../../../services/GameHelpers';

const Top3Outside3SignUp = () => {


  const [ token ] = useContext(TokenContext);
  const [ companionData, setCompanionData ] = useContext(CompanionContext);
  const [ toggleAdd, setToggleAdd ] = useState(false);
  const [ playerId, setPlayerId ] = useState(-1);

  const editPicks = (i) => {
    setPlayerId(companionData.top3Outside3.players[i].id);
    setToggleAdd(true);
  }

  const removePlayer = (i) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    clone.top3Outside3.players.splice(i, 1);
    updateCompanionData(clone);
  }


  const renderPlayers = () => {
    return companionData.top3Outside3.players.map((item, i) => {
      const isEligible = item.tracks.length === 5;
      return (
        <div className="top-5-player fr" key={i}>
          <img className="thumb-med" src={item.imageURL ? item.imageURL : require(`../../../images/user.png`)} alt="eligibility" ></img>
          <div className="top-5-player-info fc" id={`player-${item.name}`}>
            <div className="fr ac">
              <p className="bold">{item.name}</p>
              <div className="fr ac">
                <img className="eligibility player-eligibility" src={require(`../../../images/${isEligible ? "tick" : "warning"}.png`)} alt="eligibility"></img>
                <p data-test='player-eligibility'>{isEligible ? "player ready" : "player not ready"}</p>
              </div>
            </div>
            <div className="fr ac">
              <button className="btn edit-picks-button" onClick={() => editPicks(i)}>edit picks</button>
              <img className="cross remove-player" src={require("../../../images/close.png")} alt="cross" 
                  onClick={() => removePlayer(i)}></img>
            </div>
          </div>
        </div>
      )
    });
  }

  const addPlayer = (player) => {
    //If it's an existing player we want to update them, otherwise we want to add it
    const found = companionData.top3Outside3.players.find(a => a.id === player.id);
    
    const clone = JSON.parse(JSON.stringify(companionData));
    if (found) clone.top3Outside3.players[found] = player;
    else clone.top3Outside3.players.push(player);
    updateCompanionData();
  }

  const hasUnfinisedPlayers = () => {
    for (let player of companionData.top3Outside3.players) {
      if (player.tracks?.length !== PICKS) return true;
    }
    return false;
  }


  const newPlayer = () => {
    const clone = JSON.parse(JSON.stringify(companionData));
    const playerId = Date.now();
    setPlayerId(playerId);
    clone.top3Outside3.players.push( {name: "", id: playerId, tracks: []} );
    setCompanionData(clone);
    setToggleAdd(true);
  }

  const updateCompanionData = async (newData) => {
    setCompanionData(newData);
    await apiUpdateCompanions(token, newData.top3Outside3, false);
  }


  if (!token) {
    return (
      <>
        <CompanionHeader companion={companionInfos[0]} redirectLink={true} />
        <AuthPrompt 
          header="sign up"
          subHeader="to sign up for top 5 outside 5 you must first authenticate through spotify"
          redirectEndpoint="companions/top-5-outside-5/sign-up"/>
      </>
    )
  }

  if (!companionData) {
    return ( 
      <div>
        <CompanionHeader companion={companionInfos[0]} redirectLink={true} />
        <Loader />
      </div>
    )
  }


  return (
    <div>
      <CompanionHeader companion={companionInfos[0]} redirectLink={true} />
      {!toggleAdd && 
    <div>
      <div id="sign-up">
        <h3>sign up</h3>
        <span>you have </span>
        <span className="bold player-number">{companionData.top3Outside3.players.length}</span>
        <span> {companionData.top3Outside3.players.length === 1 ? "player" : "players"} in your game</span>
      </div>

      <div id="top-5-players">{renderPlayers()}</div>

      <div id="add-player" className="fr ac bold" onClick={newPlayer} >
        <img className="add-s" src={require(`../../../images/add-button-orang.png`)} alt="add player"></img>
        <p>add new player</p>
      </div>


      {hasUnfinisedPlayers() && 
        <div className="fr ac">
          <img className="player-eligibility" src={require(`../../../images/warning.png`)} alt="eligibility"></img>     
          <p data-test='unready-players'>you have players that are not ready. please make sure each player has 5 picks.</p>
        </div>
      }

      { companionData.top3Outside3.players.length > 0 && !hasUnfinisedPlayers() &&
        <>
          <div className="fr ac">
            <img className="player-eligibility" src={require(`../../../images/tick.png`)} alt="eligibility"></img>     
            <p data-test='ready-players'>your player picks are all ready! feel free to add more players otherwise you're ready to go.</p>
          </div>

          <Link className="fr ac" to={`/companions/${companionInfos[0].link}/live`}>
            <img className="thumb-mini pad-right" src={require(`../../../images/gaming.png`)} alt="more-info"></img>
            <p className="bold">view live game</p>
          </Link>
        </>
      }

    </div>}
    {toggleAdd && <Top3Outside3AddPlayer 
          setToggleAdd={setToggleAdd} 
          companionData={companionData}
          addPlayer={addPlayer} updateCompanionData={updateCompanionData} 
          playerId={playerId} />}
    </div>
  )
};

export default Top3Outside3SignUp;