import React, { useContext, useEffect, useState } from 'react';

import TopTrack from './TopTrack';
import TopArtist from './TopArtist';

import { useParams } from 'react-router-dom';
import { TokenContext } from '../Providers/TokenProvider';
import { getAllTopSyncedTracks, checkTrackEligibility } from '../../services/helpers';
import { getTop } from '../../services/spotify';
import { WarningMessage } from '../Common';


const TopRenderer = ({ topData, setTopData, triggerShortlistModal, setLoaded,
    setLoadError, setErrorContent, loadError }) => {

  const [ token ] = useContext(TokenContext);

  const { type, time } = useParams();

  const [ lastType, setLastType ] = useState(type);
  const [ lastTime, setLastTime ] = useState(time); 

  useEffect(() => {
    if (loadError && lastType === type && lastTime === time) return;
    if (loadError) setLoadError(false);

    const top = topData[type][time];

    if (!top) {

      async function getTopData() {

        setLoaded(false);
        const response = type === "tracks"
            ? await getAllTopSyncedTracks(token, time)
            : await getTop(token, "artists", time);
        setLoaded(true);


        if (response.error) {      
          setLoadError(true);
          setErrorContent({
            header: "we're sorry",
            subHeader: "we encountered a technical issue when retrieving your data from spotify. please try again later",
            imageSource: "warning.png",
            closable: false,
          });
          return;
        }

        const topDataCopy = JSON.parse(JSON.stringify(topData));
        topDataCopy[type][time] = response;
        setTopData(topDataCopy);
        setLastTime(time);
        setLastType(type);
      }

      getTopData();

    }
  });

  
  const renderTopTracks = () => {

    let topTracks = topData["tracks"][time]
        ? topData["tracks"][time] : [];

    if (loadError) return;

    if (topTracks.length === 0) {
      return (
        <WarningMessage title="no top tracks to show" text ="once you've listened more on spotify, your top tracks will show up here" />
      )
    }

    return topTracks.map((item, i) => {
      return (
        <TopTrack key={i} index={i+1} title={item.name} artist={item.artists[0].name} 
          imageSource={item.album.images[1].url} audioSource={item.preview_url} 
          addToShortlist={triggerShortlistModal} added={item.added} 
          eligibility={checkTrackEligibility(item.album.release_date)}
          spotifyUrl={item.external_urls.spotify}
          />
      )
    });
  }

  const renderTopArtists = () => {

    const topArtists = topData["artists"][time] 
        ? topData["artists"][time] : [];

    if (loadError) return;

    if (topArtists.length === 0) {
      return (
        <WarningMessage title="no top artists to show" text ="once you've listened more on spotify, your top artists will show up here" />
      )
    }

    return topArtists.map((item, i) => {
      return (
        <TopArtist key={i} index={i+1} artist={item.name} 
          imageSource={item.images[1].url} spotifyUrl={item.external_urls.spotify} />
      )
    });
  }

  return (
    <>
      {type === "tracks" && renderTopTracks()}
      {type === "artists" && renderTopArtists()}
    </>
  )
};


export default TopRenderer;