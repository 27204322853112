import React from 'react';

import { AddToShortlist, Eligibility, SpotifyLink, AudioPreview } from '../Common';


const TopTrack = ({imageSource, index, title, artist, audioSource, addToShortlist, 
      added, eligibility, spotifyUrl}) => {
  
  return (
    <div className="top-track fr ac">
      <img className="thumb-med" src={imageSource} alt="track"></img>
      <div className="track-info fc">
        <div>
          <div className="fr ac">
            <div className="ranking-title fr">
              <p className="ranking">{`#${index}.`}</p>
              <p className="default rank-title overflow-ellipsis">{title}</p>
            </div>  
            <Eligibility eligibility={eligibility}/>
          </div>
          <p className="artist overflow-ellipsis bold">{artist}</p>
        </div>
        <div className="card-actions fr ac">
          <AddToShortlist added={added} index={index} addToShortlist={addToShortlist} />
          <div className="push-right"><AudioPreview audioSource={audioSource}/></div>
          <SpotifyLink url={spotifyUrl} />
        </div>
      </div>
    </div>

  )
};


export default TopTrack;