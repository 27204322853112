import React, { useEffect } from "react";

/**
 * This is a generalized component that wraps logic to call a callback function given (n) seconds after a user has
 * last typed into the input field
 * 
 * This way we can avoid making a bunch of unnecessary calls to APIs
 */


//timeout is the amount of seconds to wait after last character typed in input field to call the callback function
//callback is the function to call after the timout has elapsed
const TimedInput = ({ callback, timeout=750, searchTerm, setSearchTerm }) => {

  
  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchTerm) callback(searchTerm);
    }, timeout);

    return () => clearTimeout(timer)
  }, [searchTerm, timeout, callback]);

  const updateSearchTerm = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  }

  return (
    <input autoFocus className="timed-input" type="text" value={searchTerm} onChange={updateSearchTerm}></input>
  );

};

export default TimedInput;