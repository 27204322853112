import React, { useContext, useRef } from 'react';

import { AudioContext } from '../Providers/AudioProvider';

const AudioPreview = ({audioSource}) => {

  const [ src, setSrc ] = useContext(AudioContext);
  const audioRef = useRef();

  const setAudioSource = () => {
    let source = audioRef.current.getAttribute("audiosrc");
    source = source === src ? "" : source;
    setSrc(source);
  }

  if (audioSource === null) {
    return (
    <div className="preview-section fr ac point" >
      <p className="light-text">no preview</p>
    </div>
    )
  }

  return (
    <div className="preview-section fr ac point" audiosrc={audioSource} ref={audioRef} onClick={setAudioSource} >
      <img className="preview-image" src={require(`../../images/play-button.png`)} alt="play button"></img>
      <p className="light-text">{ src === audioSource ? "pause" : "preview"}</p>
    </div>
  )
};

export default AudioPreview;