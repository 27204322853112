import React, { useState, useContext, useCallback } from 'react';

import { SpotifyLink, Eligibility, AudioPreview, AddToShortlist, TimedInput } from '../../Common';


import { searchSpotify } from '../../../services/spotify';
import { TokenContext } from '../../Providers/TokenProvider';
import { checkTrackEligibility } from '../../../services/helpers';

const BingoSearch = ({ addTrack, board, setAddToggle, value, searchResults, setSearchResults }) => {

  const [ token ] = useContext(TokenContext);
  const [ searchTerm, setSearchTerm ] = useState("");

  const performSpotifySearch = useCallback(async (searchTerm) => {
    let results = await searchSpotify(searchTerm, token, value);
    //Filter search results so they don't show songs already selected
    const filteredResults = results.filter(result => 
          !board.tracks.find(a => a && a.type === value && a.id === result.id));
    setSearchResults(filteredResults);
  }, [board.tracks, setSearchResults, token, value]);


  const handleAddTrack = async (index) => {
    addTrack(searchResults[index], value);
    setAddToggle(false);
    setSearchResults([]);
    setSearchTerm("");
  }




  const clearSearch = () => {
    setSearchResults([]);
    setSearchTerm("");
  }

  
  const renderSearchResults = () => {
    return searchResults.map((item, i) => {
      if (value === "track") {
        return (
          <SearchResult track={item.name} artist={item.artists[0].name} 
              isEligible={checkTrackEligibility(item.album.release_date)} imageSource={item.album.images[1].url}
              audioSource={item.preview_url} key={i} addTrackToShortlist={handleAddTrack} spotifyUrl={item.external_urls.spotify}
              index={i} />
        )
      } else {
        return (
        <ArtistSearchResult artist={item.name} imageSource={item.images.length > 0 ? item.images[1].url : ""}
            key={i} addTrackToShortlist={handleAddTrack} spotifyUrl={item.external_urls.spotify}
            index={i} />
        )
      }

    });
  }

  return (
    <div>
      <div id="search-bar">
        <label className="default">search tracks:</label>
        <TimedInput callback={performSpotifySearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>

      {searchResults.length > 0 &&
        <div id="bingo-search-results">
          <div id="results-header" className="fr ac">
            <p>results:</p>
            <div className="fr ac" onClick={clearSearch}>
              <p>close</p>
              <img className="cross" src={require("../../../images/close.png")} alt="cross"></img>
            </div>
          </div>
          <div>
            {renderSearchResults()}
          </div>
        </div>
      }
    </div>
  )
};


const ArtistSearchResult = (({ imageSource, artist, addTrackToShortlist, index, spotifyUrl }) => {
return (
<div className="search-result fr ac">
  <div className="search-left fr ac">
    <img className="thumb-small" src={imageSource} alt="track"></img>
    <div className="search-info">
      <p className="bold overflow-ellipsis">{artist}</p>
      <SpotifyLink url={spotifyUrl} search={true} />
    </div>
  </div>
  <div className="search-actions fc jc">
    <AddToShortlist added={false} index={index} addToShortlist={addTrackToShortlist} buttonContent={"select"} />
  </div>
</div>
)
});

const SearchResult = (({ track, imageSource, artist, isEligible, audioSource, 
      addTrackToShortlist, index, spotifyUrl }) => {
  return (
    <div className="search-result fr ac">
      <div className="search-left fr ac">
        <img className="thumb-small" src={imageSource} alt="track"></img>
        <div className="search-info">
          <div className="search-result-title fr ac">
            <p className="overflow-ellipsis">{track}</p>
            <Eligibility eligibility={isEligible}/>
          </div>
          <p className="bold overflow-ellipsis">{artist}</p>
          <SpotifyLink url={spotifyUrl} search={true} />
        </div>
      </div>
      <div className="search-actions fc jc">
        <AddToShortlist added={false} index={index} addToShortlist={addTrackToShortlist} buttonContent={"select"} />
        <AudioPreview audioSource={audioSource}/>
      </div>
    </div>
  )
});


export default BingoSearch;