import React, { useContext } from 'react';

import { Eligibility, AddToShortlist } from '../Common';

import { TokenContext } from '../Providers/TokenProvider';

import '../../style/popularTrack.css';

const PopularTrack = ({imageSource, index, title, artist, played, eligibility, addToShortlist, added, addable=true}) => {
  
  const [ token ] = useContext(TokenContext);

  return (
    <div className="top-track fr ac">
      <img className="thumb-med" src={imageSource} alt="track"></img>
      <div className="popular-track-info fc">
        <div className="top-info">
          <div className="ranking-title-popular fr">
            <p className="ranking">{`#${index}.`}</p>
            <p className="light-text rank-title overflow-ellipsis">{title}</p>
            {eligibility !== null && <Eligibility eligibility={eligibility} />}
          </div>
          <p className="artist overflow-ellipsis bold">{artist}</p>
        </div>
        <div className="add-shortlist-holder fr">
          <p className="played light-italic">played <strong>{played}</strong> times</p>
          { token && addable && <AddToShortlist added={added} index={index} addToShortlist={addToShortlist} />}
        </div>
      </div>
    </div>

  )
};


export default PopularTrack;