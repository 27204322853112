import React, { useState, useContext, useCallback } from 'react';

import { SpotifyLink, Eligibility, AudioPreview, AddToShortlist, TimedInput } from '../Common';

import { searchSpotify } from '../../services/spotify';
import { TokenContext } from '../Providers/TokenProvider';
import { checkTrackEligibility } from '../../services/helpers';

const Search = ({ triggerModalAdd, setLoadError, setErrorContent, shortlist }) => {

  const [ token ] = useContext(TokenContext);
  const [ searchTerm, setSearchTerm ] = useState("");
  const [ searchResults, setSearchResults ] = useState([]);




  const performSpotifySearch = useCallback(async () => {

    const filterSearchResults = (results) => {

      if (!(shortlist?.tracks?.length > 0)) return results;
  
      for (let i = 0; i < results.length; i++) {
  
        const index = shortlist.tracks.findIndex(a => {
          return (
            a.trackName === results[i].name &&
            a.artistName === results[i].artists[0].name
          );
        });
  
        results[i].added = index !== -1;
  
      }
      return results;
    }

    let results = await searchSpotify(searchTerm, token);
    if (results.error) {
      setLoadError(true);
      setErrorContent({
        header: "we're sorry",
        subHeader: "spotify search doesn't seem to be working right now. please try again later",
        imageSource: "warning.png",
        closable: false
      });
      return;
    }
    setSearchResults(filterSearchResults(results));
  }, [shortlist?.tracks, searchTerm, setErrorContent, setLoadError, token]);


  const handleAddTrack = async (index) => {
    const toAdd = {
      id: searchResults[index].id,
      data: searchResults[index]
    }
    await triggerModalAdd(toAdd);
    setSearchResults([]);
    setSearchTerm("");
  }




  const clearSearch = () => {
    setSearchResults([]);
    setSearchTerm("");
  }

  
  const renderSearchResults = () => {
    return searchResults.map((item, i) => {
      return (
        <SearchResult track={item.name} artist={item.artists[0].name} 
            isEligible={checkTrackEligibility(item.album.release_date)} imageSource={item.album.images[1].url}
            audioSource={item.preview_url} key={i} addTrackToShortlist={handleAddTrack} spotifyUrl={item.external_urls.spotify}
            index={i} added={item.added} />
      )
    });
  }

  return (
    <div>
      <div id="search-bar">
        <label className="default">search tracks:</label>
        <TimedInput callback={performSpotifySearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>

      {searchResults.length > 0 &&
        <div id="search-results">
          <div id="results-header" className="fr ac">
            <p>results:</p>
            <div className="fr ac" onClick={clearSearch}>
              <p>close</p>
              <img className="cross" src={require("../../images/close.png")} alt="cross"></img>
            </div>
          </div>
          <div>
            {renderSearchResults()}
          </div>
        </div>
      }
    </div>
  )
};



const SearchResult = (({ track, imageSource, artist, isEligible, audioSource, 
      addTrackToShortlist, index, spotifyUrl, added }) => {
  return (
    <div className="search-result fr ac">
      <div className="search-left fr ac">
        <img className="thumb-small" src={imageSource} alt="track"></img>
        <div className="search-info">
          <div className="search-result-title fr ac">
            <p className="overflow-ellipsis">{track}</p>
            <Eligibility eligibility={isEligible}/>
          </div>
          <p className="bold overflow-ellipsis">{artist}</p>
          <SpotifyLink url={spotifyUrl} search={true} />
        </div>
      </div>
      <div className="search-actions fc jc">
        <AddToShortlist added={added} index={index} addToShortlist={addTrackToShortlist} />
        <AudioPreview audioSource={audioSource}/>
      </div>
    </div>
  )
});


export default Search;