import React, { useState } from "react";

import BingoTypeSelector from './BingoTypeSelector';
import BingoSearch from './BingoSearch';


const AddBingoPick = ({ board, addTrack, setAddToggle, addIndex }) => {


  const toggleItems = ["track", "artist"];

  const [ selectValue, setSelectValue ] = useState("track");

  const [ searchResults, setSearchResults ] = useState([]);
  const [ error, setError ] = useState(false);
  const [ errorContent, setErrorContent ] = useState("");


  const changeSelectValue = (option) => {
    setSearchResults([]);
    if (option === "track") {
      setSelectValue(option);
      setError(false);
      return;
    }

    //Check that the total count of artists is less than 5
    const filtered = board.tracks.filter((a) => a && a.type === "artist");
    if (filtered.length >= 5) {
      setError(true);
      setErrorContent("you already have 5 artists on this bingo board, which is the max. if you would like to add another artist, please remove one of the existing ones.");
      return;
    }

    //Check column for 2 artists
    let columnCount = 0;
    for (let i = addIndex%4; i < 15; i+=4) {
      if (board.tracks[i] && board.tracks[i].type === "artist") columnCount++;
    }
    if (columnCount >= 2) {
      setError(true);
      setErrorContent("you already have 2 artists in this column, which is the max. if you want to add another artist in this column, please remove one of the existing ones.");
      return;
    }

    //Check row for 2 artists
    let rowCount = 0;
    for (let i = (addIndex - addIndex%4); i < (addIndex - addIndex%4 + 4); i++) {
      if (board.tracks[i] && board.tracks[i].type === "artist") rowCount++;
    }
    if (rowCount >= 2) {
      setError(true);
      setErrorContent("you already have 2 artists in this row, which is the max. if you want to add another artist in this row, please remove one of the existing ones.");
      return;
    }

    setSelectValue("artist");

  }

  return (
    <div className="bingo-board-add-bg">
      <div className="bingo-board-add bingo-board-search">
        <div id="bingo-add-window" className="fr ac">
          <h3>add pick</h3>
          <img className="cross remove-player" src={require("../../../images/close.png")} alt="cross" 
                  onClick={() => setAddToggle(false)}></img>
        </div>
        <BingoTypeSelector toggleItems={toggleItems}
            className="toggle-selector archive-toggle" value={selectValue} setSelectValue={changeSelectValue} />
        { error && 
          <div className="fr ac">
            <img className="player-eligibility" src={require(`../../../images/warning.png`)} alt="eligibility"></img>     
            <p className="bingo-add-error">{errorContent}</p>
          </div>
        }
        <BingoSearch board={board} addTrack={addTrack} setAddToggle={setAddToggle} value={selectValue} 
            searchResults={searchResults} setSearchResults={setSearchResults} />

        <div className="fr ac">
          <img className="player-eligibility" src={require(`../../../images/info.png`)} alt="eligibility"></img>       
          <p className="default">type in the search bar to find songs/artists to add to your bingo board.</p>
        </div>
      </div>
    </div>
  )

}

export default AddBingoPick;