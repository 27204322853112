
import ReactGA from 'react-ga4';


export function RegisterPageHit(path, title) {
  ReactGA.send({
    hitType: "pageview",
    page: path,
    title: title
  });
}

export function RecordButtonClick(action) {
  ReactGA.event({
    category: "button",
    action: action,
  });
}
