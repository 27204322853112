import React, { useState } from 'react';

export const SignInPopupContext = React.createContext();

const SignInPopupProvider = ({children}) => {

  const [ showSignInPopup, setShowSignInPopup ] = useState(true);

  return (
    <SignInPopupContext.Provider value={[showSignInPopup, setShowSignInPopup]}>
      {children}
    </SignInPopupContext.Provider>
  )

};

export default SignInPopupProvider;