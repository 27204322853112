import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import '../../../style/top3PlayerPicks.css';

const PlayerPicks = ({ players, selected, mobileView=false }) => {

  const [ index, setIndex ] = useState(0);
  const location = useLocation();

  
  useEffect(() => {
    if (location.pathname.includes("simulation")) return;
    if (!mobileView && players) setTimeout(() => setIndex(index + 1 === players.length ? 0 : index + 1), 10000);
  }, [index, mobileView, players, location.pathname])

  if (!players) return <></>;


  //Testing method to change which player is selected for display
  const changeSelectedPlayer = (elem) => {
    //Get player index by name
    const newIndex = players.findIndex((a) => a.name === elem.target.value);
    setIndex(newIndex);
  }

  /**
   * For testing, want to be able to change which player is being shown so we can check the scores properly
   */
  const renderSimulationHelper = () => {
    return (  
      <div id="simulation-helper" onChange={changeSelectedPlayer}>
        <select>
        {players.map((item, i) => {
          return <option value={item.name} key={item.name+i}>{item.name}</option>
        })}
        </select>
      </div>
    )
  }


  const renderPlayer = () => {
    return (
      <>
        <div id="player-pick" className="fr">
          <img className="thumb-med" src={players[index].imageURL ? players[index].imageURL : require(`../../../images/user.png`)} alt={"player"}></img>
          <div className="playerPickInfo">
            <p className="light-text rank-title overflow-ellipsis">{players[index].name}</p>
            <p className="artist overflow-ellipsis bold">score: {players[index].score === -1 ? "BUST": players[index].score}</p>
          </div>
        </div>
        <div className="player-picks">
          {renderPicks()}
        </div>
      </>
    );
  }

  const renderPlayers = () => {
    return players.map((item, i) => {
      return (
        <>
          <div id="player-pick" className="fr" key={i}>
            <img className="thumb-med" src={item.imageURL ? item.imageURL : require(`../../../images/user.png`)} alt={"player"}></img>
            <div className="playerPickInfo">
              <p className="light-text rank-title overflow-ellipsis">{players[i].name}</p>
              <p className="artist overflow-ellipsis bold">score: {players[i].score === -1 ? "BUST": players[i].score}</p>
            </div>
          </div>
          <div className="player-picks">
            {renderPicks()}
          </div>
        </>
      )
    });
  }

  const sortPlayerPicks = (a, b) => {
    if (a.score === b.score) return a.trackName < b.trackName ? 1 : -1;
    return a.score < b.score ? 1 : -1;
  };

  const renderPicks = () => {

    let sortedPicks = players[index].tracks;
    sortedPicks.sort(sortPlayerPicks);

    return sortedPicks.map((item, i) => {
      return (
        <Pick image={item.imageSrc} index={i} 
          title={item.trackName} artist={item.artistName} score={item.score} 
          placing={item.placing} key={i} />
      )
    });
  }

  if (players === null) return <div></div>;

  return (
    <div id="player-pick-section" className={selected ? "desktop-section live-comp mobile-selected" : "desktop-section live-comp"} >
      { location.pathname.includes("simulation") && renderSimulationHelper()}
      <h1>players competing</h1>
      { !mobileView && renderPlayer()}
      { mobileView && renderPlayers()}
    </div>
  )
};


const Pick = ({ image, title, artist, score, placing }) => {

  return (
    <div className="player-pick fr ac">
      <img className="thumb-med" src={image} alt={"player pick"}></img>
      <div className="playerPicksInfo">
        <p className="light-text rank-title overflow-ellipsis">{title}</p>
        <p className="artist overflow-ellipsis bold">{artist}</p>
        <div className="pick-placing fr ac">
          <p className="bold no-margin">score: </p>
          <p className="ranking bold pick-attr no-margin" 
                id={`track-score-${title.split(' ').join('-').toLowerCase()}`}>{score === -1 ? "BUST": score}</p>
          <p className="bold no-margin">placing: </p>
          <p className="ranking bold pick-attr no-margin">{(placing === "yet to play" || placing === "didn't place") ? placing : "#" + placing}</p>
        </div>
      </div>
    </div>
  )
};



export default PlayerPicks;