import React, { useContext, useState, useEffect } from 'react';

import { Header, AuthPrompt, Alert, Modal, ErrorMessage, Loader } from '../Common';
import Search from './Search';
import ShortlistHandler from './ShortlistHandler';
import LinkedPlaylist from './LinkedPlaylist';

import { TokenContext } from '../Providers/TokenProvider';
import { apiUpdateShortlist } from '../../services/functionApp';
import { fetchShortlist, updatePlaylistSyncedStatus } from '../../services/helpers';
import { RegisterPageHit, RecordButtonClick } from '../../services/googleAnalyticsHelper';


import '../../style/searchResults.css';
import '../../style/shortlist.css';
import PastYears from './PastYears';

const ShortList = ({shortList, loaded, setShortList}) => {

  RegisterPageHit("/shortlist", "user-shortlist");

  const [ token ] = useContext(TokenContext);

  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ alertContent, setAlertContent ] = useState({});

  const [ showModal, setShowModal ] = useState(false);
  const [ modalContent, setModalContent] = useState({});

  const [ loadError, setLoadError ] = useState(false);
  const [ errorContent, setErrorContent ] = useState({});

  useEffect(() => {
    if (loaded && shortList === null) {
    setLoadError(true);
    setErrorContent({
      header: "we're sorry",
      subHeader: "we encountered a technical issue when trying to retrieve your shortlist. please try again later",
      imageSource: "warning.png",
      closable: false
    });
    }
  }, [loaded, shortList]);


  const triggerModalRemove = (index) => {
    RecordButtonClick("Trigger Modal Remove");
    const data = {
      imageSource: shortList.tracks[index - 1].imageSrc,
      name: shortList.tracks[index - 1].trackName
    }

    setModalContent({
      data,
      callbackPayload: index,
      add: false,
      confirmCallback: removeFromShortlist
    });
    setShowModal(true);
  }

  const triggerModalAdd = (toAdd) => {
    RecordButtonClick("Trigger Modal Add");
    toAdd.index = shortList.tracks ? shortList.tracks.length : 0;
    const data = {
      imageSource: toAdd.data.album.images[1].url,
      name: toAdd.data.name
    }
    setModalContent({
      data,
      callbackPayload: toAdd,
      add: true,
      confirmCallback: addTrackToShortlist
    });
    setShowModal(true);
  }


  const updateAlert = (alert, added) => {
    setAlertContent({
      imageSource: alert.imageSrc,
      title: alert.trackName,
      artist: alert.artistName,
      added
    });
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000);
  }

  const addTrackToShortlist = async (toAdd) => {
    RecordButtonClick(`Confirm adding track: ${toAdd.data.name} By ${toAdd.data.artists[0].name}`);

    const newShortlist = shortList.tracks ? Array.from(shortList.tracks) : [];

    newShortlist.push({
      id: toAdd.id, 
      index: toAdd.index, 
      dateAdded: Date.now(),
      uri: toAdd.data.uri,
      audioSrc: toAdd.data.preview_url,
      spotifyUrl: toAdd.data.external_urls.spotify,
      imageSrc: toAdd.data.album.images[1].url,
      releaseDate: toAdd.data.album.release_date,
      trackName: toAdd.data.name,
      artistName: toAdd.data.artists[0].name
    });

    const response = await apiUpdateShortlist(token, newShortlist);
    if (response.error) {
      setLoadError(true);
      setErrorContent({
        header: "we're sorry",
        subHeader: "we encountered a technical issue when trying to add that track to your shortlist",
        imageSource: "warning.png",
        closable: true,
        setShow: setLoadError
      });
      return;
    }

    //The first time they're adding a song, shortlist won't be in memory
    if (!shortList)  {
      const response = await fetchShortlist(token);
      setShortList(response.shortlistItems)

      updateAlert({
        imageSrc: toAdd.data.album.images[1].url, 
        trackName: toAdd.data.name, 
        artistName: toAdd.data.artists[0].name}, true);
      return;
    }

    let clone = JSON.parse(JSON.stringify(shortList));
    clone.tracks = newShortlist;
    clone = await updatePlaylistSyncedStatus(token, clone);
    setShortList(clone);
    updateAlert({
      imageSrc: toAdd.data.album.images[1].url, 
      trackName: toAdd.data.name, 
      artistName: toAdd.data.artists[0].name}, true);
  }

  const removeFromShortlist = async (index) => {
    const toRemove = shortList.tracks[index - 1];
    RecordButtonClick(`Confirm removing track: ${toRemove.trackName} By ${toRemove.artistName}`);
    const newShortlist = Array.from(shortList.tracks);
    newShortlist.splice(index - 1, 1);
    const response = await apiUpdateShortlist(token, newShortlist);
    if (response.error) {
      setLoadError(true);
      setErrorContent({
        header: "we're sorry",
        subHeader: "we encountered a technical issue when trying to remove that track to your shortlist",
        imageSource: "warning.png",
        closable: true,
        setShow: setLoadError
      });
      return;
    } 
    let clone = JSON.parse(JSON.stringify(shortList));
    clone.tracks = newShortlist;
    clone = await updatePlaylistSyncedStatus(token, clone);
    setShortList(clone);
    updateAlert(toRemove, false);
  }



  if (!token) {
    return (
        <AuthPrompt 
          header="your hottest 100 of 2024 shortlist"
          subHeader="to create your own hottest 100 shortlist you will first need to authenticate through spotify"
          redirectEndpoint="hottest-100/shortlist"
        />
    )
  }

  return (
    <div>
      <Header 
      header="your hottest 100 of 2024 shortlist"
      subHeader="add or remove songs from your shortlist.
                  you can also create a spotify playlist based on your picks."
      />

      { loaded && shortList && <LinkedPlaylist shortlist={shortList} token={token} setShortlist={setShortList} /> }

      { loadError && <ErrorMessage data={errorContent} />}

      { loaded && <Search triggerModalAdd={triggerModalAdd} setLoadError={setLoadError} 
          setErrorContent={setErrorContent} shortlist={shortList} /> }

      {!loaded && <Loader />}

      <Alert data={alertContent} visible={alertVisible} />
      <Modal show={showModal} setShow={setShowModal} content={modalContent} />

      {loaded && shortList && <ShortlistHandler shortList={shortList} setShortList={setShortList}
          triggerModalRemove={triggerModalRemove} /> }

      {loaded && !shortList && <p className="light-text"> added songs will show up here </p>}

      {loaded && shortList && shortList.pastYears && <PastYears pastYears={shortList.pastYears} />}

    </div>
  )
};


export default ShortList;