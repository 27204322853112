import React from 'react';

import { ToggleSelector } from '../Common';

import { useParams } from 'react-router-dom';


const TimeFrame = ({toggleItems}) => {

  const { time } = useParams();

  return (
    <div>
      <h1>timeframe:</h1>
      <ToggleSelector toggleItems={toggleItems} 
          className="toggle-selector spotify-toggle" value={time} />
    </div>

  )
};


export default TimeFrame;