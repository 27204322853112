import React, { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';




const LiveRedirect = ({ companion }) => {

  const nav = useNavigate();
  const redirectId = useRef(0);
  const [ error, setError ] = useState(false);

  const handleInputChange = () => {
    if (redirectId.current.value.length > 0) setError(false);
  }

  const redirectById = () => {

    if (redirectId.current.value.length === 0) {
      setError(true);
      return;
    }

    nav(`/companions/${companion}/live/${redirectId.current.value}`, { relative: "path" });
  }

  return (
    <div>
      <h1>looking for a friend's game?</h1>
      <p>enter the unique id sent by a friend to tune in to their game</p>
      <input type="text" placeholder="unique id here" ref={redirectId} onChange={handleInputChange}></input>
      <button className="btn" onClick={redirectById}>go</button>
      {error && <p className="error">*that id format doesn't look valid, please confirm you have the correct id</p>}
    </div>
  )
  
};



export default LiveRedirect;