import React, { useContext } from 'react';

import { spotifyClientId, spotifyAuthEndpoint, spotifyResponseType, scope } from '../../services/credentials';

import { SignInPopupContext } from '../Providers/SignInPopupProvider';
import { RecordButtonClick } from '../../services/googleAnalyticsHelper';

import '../../style/authWarning.css';


const AuthWarning = ({ redirectEndpoint, 
    title="see something you like?", 
    summary="to add these tracks to your shortlist, authenticate with spotify" 
  }) => {

  
  const [ showSignInPopup, setShowSignInPopup ] = useContext(SignInPopupContext);

  const closeModal = () => {
    RecordButtonClick("Dismissing Auth Warning")
    setShowSignInPopup(false)
  }

  if (!showSignInPopup) return 

  return (
    <div className="auth-warning">
      <div id="header" data-test="auth-modal">
        <div className="auth-warning-header fr ac">
          <h1>{title}</h1>
          <img className="close-auth-warning" src={require("../../images/close.png")} alt="exit modal" onClick={closeModal}></img>
        </div>
        <p className="light-text">{summary}</p>
      </div>
      <a id="auth" className="fr ac" href={`${spotifyAuthEndpoint}?client_id=${spotifyClientId}&redirect_uri=${window.location.origin}/${redirectEndpoint}&response_type=${spotifyResponseType}&scope=${scope}`}>
        <img id="spotify-auth" src={require("../../images/menu-items/spotify.png")} alt="spotify logo"></img>
        <p className="bold">authenticate now</p>
      </a>
    </div>
  )
};

export default AuthWarning;