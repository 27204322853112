import React, { useState, useEffect, useContext } from 'react';

import TopMenu from '../Menu/TopMenu';
import ShortList from './Shortlist';
import Trending from './Trending';

import { TokenContext } from '../Providers/TokenProvider';
import { Route, Routes, Navigate } from 'react-router-dom';
import { hottest100MenuItems } from '../Content/Content';
import SignInPopupProvider from '../Providers/SignInPopupProvider';
import { fetchShortlist } from '../../services/helpers';

const Hottest100 = () => {

  const [ token ] = useContext(TokenContext);
  const [ topMenuItems, setTopMenuItems ] = useState(hottest100MenuItems);

  const [ shortlist, setShortlist ] = useState(null);
  const [ loaded, setLoaded ] = useState(false);
  
  useEffect(() => {
    if (!token || loaded) return;
    async function getShortlist() {
      const response = await fetchShortlist(token);
      if (!response.error && response.hasShortlist) setShortlist(response.shortlistItems);
      if (!response.error && !response.hasShortlist) setShortlist({tracks: []});
      setLoaded(true);
    }
    getShortlist();
  });

  return (
    <div>
      <SignInPopupProvider>
        <TopMenu topMenuItems={topMenuItems} setTopMenuItems={setTopMenuItems} />

        <Routes>
          <Route path='/' element={ <Navigate replace to="./shortlist" /> } />
          <Route path='/shortlist/*' element={<ShortList shortList={shortlist} loaded={loaded} setShortList={setShortlist} />}> </Route>
          <Route path='/trending/*' element={<Trending shortlist={shortlist} token={token} setShortlist={setShortlist} />}> </Route>
          <Route path='/*' element={<Navigate replace to="/404" />} />
        </Routes>
      </SignInPopupProvider>
    </div>
  )
};


export default Hottest100;