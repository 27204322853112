import topTracks2015 from './topTracks2015.json';
import topTracks2016 from './topTracks2016.json';
import topTracks2017 from './topTracks2017.json';
import topTracks2018 from './topTracks2018.json';
import topTracks2019 from './topTracks2019.json';
import topTracks2020 from './topTracks2020.json';
import topTracks2021 from './topTracks2021.json';
import topTracks2022 from './topTracks2022.json';

export const topTracks = {
  2015: topTracks2015,
  2016: topTracks2016,
  2017: topTracks2017,
  2018: topTracks2018,
  2019: topTracks2019,
  2020: topTracks2020,
  2021: topTracks2021,
  2022: topTracks2022
};
