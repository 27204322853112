import { axiosWrapper } from './foundation';
import { functionsAppUrl, getShortlist, updateShortlist, getPopular, 
          updateTrackShortlist, linkPlaylist, getRecentlyShortlisted, 
          updateCompanions, getCompanions, getCompanionsBySpotifyId, 
          uploadImage, uploadImageRaw, addFeedbackItem } from './credentials';


export async function apiGetRecentlyShortlisted() {
  const url = `${functionsAppUrl}${getRecentlyShortlisted.endpoint}?code=${getRecentlyShortlisted.key}`  
  let response = await axiosWrapper("GET", url, null, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;
}

export async function linkShortlistToPlaylist(token, playlistId) {
  const url = `${functionsAppUrl}${linkPlaylist.endpoint}?code=${linkPlaylist.key}`;
  const body = { token, playlistId };
  let response = await axiosWrapper("POST", url, body, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return true;
}

export async function apiUpdateTrackShortlist(token, track) {
  const url = `${functionsAppUrl}${updateTrackShortlist.endpoint}?code=${updateTrackShortlist.key}`;
  if (!track.hasOwnProperty("dateAdded")) track.dateAdded = Date.now();
  const body = { token, track };
  let response = await axiosWrapper("POST", url, body, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;
}

export async function apiGetPopularData() {

  const url = `${functionsAppUrl}${getPopular.endpoint}?code=${getPopular.key}`;
  let response = await axiosWrapper("GET", url, null, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;

}

export async function apiGetShortlist(token) {
  const url = `${functionsAppUrl}${getShortlist.endpoint}?code=${getShortlist.key}&token=${token}`;
  let response = await axiosWrapper("GET", url, null, {}).catch(err => {return {error : err}});
  return response.error ? response : response.data;
}

export async function apiUpdateShortlist(token, tracks) {
  const url = `${functionsAppUrl}${updateShortlist.endpoint}?code=${updateShortlist.key}`;
  for (let i = 0; i < tracks.length; i++) {
    if (!tracks[i].hasOwnProperty("dateAdded")) tracks[i].dateAdded = Date.now();
  }
  const body = { token, tracks };
  let response = await axiosWrapper("POST", url, body, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;
}


export async function apiUpdateCompanions(token, top3Outside3, hottest100Bingo) {
  const url = `${functionsAppUrl}${updateCompanions.endpoint}?code=${updateCompanions.key}`;
  const body = { token, top3Outside3, hottest100Bingo };
  let response = await axiosWrapper("POST", url, body, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;
}


export async function apiGetCompanions(companionId) {
  const url = `${functionsAppUrl}${getCompanions.endpoint}?code=${getCompanions.key}&companionId=${companionId}`;
  let response = await axiosWrapper("POST", url, {}, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;
}

export async function apiGetCompanionsFromSpotifyId(token) {
  const url = `${functionsAppUrl}${getCompanionsBySpotifyId.endpoint}?code=${getCompanionsBySpotifyId.key}&token=${token}`;
  let response = await axiosWrapper("POST", url, {}, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;
}

export async function apiUploadImage(token, formData, headers, fileName) {
  const url = `${functionsAppUrl}${uploadImage.endpoint}/${fileName}?code=${uploadImage.key}&token=${token}`;
  let response = await axiosWrapper("POST", url, formData, headers).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;
}

export async function apiUploadImageRaw(formData, headers, fileName) {
  const url = `${functionsAppUrl}${uploadImageRaw.endpoint}/${fileName}?code=${uploadImageRaw.key}`;
  let response = await axiosWrapper("POST", url, formData, headers).catch(err => err);
  if (response.error) return {error: response.error};
  return response.data;
}

export async function apiAddFeedbackItem(feedbackItem) {
  const url = `${functionsAppUrl}${addFeedbackItem.endpoint}?code=${addFeedbackItem.key}`;
  let response = await axiosWrapper("POST", url, feedbackItem, {}).catch(err => err);
  if (response.error) return {error: response.error};
  return response.status === 201; 
}