import React from 'react';

import { companionInfos } from '../Content/Content';

import CompanionHeader from './CompanionHeader';

import { Link } from 'react-router-dom';
import '../../style/companions.css';

const PreviewUserCompanions = ({ companionData }) => {

  const renderCompanion = (companion, index) => {
    return (
      <div data-test={companion.name.replaceAll(" ", "-")} className="companion-info preview-info" key={index}>
        <CompanionHeader companion={companion}/>
        <div className='fr ac'>
            {
              companion.isPlayable && 
              <>
              <Link className="fr ac" to={`/companions/${companion.link}/info`}>
                <img className="thumb-mini pad-right" src={require(`../../images/info.png`)} alt="more-info"></img>
                <p className="bold">info & rules</p>
              </Link>
              <Link className="fr ac" to={`/companions/${companion.link}/sign-up`}>
                <img className="thumb-mini pad-right" src={require(`../../images/sign-up.png`)} alt="more-info"></img>
                <p className="bold">sign up</p>
              </Link>              
              <Link className="fr ac" to={`/companions/${companion.link}/live`}>
                <img className="thumb-mini pad-right" src={require(`../../images/gaming.png`)} alt="more-info"></img>
                <p className="bold">play</p>
              </Link>
              </>
            }
            {
              !companion.isPlayable &&               
              <Link className="fr ac" to={`/companions/${companion.link}/live`}>
                <img className="thumb-mini pad-right" src={require(`../../images/gaming.png`)} alt="more-info"></img>
                <p className="bold">view countdown</p>
              </Link>
            }

          </div>
      </div>
    )
  }

  return (
    <div id="companions" className="fr">
      {companionInfos.map((item, i) => {
        return renderCompanion(item, i);
      })}
    </div> 
  )

};

export default PreviewUserCompanions;