import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import CompanionsPreview from './CompanionPreviews';

import CountDownTrackerLive from './countdowntracker/CountDownTrackerLive';
import Hottest100BingoInfo from './hottest100bingo/Hottest100BingoInfo';
import Hottest100BingoSignUp from './hottest100bingo/Hottest100BingoSignUp';
import Top3Outside3Info from './top3outside3/Top3Outside3Info';
import Top3Outside3SignUp from './top3outside3/Top3Outside3SignUp';
import Top3Outside3Live from './top3outside3/Top3Outside3Live';
import SignInPopupProvider from '../Providers/SignInPopupProvider';
import TracksProvider from '../Providers/TracksProvider';
import Hottest100BingoLive from './hottest100bingo/Hottest100BingoLive';
import Page404 from '../Page404';

const Companions = () => {


  return (
    <div>
    <SignInPopupProvider>
    <TracksProvider>
      <Routes>
        <Route path='/' element={ <Navigate replace to="./previews" />  } />
        <Route path='/previews/*' element={ <CompanionsPreview /> } />
        <Route path='/top-5-outside-5/info/*' element={<Top3Outside3Info />}> </Route>
        <Route path='/hottest-100-bingo/info/*' element={<Hottest100BingoInfo />}> </Route>
        <Route path='/top-5-outside-5/sign-up/*' element={<Top3Outside3SignUp />}> </Route>
        <Route path='/hottest-100-bingo/sign-up/*' element={<Hottest100BingoSignUp />}> </Route>
        <Route path='/top-5-outside-5/live/*' element={<Top3Outside3Live />}> </Route>
        <Route path='/top-5-outside-5/simulation/live/*' element={<Top3Outside3Live simulation={true} />}> </Route>
        <Route path='/top-5-outside-5/live/:liveId' element={<Top3Outside3Live />}> </Route>
        <Route path='/hottest-100-bingo/live/*' element={<Hottest100BingoLive />}> </Route>
        <Route path='/hottest-100-bingo/simulation/live/*' element={<Hottest100BingoLive simulation={true} />}> </Route>
        <Route path='/hottest-100-bingo/live/:liveId' element={<Hottest100BingoLive />}> </Route>
        <Route path='/countdown-tracker/live/*' element={<CountDownTrackerLive />}> </Route>
        <Route path='/*' element={<Page404 />} />
      </Routes>
    </TracksProvider>
    </SignInPopupProvider>
    </div>
  )
};

export default Companions;