import React, { useContext } from 'react';


import Countdown from '../Countdown';
import NowPlaying from '../NowPlaying';
import "../../../style/top3Outside3Live.css";
import CompanionHeader from '../CompanionHeader';
import { companionInfos } from '../../Content/Content';

import { startTime } from '../../../services/abc';
import CountdownHandler from '../CountdownHandler';
import { TracksContext } from '../../Providers/TracksProvider';

const CountDownTrackerLive = () => {

  const [ tracks ] = useContext(TracksContext);


  if (Date.now() < startTime || tracks.length === 0) {
    return (
      <>
        <CompanionHeader companion={companionInfos[0]}/>
        <CountdownHandler date={startTime} />
        <Countdown tracks={tracks}  fullWidth={true} />
      </>
    );
  }

  return (
    <div id="playing-now">

    
      <div id="top-5-desktop-view">
          <Countdown tracks={tracks} fullWidth={true} />
          {tracks.length > 0 && <NowPlaying tracks={tracks} />  }

      </div>



    </div>
  )
};


export default CountDownTrackerLive;