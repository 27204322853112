import React from 'react';

import '../../../style/toggleSelector.css';


const BingoTypeSelector = ({ toggleItems, value, setSelectValue }) => {


  return (
    <div id="toggle-selector">
      { toggleItems.map((option, i) => 
        <p key={i} data-test={`toggle-${option}`} 
            onClick={() => setSelectValue(option)} 
            className={`archive-toggle toggle-selector ${value === option ? "toggle-selected" : ""}`}>{option}</p>) }
    </div>

  )
};


export default BingoTypeSelector;