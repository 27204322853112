import React, { useState } from 'react';

import "../../../style/hottest100Bingo.css";

import AddBingoPick from './AddBingoPick';
import BingoBoard from './BingoBoard';
import BingoTileOptions from './BingoTileOptions';
import { Header, ImageUploader } from '../../Common';

const Hottest100BingoAddBoard = ({ companionData, boardId, updateCompanionData, setToggleAdd }) => {

  const [ addToggle, setAddToggle ] = useState(false);
  const [ addIndex, setAddIndex ] = useState(-1);

  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ optionsToggle, setOptionsToggle ] = useState(false);

  const [ isSwapping, setIsSwapping ] = useState(false);

  const updatePlayerName = (e) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    const boardIndex = clone.hottest100Bingo.boards.findIndex(a => a.id === boardId);
    clone.hottest100Bingo.boards[boardIndex].name = e.target.value;
    updateCompanionData(clone);
  }

  const getBoard = () => {
    return companionData.hottest100Bingo.boards[getBoardIndex()];
  }

  const getBoardIndex = () => {    
    return companionData.hottest100Bingo.boards.findIndex(a => a.id === boardId);
  }

  const convertSpotifyData = (type, data) => {

    if (type === "track") {
      return {
        type,
        id: data.id, 
        uri: data.uri,
        audioSrc: data.preview_url,
        spotifyUrl: data.external_urls.spotify,
        imageSrc: data.album.images[1].url,
        releaseDate: data.album.release_date,
        trackName: data.name,
        artistName: data.artists[0].name
      }
    }

    //It must be an artist
    return {
      type,
      id: data.id, 
      uri: data.uri,
      spotifyUrl: data.external_urls.spotify,
      imageSrc: data.images[1].url,
      artistName: data.name
    }


  }

  const addTrack = (track, type) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    clone.hottest100Bingo.boards[getBoardIndex()].tracks[addIndex] = convertSpotifyData(type, track);
    updateCompanionData(clone);
  }

  const removeTrack = () => {
    const clone = JSON.parse(JSON.stringify(companionData));
    clone.hottest100Bingo.boards[getBoardIndex()].tracks[selectedIndex] = null;
    updateCompanionData(clone);
  }

  const handleAddImage = (imageSrc) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    clone.hottest100Bingo.boards[getBoardIndex()].imageURL = imageSrc;
    updateCompanionData(clone);
  }

  const processSwap = (index) => {

    //User wants to swap the places of two entries
    if (index !== selectedIndex) {    
      const clone = JSON.parse(JSON.stringify(companionData));
      const bIndex = getBoardIndex();
      clone.hottest100Bingo.boards[bIndex].tracks[selectedIndex] = companionData.hottest100Bingo.boards[bIndex].tracks[index];
      clone.hottest100Bingo.boards[bIndex].tracks[index] = companionData.hottest100Bingo.boards[bIndex].tracks[selectedIndex];
      updateCompanionData(clone);
    }

    //Get out of swap mode
    
    setIsSwapping(false);
    setSelectedIndex(-1);

  }

  const updateInfo = () => {
    updateCompanionData(companionData);
    setToggleAdd(false);
  }

  const hasUnfinishedBoard = () => {
    return getBoard().tracks.findIndex(a => a == null) !== -1;
  }

  const startSwap = () => { setIsSwapping(true); }

  return (
    <div id="add-player-form">
      <h1>edit player</h1>

      <div className="fr ac">
        <p className="pad-right">name:</p>
        <input id="new-player-name" type="text" value={getBoard().name} onChange={updatePlayerName}></input>
      </div>
      
      <ImageUploader addImage={handleAddImage} profileImage={getBoard().imageURL} />

      {addToggle && <AddBingoPick board={getBoard()} setAddToggle={setAddToggle} addTrack={addTrack} addIndex={addIndex}/>}
      {optionsToggle && <BingoTileOptions board={getBoard()} selectedIndex={selectedIndex} setOptionsToggle={setOptionsToggle}
                            removeTrack={removeTrack} startSwap={startSwap} />}

      <Header header="board" subHeader="click on a section of the bingo board to add a track or artist" />
      <BingoBoard board={getBoard()} setAddToggle={setAddToggle} setAddIndex={setAddIndex}
          optionsToggle={optionsToggle} setSelectedIndex={setSelectedIndex}
          setOptionsToggle={setOptionsToggle} getBoard={getBoard} isSwapping={isSwapping}
          selectedIndex={selectedIndex} processSwap={processSwap} />

      {hasUnfinishedBoard() && 
        <div className="fr ac">
          <img className="player-eligibility" src={require(`../../../images/warning.png`)} alt="eligibility"></img>       
          <p data-test="board-message">your bingo board isn't full yet. please add picks to all of the squares in the board.</p>
        </div>
      } 
      {!hasUnfinishedBoard() && 
        <div className="fr ac">
          <img className="player-eligibility" src={require(`../../../images/tick.png`)} alt="eligibility"></img>       
          <p data-test="board-message">your bingo board is good to go!</p>
        </div>
      }
          
      <button className="update-info-button" onClick={updateInfo}>update info</button>
    </div>
  )
  
};



export default Hottest100BingoAddBoard;