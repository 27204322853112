import React, { useState } from 'react';
import { AudioPreview, SpotifyLink } from '../Common';
import { checkTrackEligibility } from '../../services/helpers';

export const PastShortlistTrack = ({imageSource, ranking, title, artist, audioSource, spotifyUrl}) => {

  return (
    <li className="top-track fr ac" >
      <img className="thumb-med" src={imageSource} alt="track"></img>
      <div className="track-info fc">
        <div>
          <div className="fr ac">
            <div className="ranking-title fr">
              <p className="ranking">{`#${ranking}.`}</p>
              <p className="default rank-title overflow-ellipsis">{title}</p>
            </div>
          </div>
          <p className="artist overflow-ellipsis bold">{artist}</p>
        </div>
        <div className="shortlist-actions">
          <AudioPreview audioSource={audioSource}/>
          <SpotifyLink url={spotifyUrl} />
        </div>
      </div>
    </li>
  )};

const PastYearShortlist = ({ year, tracks }) => {

  const [ show, setShow ] = useState(false);

  const renderShortlistTracks = () => {
    return tracks.map((item, i) => {
        return (
          <PastShortlistTrack title={item.trackName} artist={item.artistName} 
            isEligible={checkTrackEligibility(item.releaseDate)} imageSource={item.imageSrc}
            audioSource={item.audioSrc} ranking={i+1}
            spotifyUrl={item.spotifyUrl} key={i} /> 
        )
    })
  }


  return (
    <div className={`pastYearShortlist past-year-${year}`}>
      <div className="pastYearShortlistHead fr ac" onClick={() => setShow(!show)}>
        <h4>shortlist of {year}</h4>
        <img className="linked-icon" src={require(`../../images/${show ? "minimize": "expand"}.png`)} alt="accordian"></img>
      </div>
      { show && <div className="pastTopTracks"> { renderShortlistTracks() } </div> }
    </div>
  )
};

export default PastYearShortlist;