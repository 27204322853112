import React, { useContext, useState } from 'react';

import { Link } from 'react-router-dom';
import CompanionHeader from '../CompanionHeader';
import Loader from '../../Common/Loader';
import { companionInfos } from '../../Content/Content';

import { TokenContext } from '../../Providers/TokenProvider';
import AuthPrompt from '../../Common/AuthPrompt';
import { apiUpdateCompanions } from '../../../services/functionApp';
import Hottest100BingoAddBoard from './Hottest100BingoAddBoard';
import { CompanionContext } from '../../Providers/CompanionProvider';

const Top3Outside3SignUp = () => {

  const [ token ] = useContext(TokenContext);

  const [ companionData, setCompanionData ] = useContext(CompanionContext);
  const [ toggleAdd, setToggleAdd ] = useState(false);
  const [ boardId, setBoardId ] = useState(-1);



  const editPicks = (i) => {
    setBoardId(companionData.hottest100Bingo.boards[i].id);
    setToggleAdd(true);
  }

  const removePlayer = (i) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    clone.hottest100Bingo.boards.splice(i, 1);
    updateCompanionData(clone);
  }


  const renderBoards = () => {
    return companionData.hottest100Bingo.boards.map((item, i) => {
      const isEligible = item.tracks.findIndex(a => a == null) === -1;
      return (
        <div className="top-5-player fr" key={i}>
          <img className="thumb-med" src={item.imageURL ? item.imageURL : require(`../../../images/user.png`)} alt="eligibility" ></img>
          <div className="top-5-player-info fc">
            <div className="fr ac">
              <p className="bold">{item.name}</p>
              <div className="fr ac">
                <img className="eligibility player-eligibility" src={require(`../../../images/${isEligible ? "tick" : "warning"}.png`)} alt="eligibility"></img>
                <p>{isEligible ? "board ready" : "board not ready"}</p>
              </div>
            </div>
            <div className="fr ac">
              <button className="btn edit-picks-button" onClick={() => editPicks(i)}>edit picks</button>
              <img className="cross remove-player" src={require("../../../images/close.png")} alt="cross" 
                  onClick={() => removePlayer(i)}></img>
            </div>
          </div>
        </div>
      )
    });
  }

  const addBoard = (board) => {
    //If it's an existing player we want to update them, otherwise we want to add it
    const found = companionData.hottest100Bingo.boards.find(a => a.id === board.id);
    
    const clone = JSON.parse(JSON.stringify(companionData));
    if (found) clone.hottest100Bingo.boards[found] = board;
    else clone.hottest100Bingo.boards.push(board);
    updateCompanionData();
  }

  const hasUnfinisedBoards = () => {
    for (let board of companionData.hottest100Bingo.boards) {
      if (board.tracks.findIndex(a => a == null) !== -1) return true;
    }
    return false;
  }

  const newPlayer = () => {
    const clone = JSON.parse(JSON.stringify(companionData));
    const boardId = Date.now();
    setBoardId(boardId);
    clone.hottest100Bingo.boards.push( {name: "", id: boardId, 
      tracks: [null, null, null, null, null, null, null, null,
               null, null, null, null, null, null, null, null]} );
    setCompanionData(clone);
    setToggleAdd(true);
  }

  const updateCompanionData = async (newData) => {
    setCompanionData(newData);
    await apiUpdateCompanions(token, false, newData.hottest100Bingo);
  }


  if (!token) {
    return (
      <>
        <CompanionHeader companion={companionInfos[1]} redirectLink={true} />
        <AuthPrompt 
          header="sign up"
          subHeader="to sign up for top 5 outside 5 you must first authenticate through spotify"
          redirectEndpoint="companions/hottest-100-bingo/sign-up"/>
      </>

    )
  }

  if (!companionData) {
    return ( 
      <div>
        <CompanionHeader companion={companionInfos[1]}/>
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <CompanionHeader companion={companionInfos[1]} redirectLink={true} />
      {!toggleAdd && 
    <div>
      <div id="sign-up">
        <h3>sign up</h3>
        <span>you have </span>
        <span data-test='number-boards' className="bold">{companionData.hottest100Bingo.boards.length}</span>
        <span> bingo {companionData.hottest100Bingo.boards.length === 1 ? "board" : "boards"} in your game</span>
      </div>

      <div id="top-5-players">{renderBoards()}</div>

      <div id="add-player" className="fr ac bold" onClick={newPlayer} >
        <img className="add-s" src={require(`../../../images/add-button-orang.png`)} alt="add player"></img>
        <p>add new board</p>
      </div>


      {hasUnfinisedBoards() && 
        <div className="fr ac">
          <img className="player-eligibility" src={require(`../../../images/warning.png`)} alt="eligibility"></img>     
          <p>you have bingo boards that are not ready. please make sure each board is full.</p>
        </div>
      }

      { companionData.hottest100Bingo.boards.length > 0 && !hasUnfinisedBoards() &&
        <>
          <div className="fr ac">
            <img className="player-eligibility" src={require(`../../../images/tick.png`)} alt="eligibility"></img>     
            <p>your bingo boards are all ready! feel free to add more boards otherwise you're ready to go.</p>
          </div>

          <Link className="fr ac" to={`/companions/${companionInfos[1].link}/live`} alt="live link">
            <img className="thumb-mini pad-right" src={require(`../../../images/gaming.png`)} alt="more-info"></img>
            <p className="bold">view live game</p>
          </Link>
        </>
      }



    </div>}
    { toggleAdd && <Hottest100BingoAddBoard 
          setToggleAdd={setToggleAdd} 
          companionData={companionData}
          updateCompanionData={updateCompanionData} 
          boardId={boardId} addBoard={addBoard} />}
    </div>
  )
};

export default Top3Outside3SignUp;