import React, { useContext } from 'react';

import { AddToShortlist, Eligibility } from '../Common';

import { TokenContext } from '../Providers/TokenProvider';


const RecentlyPlayed = ({imageSource, artist, time, title, eligibility, index, addToShortlist, added}) => {

  const [ token ] = useContext(TokenContext);

  return (
    <div className="top-track fr ac">
      <img className="thumb-med" src={imageSource} alt="track"></img>
      <div className="popular-track-info fc">
        <div className="top-info">
          <div className="ranking-title fr">
            <p className="light-text rank-title overflow-ellipsis">{title}</p>
            <Eligibility eligibility={eligibility}/>
          </div>
          <p className="artist overflow-ellipsis bold">{artist}</p>
        </div>
        <div className="add-shortlist-holder fr">
          <p className="played light-italic">played at {time}</p>
          { token && <AddToShortlist added={added} index={index} addToShortlist={addToShortlist} />}
        </div>
      </div>
    </div>

  )
};

export default RecentlyPlayed;