import { getTop, searchSpotify, getPlaylist, generatePlaylistFromShortlist, 
  followPlaylist, getSpotifyId } from './spotify';
import { apiUpdateTrackShortlist, apiGetShortlist, linkShortlistToPlaylist, 
         apiUploadImage, apiUploadImageRaw } from './functionApp';

  export async function uploadImage(file) {

    const parts = file.type.split("/");
    const fileExtension = parts[parts.length - 1];
  
    const formData = new FormData();
    formData.append("fileupload", file);
  
    const headers = {
      'accept': 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data;`,
    }
  
    const fileName = `Feedback-${Date.now()}.${fileExtension}`;
    const response = await apiUploadImageRaw(formData, headers, fileName);
    if (response) return fileName;
    return response;
  }


export async function uploadImageWithSpotifyID(token, file) {

  //Get spotifyID to send it to the API to make filename unique and traceable
  const spotifyId = await getSpotifyId(token);

  //Error handling here
  if (!spotifyId || spotifyId.error) return false;

  const parts = file.type.split("/");
  const fileExtension = parts[parts.length - 1];

  const formData = new FormData();
  formData.append("fileupload", file);

  const headers = {
    'accept': 'application/json',
    'Accept-Language': 'en-US,en;q=0.8',
    'Content-Type': `multipart/form-data;`,
  }

  const fileName = `${spotifyId}-${Date.now()}.${fileExtension}`;
  const response = await apiUploadImage(token, formData, headers, fileName);
  if (response) return fileName;
  return response;
}

export async function generateAndLinkPlaylist(token, tracks) {
  
  const playlistId = await generatePlaylistFromShortlist(token, tracks);
  if (!playlistId || playlistId.error) return playlistId;

  const linkResult = await linkShortlistToPlaylist(token, playlistId);
  if (!linkResult || linkResult.error) return linkResult;

  const playlist = await getPlaylist(token, playlistId);
  if (!playlist || playlist.error) return playlist;

  return playlist.data;

}

export async function fetchShortlist(token) {
  
  const response = await apiGetShortlist(token);

  if (!response || response.error) return response;
  if (!response.shortlistItems?.linkedPlaylist || (response.hasPlaylist && !response.shortlistItems?.linkedPlaylist)) return response;

  const playlist = await getPlaylist(token, response.shortlistItems.playlistId);
  if (!playlist || playlist.error) return playlist;

  const followResult = await followPlaylist(token, response.shortlistItems.playlistId);
  if (!followResult || followResult.error) return followResult;

  const result = checkPlaylistSynced(response.shortlistItems.tracks, playlist.data.tracks.items)
  response.shortlistItems.playlistSynced = result;
  response.shortlistItems.playlistData = playlist.data;

  return response;
}

export async function updatePlaylistSyncedStatus(token, shortlist) {
  

  if (!shortlist.linkedPlaylist) return shortlist;

  const playlist = await getPlaylist(token, shortlist.playlistId);
  if (!playlist || playlist.error) return playlist;

  const followResult = await followPlaylist(token, shortlist.playlistId);
  if (!followResult || followResult.error) return followResult;

  const result = checkPlaylistSynced(shortlist.tracks, playlist.data.tracks.items)
  shortlist.playlistSynced = result;
  shortlist.playlistData = playlist.data;

  return shortlist;
}

function checkPlaylistSynced(shortlist, playlist) {

  let syncedStatus = { 
    synced: false, push: false, pull: false, toPush: 0, toPull: 0 
  };

  let matchingTracks = 0;

  for (let i = 0; i < shortlist.length; i++) {
    const found = playlist.findIndex((a) => a.track.id === shortlist[i].id);
    if (found === -1) syncedStatus.push = true; //Not found
    else matchingTracks++;
    
  }

  syncedStatus.pull = playlist.length > matchingTracks;
  syncedStatus.toPush = shortlist.length - matchingTracks;
  syncedStatus.toPull = playlist.length - matchingTracks;
  syncedStatus.synced = !(syncedStatus.toPull || syncedStatus.toPush)

  return syncedStatus;

}


export async function addTrackFromTripleJ(token, name, artist) {
  const trackData = await findSpotifyIdFromTripleJTrack(token, name, artist);
  if (trackData.error) {
    trackData.matchError = true;
    return trackData;
  }

  const response = await apiUpdateTrackShortlist(token, trackData);
  if (response.error) return response;
  return trackData;
}

export async function findSpotifyIdFromTripleJTrack(token, name, artist) {
  const searchResults = await searchSpotify(`${name} ${artist}`, token);
  if (searchResults.error) return searchResults;
  for (let result of searchResults) {
    if (result.name === name && result.artists[0].name === artist) {
      return result;
    }
  }
  return { error: "couldn't be matched" };
}

export function getComparisonYear() {
  const now = new Date();
  return now.getMonth() > 1 ? now.getFullYear() : now.getFullYear() - 1;
}

export function checkTrackEligibility(date) {
  

  const compYear = getComparisonYear();

  if (date.length === 4) {
    return date === "" + compYear;
  }

  const parts = date.split("-");
  const year = parseInt(parts[0]);
  const month = parts[1];

  return (year === compYear) || (year === (compYear - 1) && month === "12");
}

export async function getAllTopSyncedTracks(token, time) {

  let shortlist = await apiGetShortlist(token);
  const shortlistItems = shortlist.error || !shortlist.shortlistItems ? [] : shortlist.shortlistItems.tracks

  const topTracks = await getTop(token, "tracks", time);
  if (topTracks.error) return { error: topTracks.error };

  for (let i = 0; i < topTracks.length; i++) {
    const track = topTracks[i];
    const result = shortlistItems ? shortlistItems.find(a => a.id === track.id) : false;
    topTracks[i].added = result ? true : false;
  }

  return topTracks;

}


export function getABCImageSource(item) {
  let imageUrl = item.recording?.releases?.[0]?.artwork?.[0]?.sizes?.[1]?.url;
  if (imageUrl === null || !imageUrl) imageUrl = item.release?.artwork?.[0]?.sizes?.[1]?.url;
  if (imageUrl === null || !imageUrl) imageUrl = item.release?.artwork?.[0]?.url;
  if (imageUrl === null || !imageUrl) imageUrl = item.recording?.artists?.[0]?.artwork?.[0]?.sizes?.[1]?.url;
  if (imageUrl === null || !imageUrl) imageUrl = "https://www.abc.net.au/core-assets/music/fallback-album.png";
  return imageUrl;
}

export function getABCReleaseYear(item) {
  let releaseYear = item.release?.release_year;
  if (releaseYear === null || !releaseYear) releaseYear = item.recording?.release_year;
  if (releaseYear === null || !releaseYear) releaseYear = item.recording?.releases?.[0]?.release_year;
  if (releaseYear === null || !releaseYear) releaseYear = "2010";
  return releaseYear;
}


