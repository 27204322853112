import React from 'react';

import { Eligibility, SpotifyLink, AudioPreview, AddToShortlist } from '../Common';


const TrendingTrack = ({imageSource, ranking, title, artist, audioSource, isEligible, 
    spotifyUrl, added, addToShortlist, token, id}) => {

  return (
    <li className="top-track fr ac">
      <img className="thumb-med" src={imageSource} alt="track"></img>
      <div className="track-info fc">
        <div>
          <div className="fr ac">
            <div className="ranking-title fr">
              <p className="ranking">{`#${ranking}.`}</p>
              <p className="default rank-title overflow-ellipsis">{title}</p>
            </div>
            <Eligibility eligibility={isEligible}/>
          </div>
          <p className="artist overflow-ellipsis bold">{artist}</p>
        </div>
        <div className="shortlist-actions">
          <AudioPreview audioSource={audioSource}/>
          <SpotifyLink url={spotifyUrl} />
          { token && <AddToShortlist added={added} addToShortlist={addToShortlist} index={id} /> }
        </div>
      </div>
    </li>
  )
};

export default TrendingTrack;