import { axiosWrapper } from './foundation';
import { getComparisonYear } from './helpers';

import { apiUpdateTrackShortlist } from './functionApp';

const baseUrl = "https://api.spotify.com/v1"

export async function getPlaylist(token, playlistId) {
  const url = `${baseUrl}/playlists/${playlistId}`;
  const Authorization = `Bearer ${token}`;
  const headers = { Authorization };
  let response = await axiosWrapper("GET", url, null, headers).catch(err => err);
  if (response.error) return {error: response.error, token};
  return response;
}

export async function generatePlaylistFromShortlist(token, tracks) {
  const trackUris = tracks.map(a => a.uri);
  const playlistId = await createPlaylist(token);
  if (!playlistId || playlistId.error) return playlistId;

  const result = await addTracksToPlaylist(token, playlistId, trackUris);
  if (!result || result.error) return result;

  return playlistId;
}

export async function syncPlaylistHelper(token, playlistId, shortlistTracks, push, pull) {
  
  const playlist = await getPlaylist(token, playlistId);
  if (!playlist || playlist.error) return playlist;

  const tracks = playlist.data.tracks.items;
  const trackUris = tracks.map(a => a.track.uri);

  //Don't need to remove tracks anymore
  // const removeResult = await removeTracksFromPlaylist(token, playlistId, trackUris);
  // if (!removeResult || removeResult.error) return removeResult;

  const shortlistUris = shortlistTracks.map(a => a.uri);

  //Add any tracks that are in their spotify but aren't in urpix
  let toPush = [];
  if (push) {
    for (let uri of shortlistUris) {
      if (!trackUris.includes(uri)) toPush.push(uri);
    }
  } 


  let toPull = [];
  if (pull && toPull.length > 0) {
    for (let uri in trackUris) {
      if (!shortlistUris.includes(uri)) toPull.push(uri);
    }
  }

  if (push && toPush.length > 0) {
    //If push is true, this will add tracks from urpix shortlist
    const addResult = await addTracksToPlaylist(token, playlistId, toPush);
    if (!addResult || addResult.error) return addResult;
  }

  //This will get the playlist with newly added tracks. It could have extra tracks
  const playlistResult2 = await getPlaylist(token, playlistId);
  if (!playlistResult2 || playlistResult2.error) return playlistResult2;

  //If we aren't pulling the extra tracks, we want to filter them out
  if (!pull) {
    playlistResult2.data.tracks.items = playlistResult2.data.tracks.items.filter((a) => !toPull.includes(a.track.uri));
  }

  if (pull) {
    for (let track of playlistResult2.data.tracks.items) {
      if (!shortlistUris.includes(track.track.uri)) {
        await apiUpdateTrackShortlist(token, track.track);
      }
    }
  }

  return playlistResult2.data;

} 

//If the user deletes the playlist in spotify, it's just unfollowed and deleted later?
//If the playlist exists we just need to refollow it so it shows up for them
export async function followPlaylist(token, playlistId) {

  const url = `${baseUrl}/playlists/${playlistId}/followers`

  const data = { public: false };
  const headers = { 
    "Authorization":  `Bearer ${token}`,
    "Content-Type": "application/json"
  };

  let response = await axiosWrapper("PUT", url, data, headers).catch(err => err);

  if (response.error) return {error: response.error, token};
  return true;

} 

// async function removeTracksFromPlaylist(token, playlistId, trackUris) {
  
//   const url = `${baseUrl}/playlists/${playlistId}/tracks`;

//   const data = { 
//     tracks: trackUris
//   }

//   const headers = { 
//     "Authorization":  `Bearer ${token}`,
//     "Content-Type": "application/json"
//   };

//   let response = await axiosWrapper("DELETE", url, data, headers).catch(err => err);

//   if (response.error) return {error: response.error, token};
//   return true;

// }

async function addTracksToPlaylist(token, playlistId, trackUris) {

  const url = `${baseUrl}/playlists/${playlistId}/tracks`;

  const data = { 
    uris: trackUris
  }

  const Authorization = `Bearer ${token}`;
  const headers = { Authorization };

  let response = await axiosWrapper("POST", url, data, headers).catch(err => err);
  if (response.error) return {error: response.error, token};
  return true;
}

async function createPlaylist(token) {

  const userId = await getSpotifyId(token);
  if (!userId || userId.error) return userId;

  const url = `${baseUrl}/users/${userId}/playlists`;

  const data = { 
    name: `Hottest 100 Picks ${getComparisonYear()} - urpix.net`, 
    description: "this is an automated hottest 100 playlist generated via urpix.net", 
    public: false 
  }

  const headers = { 
    "Authorization":  `Bearer ${token}`,
    "Content-Type": "appliaction/json"
  };

  let response = await axiosWrapper("POST", url, data, headers).catch(err => err);
  if (response.error) return {error: response.error, token};

  return response.data.id;
}

export async function getSpotifyId(token) {
  const url = `${baseUrl}/me`;
  const Authorization = `Bearer ${token}`;
  const headers = { Authorization };

  const response = await axiosWrapper("GET", url, null, headers).catch(err => {err.error = true; return err;});
  return response.error ? response : response.data.id;
}

export async function searchSpotify(query, token, type="track") {

  const url = `${baseUrl}/search?q=${query}&type=${type}`;

  const Authorization = `Bearer ${token}`;
  const headers = { Authorization };

  let response = await axiosWrapper("GET", url, null, headers).catch(err => err);
  if (response.error) return {error: response.error, token};
  const items = type === "track" ? response.data.tracks.items : response.data.artists.items;

  return type === "track" ? filterSpotifySearch(items) : items;

}

function filterSpotifySearch(tracks) {

  let filteredTracks = [];
  for (let track of tracks) {
    const foundIndex = filteredTracks.findIndex(e => e.name === track.name && 
          e.artists[0].name === track.artists[0].name);
    if (foundIndex !== -1) {
        //Here we want to add the oldest track
        if (track.album.release_date < filteredTracks[foundIndex].album.release_date) {
          filteredTracks[foundIndex] = track;
        }
        continue;
    };
    filteredTracks.push(track);
  }

  //Only return the top 10 results
  filteredTracks = filteredTracks.slice(0, 10);
  return filteredTracks;
}

export async function getTop(token, type, time) {
  const trackData = await axiosWrapper(
    "GET",
    `${baseUrl}/me/top/${type}?limit=50&time_range=${time}_term`,
    null,
    { "Authorization": "Bearer " + token }
  );
  if (trackData.error) return {error: trackData.error};
  
  return trackData.data.items;
}


//This function takes the spotify Ids of the tracks in the user's shorlist and grabs
//all the data associated with them
export async function getShortlistData(token, shortlist) {

  let ids = "";
  for (let i = 0; i < shortlist.length; i++) {
    if (i === 0) ids += shortlist[i].id;
    ids += "," + shortlist[i].id;
  }

  const response = await axiosWrapper(
    "GET",
    `${baseUrl}/tracks?ids=${ids}`,
    null,
    { "Authorization": "Bearer " + token }
  );
  
  const tracks = response.tracks;

  for (let i = 0; i < shortlist.length; i++) {
    shortlist[i].data = tracks.find(x => x.id === shortlist[i].id);
  }

  return shortlist;

}
