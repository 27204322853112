import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import '../../../style/top3Leaderboard.css';

const Leaderboard = ({ players, selected }) => {

  const [ showPot, setShowPot ] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("simulation")) return;

    const interval = setInterval(() => {
      setShowPot((prev) => !prev);
    }, 20000);
    return () => clearInterval(interval);
  }, [location.pathname])


  const renderPlayers = () => {
    const clone = [ ...players ];
    const sorted = showPot 
          ? clone.sort((a, b) => a.potentialScore < b.potentialScore ? 1 : -1)
          : clone.sort((a, b) => a.score < b.score ? 1 : -1);
    return sorted.map((item, i) => {
      return (
        <Player index={i} name={item.name} picks={item.picks} rank={i+1} score={item.score} key={i}
          first={i === 0 ? "first" : ""} showPot={showPot} potentialScore={item.potentialScore}
          playerImage={item.imageURL} />
      )
    });
  }

  /**
   * For testing, want to be able to change which mode the leaderboard is in
   */
  const renderSimulationHelper = () => {
    return (  
      <div id="leaderboard-simulation-helper" onChange={(e) => setShowPot(e.target.value !== 'raw')}>
        <select>
          <option value='raw'>raw score</option>
          <option value='potential'>potential score</option>
        </select>
      </div>
    )
  }


  if (!players || players === null) return <div></div>;

  return (

    <div id="top3-leaderboard" className={selected ? "desktop-section live-comp mobile-selected" : "desktop-section live-comp"} >
      { location.pathname.includes("simulation") && renderSimulationHelper()}
      <h1>leaderboard</h1>
      {players !== null && renderPlayers()}
    </div>

  );

}

const Player = ({ imageSrc, name, rank, score, first, showPot, potentialScore, playerImage }) => {

  return (
    <div className={`fr ac player ${first}`} id={`player-leaderboard-${name}`}>
      <p className="ranking top-rank board-rank">#{rank}</p>
      <img className="thumb-med player-image" src={playerImage ? playerImage : require(`../../../images/user.png`)} alt={"player"}></img>
      <div className="playerInfo fc jc">
        <p className="light-text rank-title overflow-ellipsis player-name">{name}</p>
        {!showPot && <p className="artist overflow-ellipsis bold player-score">score: {score === -1 ? "BUST": score}</p> }
        {showPot && <p className="artist overflow-ellipsis bold player-score">potential score: {score === -1 ? "BUST": potentialScore}</p> }
      </div>
    </div>
  )
};


export default Leaderboard;