import React, { useContext } from 'react';

import { AuthWarning, Header, Loader } from '../Common';

import PreviewElements from './PreviewElements';
import PreviewUserCompanions from './PreviewUserCompanions';

import { CompanionContext } from '../Providers/CompanionProvider';
import { TokenContext } from '../Providers/TokenProvider';

const CompanionPreviews = () => {

  const [ companionData ] = useContext(CompanionContext);
  const [ token ] = useContext(TokenContext);

  const renderAuth = () => {

    if (!token) {
      return (
        <AuthWarning redirectEndpoint={"companions/previews"} 
          title="keen to play?"
          summary="authenticate through spotify to be able to sign up and view our companion apps"
          />
      )
    }
    if (token && !companionData) {
      return (
        <>
          <p>loading your companion app data</p>
          <Loader />
        </>
      )
    }
  }

  const renderUsersCompanions = () => {
    return (
      <>
        <h1>your companions</h1>
        <PreviewUserCompanions companionData={companionData} />
      </>
    );
  }

  return (
    <div>
      <div>
      <Header 
          header="hottest 100 of 2023 companion apps"
          subHeader="new for the hottest 100 of 2023! whether you want to play a countdown game with your mates or just track the countdown on the TV we’ve got you covered."
        />
      </div>

      { renderAuth() }
      { companionData && renderUsersCompanions() }
      { (!token) && <PreviewElements /> }
    </div>
  )
};

export default CompanionPreviews;