import React from "react";

import { getABCImageSource } from "../../services/helpers";


const NowPlaying = ({ tracks }) => {


  return (
    <div id="now-playing" className="fr ac">
      <div className="fr ac jc"> 
        <h4>Now Playing:</h4>
        <img id="highlight-img" src={getABCImageSource(tracks[0])} alt="highlight"></img>
        <div className="fc np-track-info">
          <p className="light-text">{tracks[0].recording.title}</p>
          <p className="bold">{tracks[0].recording.artists[0].name}</p>
        </div>
      </div>
    </div>
  )


}


export default NowPlaying;