import React, { useState, useEffect, useContext } from 'react';

import TopMenu from '../Menu/TopMenu';
import Popular from './Popular';
import Playing from './Playing';
import Archives from './Archives';

import SignInPopupProvider from '../Providers/SignInPopupProvider';

import { Route, Routes, Navigate } from 'react-router-dom';
import { tripleJMenuItems } from '../Content/Content';
import { TokenContext } from '../Providers/TokenProvider';
import { apiGetPopularData, apiGetShortlist } from '../../services/functionApp';

const TripleJ = () => {

  const [ token ] = useContext(TokenContext);
  
  const [ loaded, setLoaded ] = useState(false);
  const [popularData, setPopularData] = useState({});
  const [ topMenuItems, setTopMenuItems ] = useState(tripleJMenuItems);

  const [ shortlist, setShortlist ] = useState([]);

  const [ popularDataError, setPopularDataError ] = useState(false);

  useEffect(() => {
    if (loaded) return;
    async function getAllPopularData() {
      let data = await apiGetPopularData();     
      if (data.error) {
        setPopularDataError(true);
      }

      if (token) {
        const shortlist = await apiGetShortlist(token);
        if (shortlist.hasShortlist) {
          data = updateAddedStatus(data, shortlist.shortlistItems.tracks ? shortlist.shortlistItems.tracks : []);
          setShortlist(shortlist.shortlistItems.tracks);
        }
      }

      setPopularData(data);
      setLoaded(true);
    }
    getAllPopularData();
  });

  const updateAddedStatus = (popularData, shortlist) => {
    for (let prop in popularData.tracks) {
      for (let i = 0; i < popularData.tracks[prop].length; i++) {
        let popTrack = popularData.tracks[prop][i];
        for (let shortlistTrack of shortlist) {
          if (shortlistTrack.trackName === popTrack.trackName &&
              shortlistTrack.artistName === popTrack.artistName) {
            popularData.tracks[prop][i].added = true;
            break;
          }
          if (!popTrack.added) popularData.tracks[prop][i].added = false;
        }
      }
    }
    return popularData;
  };


  return (
    <SignInPopupProvider>
      <TopMenu topMenuItems={topMenuItems} setTopMenuItems={setTopMenuItems} />

      <Routes>
        <Route exact path='/' element={ <Navigate replace to="./playing" /> } />
        <Route path='/playing/*' element={<Playing shortlist={shortlist} setShortlist={setShortlist} />} />
        <Route path='/popular-now/*' element={
          <Popular shortlist={shortlist} loaded={loaded} popularData={popularData} 
            setPopularData={setPopularData} popularDataError={popularDataError} setShortlist={setShortlist} />} 
        />
        <Route path='/archives/*' element={<Archives />} />
        <Route path='/*' element={<Navigate replace to="/404" />} />
      </Routes>


    </SignInPopupProvider>
  )
};

export default TripleJ;