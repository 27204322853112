import axios from 'axios';
const baseUrl = "https://music.abcradio.net.au"
// export const startTime = Date.now() - 100000000;
// export const startTime = Date.parse("2023-11-23:11:00") //tomorrow
export const startTime = Date.parse("2024-01-27:11:00") //2023 countdown
export const startTime2023 = Date.parse("2024-01-27:11:00") //2023 countdown
// export const startTime = 1674860400000; //2022 countdown
// export const startTime = 1642813200000; //2021 countdown


export async function getTripleJRecentlyPlayed(lastTimeStamp, from) {

  let timeIntervalMinutes = 60;

  let items = [];
  const sendSize = 5;
  let attempts = 3;

  while (items.length < sendSize && attempts > 0) {
    
    from = new Date(new Date(lastTimeStamp) - timeIntervalMinutes * 1000 * 60).toISOString();
    const response = await getTracks("triplej", from, lastTimeStamp, 5, "desc");
    if (response.error && !response.response.status === 404) return response;
    
    if (!response.items) response.items = [];
    items = response.items;
    timeIntervalMinutes += 60;
    attempts--;
  }

  if (items.length === 0) return { items, lastTimeStamp };

  items = items.slice(0, sendSize);

  let lastStamp = items[items.length - 1].played_time;
  lastStamp = new Date(new Date(lastStamp).getTime() - 1000).toISOString();
  return {items, stamp: lastStamp };
}

async function getTracks(station="triplej", from="2020-01-01T00:00:00", to="2020-01-01T04:00:00", limit=100, order="asc", offset=0) {

  const paginationString = paginationHandler({station, from, to, limit, order, offset});

  const queryUrl = `${baseUrl}/api/v1/plays/search.json${paginationString}`;
  const response = await axios.get(queryUrl).catch(err => {err.error = true; return err;});

  if (response.error) return response;
  return response.data;

}


async function getTracksByLimit(station="triplej", offset=0, limit=100, order="desc", tz="Australia%2FSydney") {

  const paginationString = paginationHandler({station, offset, limit, order, tz});
  const queryUrl = `${baseUrl}/api/v1/plays/search.json${paginationString}`;

  const response = await axios.get(queryUrl).catch(err => {err.error = true; return err;});

  if (response.error) return response;
  return response.data;

}

function paginationHandler(options) {
  let optionsPresent = [];
  for (let option in options) {
    optionsPresent.push(`${option}=${options[option]}`);
  }
  const optionsString = optionsPresent.join("&");
  if (optionsPresent.length === 0) return "";
  return "?" + optionsString;
}

async function getHottest100FromTimeStamp(time) {

  const from = new Date(time - 1000 * 60).toISOString();
  const to = new Date(new Date(time).getTime() + (10 * 1000 * 60 * 60)).toISOString();
  const response1 = await getTracks("triplej", from, to, 200, "desc", 0);
  let items = response1.items;
  if (response1.total > 100) {
    const response2 = await getTracks("triplej", from, to, 200, "desc", 100);
    items = items.concat(response2.items);
  }
  items = items.sort((a, b) => a.played_time < b.played_time);
  return orderHottest100(items);

}

export async function getHottest100() {

  const now = Date.now();
  const cutOff = startTime + 1000 * 60 * 60 * 12;
  if (now < cutOff) return await getHottest100SoFar();

  return getHottest100FromTimeStamp(startTime);

}

//Get all tracks back till the startDate
async function getHottest100SoFar() {
  //Get last 200 tracks

  let items = [];

  for (let i = 0; i < 4; i++) {
    const response = await getTracksByLimit("triplej", i*50, 50);
    if (response.error && !response.response.status === 404) return response;
    if (!response.items) response.items = [];
    items = items.concat(response.items);
  }

  return orderHottest100(items);
}

function orderHottest100(items) {

  items = items.sort((a, b) => a.played_time > b.played_time);

  let index = -1;
  for (let i = 0; i < items.length; i++) {
    if (Date.parse(items[i].played_time) < startTime) {
      index = i;
      break;
    }
  }

  if (index !== -1 ) items = items.splice(0, index);

  if (items.length > 100) items = items.splice(items.length - 100);
  for (let i = 0; i < items.length; i++) {
    items[i].placing = 100 - items.length + i + 1;
  }

  return items;
}


export function getABCImageSource(item) {
  let imageUrl = item.recording?.releases?.[0]?.artwork?.[0]?.sizes?.[1]?.url;
  if (imageUrl === null || !imageUrl) imageUrl = item.release?.artwork?.[0]?.sizes?.[1]?.url;
  if (imageUrl === null || !imageUrl) imageUrl = item.release?.artwork?.[0]?.url;
  if (imageUrl === null || !imageUrl) imageUrl = item.recording?.artists?.[0]?.artwork?.[0]?.sizes?.[1]?.url;
  if (imageUrl === null || !imageUrl) imageUrl = "https://www.abc.net.au/core-assets/music/fallback-album.png";
  return imageUrl;
}