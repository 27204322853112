import React, { useState, useEffect, useContext } from 'react';
import { TokenContext } from './TokenProvider';
import { apiGetCompanionsFromSpotifyId } from '../../services/functionApp';

// import { useParams } from 'react-router-dom';
export const CompanionContext = React.createContext();

const CompanionProvider = ({children}) => {

  const [ token ] = useContext(TokenContext);
  const [ companionData, setCompanionData ] = useState(null);

  useEffect(() => {

    if (!token) return;

    async function getCompanionData() {

      //Get user's companion data
      if (companionData == null) {
        const companionsResponse = await apiGetCompanionsFromSpotifyId(token);
        setCompanionData(companionsResponse);
      } 
      
    }

    getCompanionData();
  }, [ token, companionData ])

  return (
    <CompanionContext.Provider value={[companionData, setCompanionData]}>
      {children}
    </CompanionContext.Provider>
  )

};

export default CompanionProvider;