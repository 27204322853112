import React, { useState, useContext } from 'react';

import { Header, AuthPrompt } from '../Common';
import TopMenu from '../Menu/TopMenu';
import Top from './Top';

import { Routes, Route, Navigate } from 'react-router-dom';
import { TokenContext } from '../Providers/TokenProvider';
import { spotifyMenuItems } from '../Content/Content';


const Spotify = () => {

  const [ token ] = useContext(TokenContext);
  const [ topMenuItems, setTopMenuItems ] = useState(spotifyMenuItems);


  if (!token) {
    return (
      <div>
        <AuthPrompt 
          header="your top spotify listens"
          subHeader="to view your top spotify listens you will first need to authenticate through spotify"
          redirectEndpoint="spotify"
        />
      </div>
    )
  }

  return (
    <div>
      <TopMenu topMenuItems={topMenuItems} setTopMenuItems={setTopMenuItems} />
      <Header 
      header="your top listens"
      subHeader="view your most listened to tracks and artists over time"
      />

      <Routes>
        <Route exact path='/' element={ <Navigate replace to="./top/tracks/short" /> } />
        <Route exact path='/top' element={ <Navigate replace to="./tracks/short" /> } />
        <Route path='/top/:type/:time/*' element={<Top />}> </Route>
        <Route path='/top/:type/*' element={<Navigate replace to="./short" />}> </Route>
        <Route path='/*' element={<Navigate replace to="/404" />} />
      </Routes>
    </div>

  )
};


export default Spotify;