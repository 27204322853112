import React from 'react';

import Header from './Header';

import { spotifyClientId, spotifyAuthEndpoint, spotifyResponseType, scope } from '../../services/credentials';

import '../../style/header.css';
import '../../style/authPrompt.css';

const AuthPrompt = ({ header, subHeader, redirectEndpoint }) => {

  return (
    <div>
      <Header header={header} subHeader={subHeader} />
      <a id="auth" className="fr ac" href={`${spotifyAuthEndpoint}?client_id=${spotifyClientId}&redirect_uri=${window.location.origin}/${redirectEndpoint}&response_type=${spotifyResponseType}&scope=${scope}`}>
        <img id="spotify-auth" src={require("../../images/menu-items/spotify.png")} alt="spotify logo"></img>
        <p className="bold">authenticate now</p>
      </a>
    </div>
  )
};

export default AuthPrompt;