import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import TokenProvider from './components/Providers/TokenProvider';
import AudioProvider from './components/Providers/AudioProvider';
import CompanionProvider from './components/Providers/CompanionProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AudioProvider>
    <TokenProvider>
      <CompanionProvider>
        <App />
      </CompanionProvider>
    </TokenProvider>
  </AudioProvider>
);


