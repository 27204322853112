import React from 'react';

import { AudioPreview, Eligibility, SpotifyLink, ImageUploader } from '../../Common';
import Top3Search from './Top3Search';
import { checkTrackEligibility } from '../../../services/helpers';

import "../../../style/top3outside3.css";
import { PICKS } from '../../../services/GameHelpers';

const Top3Outside3AddPlayer = ({ companionData, updateCompanionData, playerId, setToggleAdd }) => {

  const getPlayer = () => {
    const playerIndex = companionData.top3Outside3.players.findIndex(a => a.id === playerId);
    return companionData.top3Outside3.players[playerIndex];
  }

  const updatePlayerName = (e) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    const playerIndex = clone.top3Outside3.players.findIndex(a => a.id === playerId);
    clone.top3Outside3.players[playerIndex].name = e.target.value;
    updateCompanionData(clone);
  }

  const convertSpotifyData = (data) => {
    return {
      id: data.id, 
      uri: data.uri,
      audioSrc: data.preview_url,
      spotifyUrl: data.external_urls.spotify,
      imageSrc: data.album.images[1].url,
      releaseDate: data.album.release_date,
      trackName: data.name,
      artistName: data.artists[0].name
    };
  }

  const handleAddTrack = (data) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    const playerIndex = clone.top3Outside3.players.findIndex(a => a.id === playerId);
    clone.top3Outside3.players[playerIndex].tracks.push(convertSpotifyData(data));
    updateCompanionData(clone);
  }

  const removeTrack = (index) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    const playerIndex = clone.top3Outside3.players.findIndex(a => a.id === playerId);
    clone.top3Outside3.players[playerIndex].tracks.splice(index, 1);
    updateCompanionData(clone);
  }

  const handleAddImage = (imageUrl) => {
    const clone = JSON.parse(JSON.stringify(companionData));
    const playerIndex = clone.top3Outside3.players.findIndex(a => a.id === playerId);
    clone.top3Outside3.players[playerIndex].imageURL = imageUrl;
    updateCompanionData(clone);
  }

  const updateInfo = () => {
    updateCompanionData(companionData);
    setToggleAdd(false);
  }

  const renderUserPicks = () => {
    return getPlayer().tracks.map((item, i) => {
      return (
        <div className="player-pick fr ac" key={i}>
          <img className="thumb-med" src={item.imageSrc} alt=""></img>
          <div className="player-pick-info">
            <div className="fr ac">
              <p  className="default rank-title overflow-ellipsis">{item.trackName}</p>
              <Eligibility eligibility={checkTrackEligibility(item.releaseDate)} />
            </div>
            <p className="artist overflow-ellipsis bold">{item.artistName}</p>
            <div className="player-pick-actions fr">
              <div className="fr">
                <AudioPreview audioSource={item.audioSrc} />
                <SpotifyLink url={item.spotifyUrl} />
              </div>
              <img className="cross" src={require("../../../images/close.png")} alt="cross" 
                  onClick={() => removeTrack(i)}></img>
            </div>
          </div>
        </div>
      )
    });
  }

  return (
    <div id="add-player-form">
      <h1>edit player</h1>

      <div className="fr ac">
        <p className="pad-right">name:</p>
        <input id="new-player-name" type="text" value={getPlayer().name} onChange={updatePlayerName}></input>
      </div>

      <ImageUploader addImage={handleAddImage} profileImage={getPlayer().imageURL} />

      <h3>picks</h3>

      <div id="user-tracks">
        {getPlayer().tracks.length === 0 && <p>search tracks below to add them to your picks</p>}
        {renderUserPicks()}
      </div>

      {getPlayer().tracks.length !== PICKS && 
        <div>
          <div className="fr ac">
            <img className="player-eligibility" src={require(`../../../images/warning.png`)} alt="eligibility"></img>       
            <p data-test="missing-tracks-message">you do not yet have 5 picks. use the search below to add more tracks</p>
          </div>
          <Top3Search addTrack={handleAddTrack} tracks={getPlayer().tracks} />

        </div>
      }

      {getPlayer().tracks.length === PICKS && 
        <p data-test='full-tracks-message'>you have 5 tracks selected. if you want to add a different track, please remove one first.</p>
      } 
      
      <button className="update-info-button" onClick={updateInfo}>update info</button>

    </div>
  )

}

export default Top3Outside3AddPlayer
