import React, { useState, useContext } from 'react';

import { Header, Loader, Modal, Alert, AuthWarning, ErrorMessage } from '../Common';
import PopularTrack from './PopularTrack';
import PopularArtist from './PopularArtist';

import { RegisterPageHit } from '../../services/googleAnalyticsHelper';
import { TokenContext } from '../Providers/TokenProvider';
import { checkTrackEligibility, addTrackFromTripleJ } from '../../services/helpers';
import { RecordButtonClick } from '../../services/googleAnalyticsHelper';


const Popular = ({ loaded, popularData, setPopularData, popularDataError, shortlist, setShortlist }) => {

  RegisterPageHit("/popular", "popular triple j page");

  const [ token ] = useContext(TokenContext);

  const [ selectedTime, setSelectedTime ] = useState("week");
  const [ selectedType, setSelectedType] = useState("tracks");

  const [ showModal, setShowModal ] = useState(false);
  const [ modalContent, setModalContent] = useState({});

  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ alertContent, setAlertContent ] = useState({});

  const [ errorContent, setErrorContent ] = useState({});
  const [ loadError, setLoadError ] = useState(false);

  const updateAlert = (alert) => {
    setAlertContent({
      imageSource: alert.imageSource,
      title: alert.trackName,
      artist: alert.artistName,
      added: true
    });
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000);
  }

  const triggerModalAdd = (index) => {
    RecordButtonClick("Trigger Modal Add");
    const data = {
      imageSource: popularData.tracks[selectedTime][index - 1].imageSource,
      name: popularData.tracks[selectedTime][index - 1].trackName
    }

    setModalContent({
      data,
      callbackPayload: index,
      add: true,
      confirmCallback: addTripleJTrackToShortlist
    });
    setShowModal(true);
  }

  const addTripleJTrackToShortlist = async (index) => {
    RecordButtonClick(`Confirm adding track: ${popularData.tracks[selectedTime][index - 1].trackName} By ${popularData.tracks[selectedTime][index - 1].artistName}`);
    
    const response = await addTrackFromTripleJ(token, 
      popularData.tracks[selectedTime][index - 1].trackName, popularData.tracks[selectedTime][index - 1].artistName);

    if (response.error) {
      const content = response.matchError 
        ? "the track couldn't be matched on spotify. please try adding via the shortlist section"
        : "we encountered a technical issue when trying to add that track to your shortlist";
      setLoadError(true);
      setErrorContent({
        header: "we're sorry",
        subHeader: content,
        imageSource: "warning.png",
        closable: true,
        setShow: setLoadError
      });
      return;
    }

    //Update added status of that track
    const popCopy = JSON.parse(JSON.stringify(popularData));
    popCopy.tracks[selectedTime][index - 1].added = true;
    setPopularData(popCopy);

    const clone = shortlist ? JSON.parse(JSON.stringify(shortlist)) : [];    
    clone.push({
      id: response.id, 
      index: clone.length, 
      dateAdded: Date.now(),
      uri: response.uri,
      audioSrc: response.preview_url,
      spotifyUrl: response.external_urls.spotify,
      imageSrc: response.album.images[1].url,
      releaseDate: response.album.release_date,
      trackName: response.name,
      artistName: response.artists[0].name
    });
    setShortlist(clone);

    setShowModal(false);
    setLoadError(false);
    updateAlert(popularData.tracks[selectedTime][index - 1]);
  }

  const processTimeChange = (e) => setSelectedTime(e.target.value) 
  const processTypeChange = (e) => setSelectedType(e.target.value) 


  const renderPopularTracks = () => {

    const tracks = popularData.tracks[selectedTime];
    return tracks.map((item, i) => {
      return (
        <PopularTrack key={i} index={i+1} title={item.trackName} artist={item.artistName} 
          imageSource={item.imageSource} played={item.score} eligibility={checkTrackEligibility(item.releaseYear)}
          addToShortlist={triggerModalAdd} added={item.added} />
      )
    });

  }

  const renderPopularArtists = () => {

    const artists = popularData.artists[selectedTime];
    return artists.map((item, i) => {
      return (
        <PopularArtist key={i} index={i+1} artist={item.artistName} played={item.score} 
          imageSource={item.imageSource} songsPlayed={item.uniqueSongs.length} />
      )
    });

  }

  const processPopularData = () => {
    if (selectedType === "tracks") return renderPopularTracks();
    if (selectedType === "artists") return renderPopularArtists();
  }

  if (popularDataError) {
    return (
      <div>
        <Header 
          header="popular on triple j right now"
          subHeader="see the most popular tracks and artists being played on triple j right now"
        />
        <ErrorMessage data={{
          header: "we're sorry",
          subHeader: "we encountered a technical issue when trying to load triple j popular data",
          imageSource: "warning.png",
          closable: false
        }} />
      </div>
    )
  }

  return (
    <div>
      <Header 
        header="popular on triple j right now"
        subHeader="see the most popular tracks and artists being played on triple j right now"
      />

      { loadError && <ErrorMessage data={errorContent} /> }

      <Alert data={alertContent} visible={alertVisible} />
      <Modal show={showModal} setShow={setShowModal} content={modalContent} />

      <div id="time-switcher">
        <label>top </label>
        <select data-test="top-switcher" onChange={processTypeChange} value={selectedType} className="select-label">
          <option value="tracks">tracks</option>
          <option value="artists">artists</option>
        </select>
        <label>of the past</label>
        <select data-test="time-switcher" onChange={processTimeChange} value={selectedTime} className="select-label">
          <option value="2 days">2 days</option>
          <option value="week">week</option>
          <option value="fortnight">fortnight</option>
          <option value="month">month</option>
          <option value="3 months">3 months</option>
          <option value="6 months">6 months</option>
        </select>
        
      </div>

      {!token && <AuthWarning redirectEndpoint={"triple-j/popular-now"} />}
      
      <div id="top-tracks" className="fc">
        {!loaded && <Loader />}
        {loaded && processPopularData()}
      </div>


    </div>

  )
};

export default Popular;