import React from 'react';
import Countdown from 'react-countdown';

import '../../style/countdown.css';

const Completionist = () => <p id="countdown">Waiting for first track...</p>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {

  const getTimePart = (num, type) => {
    if (num === 0) return "";
    return num === 1 ? `${num} ${type} ` : `${num} ${type}s `
  }

  const getTime = () => {
    return `${getTimePart(days, "day")}${getTimePart(hours, "hour")}${getTimePart(minutes, "minute")}${getTimePart(seconds, "second")}`;
  }

  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (   
        <p id="countdown">{getTime()}</p>
    )
  }
};

const CountdownHandler = ({ date }) => {

  return (
    <div id="countdown-container">      
      <img id="logo" alt="logo" src={require("../../images/logo.png")}></img>
      <Countdown date={date} renderer={renderer} />
      <div id="flowers" className="fr">
        <img alt="flower1" className="flower" src={require("../../images/jjjflower1.png")}></img>
        <img alt="flower2" className="flower" src={require("../../images/jjjflower2.png")}></img>
        <img alt="flower3" className="flower" src={require("../../images/jjjflower3.png")}></img>
      </div>
    </div>
  )
};



export default CountdownHandler