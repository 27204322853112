import React, { useState, useEffect } from "react";
import { getABCImageSource } from "../../../services/helpers";


const BingoHightlight = ({highlights}) => {

  const [ updateIndex, setUpdateIndex ] = useState(0);

  //Some dodgy shit in here
  useEffect(() => {
    if (!highlights || highlights.length === 1) setUpdateIndex(0);

    if (highlights?.length > 0) {
      setTimeout(() => {
        if (!highlights || highlights.length === 1) setUpdateIndex(0);
        setUpdateIndex(updateIndex + 1 === highlights.length ? 0 : updateIndex + 1
      )}, 10000);
    }
  }, [updateIndex, highlights])

  if (!highlights || highlights.length === 0) return <></>;

  const getRelativeIndex = () => {
    if (!highlights || highlights.length < 2) return 0;
    return updateIndex;
  }

  const renderBoard = () => {

    return highlights[getRelativeIndex()].board.tracks.map((item, i) => {


      if (item?.type === "track") {
        return (
          <div className={`bingo-grid grid-${i%4} bingo-track fr ac jc point`} key={i} index={i}
              style={{ backgroundImage: `url(${item.imageSrc})` }}>
              <div className={item.match ? "bingo-hold" : "full-width"}>
                <div className="bingo-pick-bg fc ac">
                  {item.match && <h3 className="ranking top-rank bingo-rank">#{item.match}</h3>}
                  <p className="default">{item.trackName}</p>
                  <p className="bold">{item.artistName}</p>
                </div>
              </div>
          </div>
        )
      }

      if (item?.type === "artist") {
        return (
          <div className={`bingo-grid grid-${i%4} bingo-artist fr ac jc point`} key={i} index={i}
              style={{ backgroundImage: `url(${item.imageSrc})` }}>
            <div className={item.match ? "bingo-hold" : "full-width"}>
              <div className="bingo-pick-bg fc ac">
                {item.match && <h3 className="ranking top-rank bingo-rank">#{item.match}</h3>}
                <p className="bold">{item.artistName}</p>
              </div>
            </div>
          </div>
        )
      }
      
      return (
        <div className={`bingo-grid fr ac jc grid-${i%4}`} key={i} index={i} >
          <img className="add-s" src={require(`../../../images/close.png`)} alt="add player"></img>
          <p className="bold">N/A</p>
        </div>
      )

    });
  }

  const renderBingoPick = () => {
    
    const track = highlights[getRelativeIndex()].latestTrack;

    return (
      <div id="player-update-track" className="fr ac jc">
        <p className="ranking update-rank">#{track.placing}.</p>
        <img className="thumb-med" src={getABCImageSource(track)} alt="track"></img>
        <div className="player-update-track-info">
          <p className="default">{track.recording.title}</p>
          <p className="bold">{track.recording.artists[0].name}</p>
        </div>
      </div>
    )

  }

  return (
    <div id="player-update">
      <div id="bingo-update-content" className="fr ac">
        <div id="bingo-update-left">
          <h1>player update</h1>
          <p>one of {highlights[getRelativeIndex()].board.name}'s picks is playing</p>
          {renderBingoPick()}
        </div>
        <div id="bingo-update-holder">
          <div id="bingo-board" className="fr">
            {renderBoard()}
          </div>
        </div>
        {/* <img id="close-update" src={require("../../../images/close.png")} alt="cross" 
                  ></img> */}
      </div>
    </div> 
  )


}

export default BingoHightlight;