import React from "react";

import { getABCImageSource } from "../../services/helpers";



const Countdown = ({ tracks, selected }) => {

  const renderTracks = () => {

    return tracks.map((item, i) => {
      let imageUrl = getABCImageSource(item);
      return (
        <CountdownTrack imageSource={imageUrl} index={i} 
          artist={item.recording.artists[0].name} key={i}
          title={item.recording.title}
          ranking={item.placing}
          first={i === 0 ? "first" : "" }
          />
      )
    });
  }

  return (
    <div className={selected ? "desktop-section live-comp mobile-selected" : "desktop-section live-comp"} >
      <h1>now playing on triple j</h1>
      <div id="tracks" className="live-comp" >
        {renderTracks()}
      </div>
    </div>
  );

}

const CountdownTrack = ({imageSource, artist, title, ranking, first}) => {

  return (
    <div className={`top-track fr ac ${first}`}>
      <img className="thumb-med" src={imageSource} alt="track"></img>
      <div className="popular-track-info fc">
        <p className="ranking top-rank">#{ranking}</p>
        <p className="light-text rank-title overflow-ellipsis">{title}</p>
        <p className="artist overflow-ellipsis bold">{artist}</p>
      </div>
    </div>

  )
};


export default Countdown;