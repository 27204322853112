import React, { useState } from 'react';

import { Header } from '../Common';

import { hottest100Results } from '../Content/Content';


const Hottest100Archive = () => {

    
  const [ selectedList, setSelectedList ] = useState(2023);
  const processListChange = (e) => setSelectedList(e.target.value) 

  const renderHottest100List = () => {
    const artists = hottest100Results[selectedList];
    return artists.map((item, i) => {
      return (
        <Hottest100Entry key={i} index={i+1} artist={item.artist} 
          imageSource={item.imageSource} track={item.track} />
      )
    });
  }


  return (
    <div>
      <Header 
        header="historic hottest 100s"
        subHeader="view the hottest 100 lists from previous years"
      />

      <div id="time-switcher">
        <label>hottest 100 of</label>
        <select data-test="time-switcher" onChange={processListChange} value={selectedList} className="select-label">
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
        </select>
      </div>

      <div id="hottest-100-list">
        {renderHottest100List()}
      </div>

    </div>
  )
  
};

const Hottest100Entry = ({imageSource, index, artist, track}) => {
  
  return (
    <div className="top-track fr ac">
      <img className="thumb-small" src={imageSource} alt="track"></img>
      <div className="popular-track-info fc">
        <div className="ranking-title">
          <p className="ranking">{`#${index}.`}</p>
          <p className="light-text overflow-ellipsis no-margin">{track}</p>
        </div>
        <p className="popular-artist-a overflow-ellipsis">{artist}</p>
      </div>
    </div>

  )
};

export default Hottest100Archive;