import React from 'react';

import '../../style/alert.css';

const Alert = ({ data, visible }) => {

  return (
    <div className={`${data.added ? "accept" : "deny"} alert ${visible ? "display-show-flex" : "display-hide"}`}>
      <div className="fr ac">
        <img src={data.imageSource} alt="track" className="alert-img pad-right"></img>
        <div className="fc">
          <p className="default no-margin">{data.title}</p>
          <p className="bold no-margin">{data.artist}</p>
        </div>
      </div>
      <div className="fr ac">
        <p className="bold alert-text">{data.added ? "added" : "removed"}</p>
        <img src={require(`../../images/tick.png`)} className="alert-icon" alt="eligibility icon"></img>
      </div>
    </div>
  )
};

export default Alert;