import React, { useState, useRef, useEffect } from 'react';

export const AudioContext = React.createContext();

const AudioProvider = ({children}) => {

  const [ src, setSrc ] = useState(null);
  const audioRef = useRef();


  useEffect(() => {
    if (src === null) return;
    audioRef.current.volume = 0.1;
    if (src === "") {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  }, [ src ]);

  return (
    <AudioContext.Provider value={[src, setSrc]}>
      <audio id="audio-player" ref={audioRef} src={src} onEnded={() => setSrc(null)}></audio>
      {children}
    </AudioContext.Provider>
  )

};

export default AudioProvider;