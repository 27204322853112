import React from "react";

import '../../style/activeMenuItem.css';

const ActiveMenuItem = ({ item, selected }) => {
  return (
    <div data-test='active-menu-item' id="active-menu-item" className="fc ac jc point">
      <div id="active-menu-content" className="fr ac">
        <img id="active-menu-item-image" src={require(`../../images/menu-items/${item.imageSource}`)} alt="menu item logo"></img>
        <p className="pageTitle">{item.name}</p>
      </div>
      {selected && <div className="stroke-underline"></div>}
    </div>
  )
};

export default ActiveMenuItem;