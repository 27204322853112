import React from 'react';

import '../../style/spotifyLink.css';

const SpotifyLink = ({url, search=false, resize=true}) => {

  return (
    <a className={`listen-spotify fr ac ${search ? "listen-spotify-search" : ""}`} href={url} target="_blank" rel="noreferrer">
      <img src={require("../../images/menu-items/spotify.png")} alt="spotify logo"></img>
      { resize &&    
        <div>
          <p className="play-on-spotify">play on spotify</p>
          <p className="view-on-spotify">view</p>
        </div>  
      }
      { !resize &&
        <p>play on spotify</p>
      }

    </a>
  )

};

export default SpotifyLink;