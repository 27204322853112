import React from "react";

const BingoBoard = ({ board, setAddToggle, setAddIndex, setSelectedIndex, setOptionsToggle, isSwapping,
                      selectedIndex, processSwap }) => {

  const addTrack = (i) => {
    setAddIndex(i);
    setAddToggle(true);
  }

  const toggleOptions = (i) => {
    setSelectedIndex(i);
    setOptionsToggle(true);
  }


  const renderSwappingBoard = () => {
    return board.tracks.map((item, i) => {

      if (item?.type === "track") {
        return (
          <div className={`bingo-grid grid-${i%4} swap`} key={i} index={i} onClick={() => processSwap(i)}
            style={{ backgroundImage: `url(${item.data.album.images[1].url})` }}>
            { i !== selectedIndex && 
            <div className="bingo-track-swap fr ac jc point">
              <img className="add-s" src={require(`../../../images/swap.png`)} alt="add player"></img>
              <p className="bold">swap</p>
            </div>
            }
            { i === selectedIndex && 
            <div className="bingo-track-swap fr ac jc point">
              <img className="add-s" src={require(`../../../images/warning.png`)} alt="add player"></img>
              <p className="bold">cancel</p>
            </div>
            }
          </div>
        )
      }

      if (item?.type === "artist") {
        return (
          <div className={`bingo-grid grid-${i%4} swap`} key={i} index={i} onClick={() => processSwap(i)}
            style={{ backgroundImage: `url(${item.data.images[1].url})` }}>          
            { i !== selectedIndex && 
            <div className="bingo-track-swap fr ac jc point">
              <img className="add-s" src={require(`../../../images/swap.png`)} alt="add player"></img>
              <p className="bold">swap</p>
            </div>
            }
            { i === selectedIndex && 
            <div className="bingo-track-swap fr ac jc point">
              <img className="add-s" src={require(`../../../images/warning.png`)} alt="add player"></img>
              <p className="bold">cancel</p>
            </div>
            }
          </div>
        )
      }
      
      return (
        <div className={`bingo-grid fr ac jc grid-${i%4}`} key={i} index={i} onClick={() => processSwap(i)}>
          <img className="add-s" src={require(`../../../images/swap.png`)} alt="add player"></img>
          <p className="bold">swap</p>
        </div>
      )

    });
  }

  const renderBoard = () => {
    if (isSwapping) return renderSwappingBoard();

    return board.tracks.map((item, i) => {

      if (item?.type === "track") {
        return (
          <div className={`bingo-grid grid-${i%4} bingo-track fr ac jc point`} key={i} index={i} onClick={() => toggleOptions(i)}
              style={{ backgroundImage: `url(${item.imageSrc})` }}>
            <div className="bingo-pick-bg fc ac">
              <p className="default">{item.trackName}</p>
              <p className="bold">{item.artistName}</p>
            </div>
          </div>
        )
      }

      if (item?.type === "artist") {
        return (
          <div className={`bingo-grid grid-${i%4} bingo-artist fr ac jc point`} key={i} index={i} onClick={() => toggleOptions(i)}
              style={{ backgroundImage: `url(${item.imageSrc})` }}>
            <div className="bingo-pick-bg fc ac">
              <p className="bold">{item.artistName}</p>
            </div>
          </div>
        )
      }
      
      return (
        <div className={`bingo-grid fr ac jc grid-${i%4}`} key={i} index={i} onClick={() => addTrack(i)}>
          <img className="add-s" src={require(`../../../images/add-button-orang.png`)} alt="add player"></img>
          <p className="bold">add</p>
        </div>
      )

    });
  }

  return (
    <div id="bingo-board" className="fr">
      {renderBoard()}
    </div>
  )

}

export default BingoBoard;