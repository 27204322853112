import React, { useState } from "react";
import { PICKS } from "../../../services/GameHelpers";



const PlayHighlight = ({ updates }) => {

  const [ updateIndex, setUpdateIndex ] = useState(0);

  // useEffect(() => {
  //   setTimeout(() => setUpdateIndex(updateIndex + 1 >= updates.length ? 0 : updateIndex + 1), 10000);
  // }, [updateIndex, updates.length])

  
  if (!updates || updates.length === 0) return (<></>)
  if (updateIndex >= updates.length) setUpdateIndex(0);
  const player = updates[updateIndex].player;
  const track = player.tracks[updates[updateIndex].i];
  const hasBusted = track.placing <= PICKS;

  const renderUpdateMessage = () => {
    return (
      <div id="player-update-p" className="fr ac jc">
        <img className="thumb-mini" src={require("../../../images/tick.png")}alt="player" ></img>
        {
          hasBusted 
            ? <div><span>{player.name} has </span><span className="error bold">busted!</span></div>
            : <p>one of {player.name}'s picks is playing!</p> 
        }
      </div>
    )
  };

  const renderTrack = () => {
    return (
      <div id="player-update-track" className="fr ac jc">
        <p className="ranking update-rank">#{track.placing}.</p>
        <img className="thumb-med" src={track.imageSrc} alt="track"></img>
        <div className="player-update-track-info">
          <p className="default">{track.trackName}</p>
          <p className="bold">{track.artistName}</p>
        </div>
      </div>
    )
  };

  const renderTrackResult = () => {
    return (
      <div id="player-update-result">
        <span>{track.trackName} by {track.artistName} has come in at </span>
        <span className="update-ranking">#{track.placing}</span>
        {
          hasBusted
            ? <><span>, causing them to </span><span className="space">bust</span></>
            : <><span>, earning them </span><span className="valid bold space">{track.score}</span><span> points</span></>
        }
      </div>
    )
  };

  const renderScore = () => {
    return (
      <div id="update-score">
        <span>score: </span>
        <div id="update-score-formula">
        {
          hasBusted
            ? <span className="error bold">bust!</span>
            : <><span>{player.score - track.score} </span><span className="valid bold">+{track.score}</span><span> = {player.score}</span></>
        }
        </div>
      </div>
    )
  };

  return (
    <div id="player-update">
      <div id="player-update-content">
        <div className="fr ac jc">        
          <h1 className="no-margin">player update</h1>
          {renderUpdateMessage()}
        </div>
        {renderTrack()}
        {renderTrackResult()}
        {renderScore()}
      </div>
    </div>
  );

}

export default PlayHighlight;