import React, { useState } from 'react';

import { Header } from '../Common';
import { topTracks } from '../Content/TopTracks';
import { topArtists } from '../Content/TopArtists';
import PopularTrack from './PopularTrack';
import PopularArtist from './PopularArtist';


const MostPlayedArchive = () => {

  
  const [ selectedTime, setSelectedTime ] = useState("2022");
  const [ selectedType, setSelectedType] = useState("tracks");

  const processTimeChange = (e) => setSelectedTime(e.target.value) 
  const processTypeChange = (e) => setSelectedType(e.target.value) 

  const renderPopularTracks = () => {

    const tracks = topTracks[selectedTime];

    return tracks.map((item, i) => {
      return (
        <PopularTrack key={i} index={i+1} title={item.trackName} artist={item.artistName} 
          imageSource={item.imageSource} played={item.score} eligibility={null} addable={false} />
      )
    });

  }

  const renderPopularArtists = () => {

    const artists = topArtists[selectedTime];

    return artists.map((item, i) => {
      return (
        <PopularArtist key={i} index={i+1} artist={item.artistName} played={item.score} 
          imageSource={item.imageSource} songsPlayed={item.uniqueSongs.length} />
      )
    });

  }

  const processPopularData = () => {
    if (selectedType === "tracks") return renderPopularTracks();
    if (selectedType === "artists") return renderPopularArtists();
  }

  return (
    <div>
      <Header 
        header="most played on triple j"
        subHeader="see how the most played tracks on triple j have changed over the years"
      />

      <div id="time-switcher">
        <label>top </label>
        <select data-test="top-switcher" onChange={processTypeChange} value={selectedType} className="select-label">
          <option value="tracks">tracks</option>
          <option value="artists">artists</option>
        </select>
        <label>of</label>
        <select data-test="time-switcher" onChange={processTimeChange} value={selectedTime} className="select-label">
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
          <option value="2017">2017</option>
          <option value="2016">2016</option>
          <option value="2015">2015</option>
        </select>
      </div>

      <div id="top-tracks" className="fc">
        {processPopularData()}
      </div>

    </div>

  )
};

export default MostPlayedArchive;