import React from 'react';

import '../../style/popularTrack.css';

const PopularArtist = ({imageSource, index, artist, played, songsPlayed}) => {
  
  return (
    <div className="top-track fr ac">
      <img className="thumb-med" src={imageSource} alt="track"></img>
      <div className="popular-track-info fc">
        <div className="ranking-title-popular fr">
          <p className="ranking">{`#${index}.`}</p>
          <p className="popular-artist rank-title overflow-ellipsis">{artist}</p>
        </div>
        <div className="played-info">
          <p className="light-italic">played <strong>{played}</strong> times</p>
          <p className="light-italic"><strong>{songsPlayed}</strong> unique songs played</p>
        </div>
      </div>
    </div>

  )
};


export default PopularArtist;