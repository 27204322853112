import React, { useEffect, useContext } from 'react';

import { RegisterPageHit } from '../services/googleAnalyticsHelper';
import { useCookies } from 'react-cookie';
import { TokenContext } from './Providers/TokenProvider';
import { useParams, useNavigate, Link } from 'react-router-dom';


function Logout() {

  RegisterPageHit("/logout", "logout");

  //Disabling these two for now because don't need to use their values but it's throwing warnings
  // eslint-disable-next-line
  const [ token, setToken ] = useContext(TokenContext);
  // eslint-disable-next-line
  const [ cookies, setCookie, removeCookie ] = useCookies();


  let nav = useNavigate();
  let { type } = useParams();

  useEffect(() => {

    if (type === "clear-token") {
      removeCookie('token', { path: '/' });
      setToken();
      nav("/logout");
    }


  });

  return (      
    <>
      <div data-test='log-out-message' className='message-box b-info margin-top'>
        <h1 className='margin-top'>logged out</h1>
        <p className='margin-top'>thanks for visiting, you've been successfully logged out. we hope to see you again soon</p>
        <Link className="fr ac" to="/hottest-100/shortlist">
          <img className="thumb-mini pad-right" src={require("../images/menu-items/house.png")} alt="companions logo"></img>
          <p className="bold">view homepage</p>
        </Link>  
      </div>
    </>
  );
}

export default Logout;
