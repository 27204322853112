import React, { useState } from 'react';

import '../../style/imageUploader.css';

import { uploadImage } from '../../services/helpers';
import { imagesContainer } from '../../services/credentials';
import Loader from './Loader';

const MultipleImageUploader = ({ images, addImage, removeImage }) => {

  const MAX_IMAGE_SIZE = 500000;
  const ACCEPTED_FORMATS = ["png", "jpg", "jpeg"];
  const [ uploadSuccessful, setUploadSuccessful ] = useState(false);
  const [ isUploading, setIsUploading ] = useState(false);
  const [ error, setError ] = useState(false);

  const handleImageChange = async (e) => {
    setError(false);
    setUploadSuccessful(false);

    const file = e.target.files[0];

    if (file.size > MAX_IMAGE_SIZE) {
      setError("the filesize you selected was too large. please choose a file under 500kb.");
      return;
    }

    if (!ACCEPTED_FORMATS.find((a) => file.type.includes(a))) {
      setError(`the file format ${file.type} is not supported. please upload one of: ${ACCEPTED_FORMATS.join(", ")}`);
      return;
    }

    //If we get to here then image is good to upload
    
    setIsUploading(true);
    const response = await uploadImage(file);
    if (!response) {
      setError("we were unable to upload your file at this time. please try again later");
      return;
    }
    setIsUploading(false);

    const imageURL = `${imagesContainer}${response}`;
    await addImage(imageURL)
    setUploadSuccessful(imageURL);

  }

  const renderImages = () => {
    return images.map((item, i) => {
      return (
        <div key={i} className="feedback-img-holder">
          <img className="feedback-img" src={item} alt="feedback"></img>
          <img className="rmv-feedback-img" src={require("../../images/close.png")} alt="close"
              onClick={() => removeImage(i)}></img>
        </div>
      );
    })
  }

  return (
    <div id="multi-img">  
      <p className="no-margin">attach images (png, jpeg or jpg)</p>
      <div id="fbk-img-holder">{renderImages()}</div>
      <div className="fr ac m-top">
        <label className="custom-file-upload-feedback fr ac">          
          <img className="thumb-mini pad-right" src={require('../../images/photo.png')} alt="upload"></img>
          choose image
          <input className="custom-file-input"  type="file" name="myImage" onChange={(e) => {handleImageChange(e);}}/>
        </label>

      </div>
      { isUploading && <Loader content="uploading image..." />}
      {uploadSuccessful && 
          <div className="upload-success fr ac">
            <img className="thumb-mini" alt="upload-success" src={require("../../images/tick.png")}></img>
            <p>upload successful</p>
          </div>
        }
      {error && <p className="error feedback-error">{error}</p>}
    </div>
  )
};

export default MultipleImageUploader;