import React from 'react';


import { AudioPreview, SpotifyLink, Eligibility } from '../../Common';
import { checkTrackEligibility } from '../../../services/helpers';



const BingoTileOptions = ({ board, selectedIndex, setOptionsToggle, removeTrack,
                            startSwap }) => {

  const getTileData = () => {
    return board.tracks[selectedIndex];
  }

  const deleteTrack = () => {
    setOptionsToggle(false);
    removeTrack();
  }

  const initiateSwap = () => {
    setOptionsToggle(false);
    startSwap();
  }



  const renderPick = () => {
    if (getTileData().type === "track") {
      return (
        <div className="player-pick fr ac">
          <img className="thumb-med" src={getTileData().imageSrc} alt="pick-thumb"></img>
          <div className="player-pick-info">
            <div className="fr ac">
              <p  className="default rank-title overflow-ellipsis">{getTileData().trackName}</p>
              <Eligibility eligibility={checkTrackEligibility(getTileData().releaseDate)} />
            </div>
            <p className="artist overflow-ellipsis bold">{getTileData().artistName}</p>
            <div className="player-pick-actions fr">
              <div className="fr">
                <AudioPreview audioSource={getTileData().audioSrc} />
                <SpotifyLink url={getTileData().spotifyUrl} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    //TODO add artist preview here
    return (
      <div></div>
    )
  }


  return (
    <div className="bingo-board-add-bg">
      <div className="bingo-board-add">
        <div className="close-edit-bingo-pick fr ac">
          <h3>edit pick</h3>          
          <img className="cross remove-player" src={require("../../../images/close.png")} alt="cross" 
                  onClick={() => setOptionsToggle(false)}></img>
        </div>
        <div>{renderPick()}</div>
        <div className="fr">
          <div className="edit-board-action fr ac point" onClick={initiateSwap} data-test='swap-pick'>
            <img src={require("../../../images/swap.png")} alt="swap"></img>
            <p>swap position</p>
          </div>
          <div className="edit-board-action fr ac point" onClick={deleteTrack} data-test='remove-pick'>
            <img src={require("../../../images/close.png")} alt="close"></img>
            <p>remove pick</p>
          </div>
        </div>
      </div>
    </div>
  );

}

export default BingoTileOptions;