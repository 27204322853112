
export const PICKS = 5;

export function calculatePlayerScores(tracks, players) {

  let clone = players;
  
  for (let player of clone) {
    for (let pick of player.tracks) {
      pick.score = 0;
      pick.placing = "yet to play";
      for (let track of tracks) {
        if (checkForMatch(pick, track)) {
          if (track.placing <= PICKS) {
            pick.score = -1;
            pick.placing = track.placing;
            break;
          }
          pick.score = 100 - track.placing + 1;
          pick.placing = track.placing;
        }
      }
    }
  }


  //If the #1 song has been played and the song doesn't have a score yet then it hasn't been played
  if (!(tracks.length === 0) && tracks[0].placing === 1) {
    for (let player of clone) {
      for (let pick of player.tracks) {
        if (pick.score === 0) pick.placing = "didn't place";
      }
    }
  }

  //Calculate a player's potential score
  if (tracks.length > 0) {
    for (let player of clone) {
      player.potentialScore = calculatePotentialScore(player, tracks);
    }
  }

  for (let player of clone) player.score = updatePlayerScore(player.tracks);
  return clone;

}

function calculatePotentialScore(player, tracks) {
  let potentialScore = 0;
  let songsScored = 0;
  for (let pick of player.tracks) {
    if (pick.score === -1) {
      potentialScore = -1;
      break;
    }
    if (pick.score > 0) {
      songsScored++;
      potentialScore += pick.score;
    }
  }

  if (potentialScore !== -1) {

    for (let i = 1; i <= PICKS; i++) {
      if (songsScored < i && tracks[0].placing > ((PICKS * 2) + 1 - i)) {
        potentialScore += 100 - (2 * PICKS) + i;
      }
    }


    // if (songsScored < 3 && tracks[0].placing > 4) {
    //   potentialScore += 97;
    // }
    // if (songsScored < 2 && tracks[0].placing > 5) {
    //   potentialScore += 96;
    // }
    // if (songsScored < 1 && tracks[0].placing > 6) {
    //   potentialScore += 95
    // }
  }
  return potentialScore;
}

function updatePlayerScore(picks) {
  let score = 0;
  for (let pick of picks) {
    if (pick.score === -1) {
      score = -1;
      break;
    }
    score += pick.score;
  }
  return score;
}


function checkForMatch(pick, track) {

  const jTitle = track.recording.title.toLowerCase();
  const jArtist = track.recording.artists[0].name.toLowerCase()
  const fTitle = pick.trackName.toLowerCase();
  const fArtist = pick.artistName.toLowerCase();

  const titleMatches = jTitle === fTitle || jTitle.includes(fTitle) || fTitle.includes(jTitle);
  const artistMatches = jArtist === fArtist || jArtist.includes(fArtist) || fArtist.includes(jArtist);
  return titleMatches && artistMatches;

}

function checkForArtistMatch(pick, track) {
  const jArtist = track.recording.artists[0].name.toLowerCase()
  const fArtist = pick.artistName.toLowerCase();

  return (fArtist === jArtist)
      || (jArtist.includes(fArtist))
      || (fArtist.includes(jArtist));
}

export function checkForBingoMatch(pick, track) {
  if (pick.type === "artist") return checkForArtistMatch(pick, track);
  return checkForMatch(pick, track);
}

function getTrackIndex(i, tracks) {
  return 100 - tracks.length + 1 + i;
}

function getBingoMatches(tracks, boards) {

  for (let i = 0; i < boards.length; i++) {
    for (let j = 0; j < boards[i].tracks.length; j++) {
      for (let z = 0; z < tracks.length; z++) {
        if (boards[i].tracks[j] === null) break;
        if (checkForBingoMatch(boards[i].tracks[j], tracks[z])) boards[i].tracks[j].match = getTrackIndex(z, tracks);
      }
    }
  }
  return boards;

}


export function calculatePlayerBingoScores(boards, tracks) {

  boards = getBingoMatches(tracks, boards);

  //Calculate how many squares they've had hit
  for (let i = 0; i < boards.length; i++) {
    boards[i].hits = boards[i].tracks.filter((a) => a !== null && a.match).length;
    const ratings = getHighestRow(boards[i]);
    boards[i].highest = ratings.highest;
    boards[i].bingo = ratings.bingo;
  }

  return boards;

}

function getHighestRow(board) {
  let highest = 0;
  let placing = -1;

  //Assume square
  const dim = Math.round(Math.sqrt(board.tracks.length));

  //Check rows
  for (let i = 0; i < dim; i++) {
    let rowCount = 0;
    let cPlacing = 101;
    for (let j = 0; j < dim; j++) {
      if (board.tracks[i*dim+j] == null) continue;
      if (board.tracks[i*dim+j].match) {
        rowCount++;
        if (board.tracks[i*dim+j].match < cPlacing) cPlacing = board.tracks[i*dim+j].match
      }
    }
    if (rowCount > highest) highest = rowCount;
    //Bingo
    if (rowCount === dim && cPlacing > placing && cPlacing !== 101) placing = cPlacing;
  }

  //Check columns
  for (let i = 0; i < dim; i++) {
    let colCount = 0;
    let cPlacing = 101;
    for (let j = 0; j < dim; j++) {
      if (board.tracks[j*dim+i] == null) continue;
      if (board.tracks[j*dim+i].match) {
        colCount++;
        if (board.tracks[j*dim+i].match < cPlacing) cPlacing = board.tracks[j*dim+i].match
      }
      
    }
    if (colCount > highest) highest = colCount;
    //Bingo
    if (colCount === dim && cPlacing > placing && cPlacing !== 101) placing = cPlacing;
  }

  //Check diagonals
  let diagCount1 = 0;
  let diagCount2 = 0;
  let cPlacing1 = 101;
  let cPlacing2 = 101;
  for (let i = 0; i < dim; i++) {
    if (board.tracks[i*dim+i] !== null && board.tracks[i*dim+i].match) {
      diagCount1++;
      if (board.tracks[i*dim+i].match < cPlacing1) cPlacing1 = board.tracks[i*dim+i].match
    }
    if (board.tracks[i*dim+dim-i-1] !== null && board.tracks[i*dim+dim-i-1].match) {
      diagCount2++;
      if ( board.tracks[i*dim+dim-i-1].match < cPlacing2) cPlacing2 = board.tracks[i*dim+dim-i-1].match
    }
  }
  if (diagCount1 > highest) highest = diagCount1;
  if (diagCount2 > highest) highest = diagCount2;
  //Bingo
  if (diagCount1 === dim && cPlacing1 > placing && cPlacing1 !== 101) placing = cPlacing1;
  if (diagCount2 === dim && cPlacing2 > placing && cPlacing2 !== 101) placing = cPlacing2;

  return { highest, bingo: placing === -1 ? false : placing };

}