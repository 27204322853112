import React, { useState, useEffect } from 'react';

import ActiveMenuItem from './ActiveMenuItem';
import Logo from './Logo';
import Hamburger from 'hamburger-react';
import SlidingMenu from './SlidingMenu';
import { useLocation, useNavigate } from 'react-router-dom';

import { webIcon, sideMenuItems as menuItems } from '../Content/Content';

import '../../style/menu.css';


const Menu = () => {

  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    const index = menuItems.findIndex(e => {
      const route = location.pathname.split("/")[1];
      return e.link.includes(route)
    });
    if (index === selectedIndex) return;
    setMenuOpen(false);
    
    setSelectedIndex(index);
  }, [location, selectedIndex]);
  
  const getSelectedMenuItem = () => {
    if (selectedIndex === -1) return { name: "404 error", imageSource: "warning.png"};
    return menuItems[selectedIndex]
  }


  const goHome = () => nav("/");

  return (
    <div id="menubar" className="fr ac">
      <div onClick={goHome}>
        <Logo item={webIcon} selected={false} />
      </div>
      <div className="fr">
        <ActiveMenuItem item={getSelectedMenuItem()} selected={true} />
        <Hamburger size={24} direction="right" rounded toggled={menuOpen} toggle={() => setMenuOpen(!menuOpen)} />
      </div>
      <SlidingMenu menuOpen={menuOpen} menuItems={menuItems} setMenuOpen={setMenuOpen} selectedIndex={selectedIndex} />
    </div>
  )
};


export default Menu;