import React from "react";
import { calculatePlayerBingoScores } from "../../../services/GameHelpers";

const BingoLeaderboard = ({boards, tracks, selected=false}) => {

  const sortPlayerScores = (scores) => {
    return scores.sort((a, b) => {
      if (a.highest === b.highest) return b.hits - a.hits;
      return b.highest - a.highest;
    });
  }

  const renderLeaderboard = () => {
    const scores = calculatePlayerBingoScores(boards, tracks);

    const sortedScores = sortPlayerScores(scores);
    return sortedScores.map((item, i) => {
      return (
        <div className="bingo-score player" id={`bingo-player-${item.name.split(' ').join('-').toLowerCase()}`} key={i}>
          <p className="ranking top-rank board-rank">#{i+1}</p>
          <img className="thumb-med player-image" src={item.imageURL ? item.imageURL : require(`../../../images/user.png`)} alt={"player"}></img>
          <div className="playerInfo fc jc">
            <p className="light-text rank-title overflow-ellipsis player-name">{item.name}</p>
            <span className="row-centre leaderboad-stat">
              <p className="fit">in a row: </p>
              <p data-test='in-a-row' className={`artist overflow-ellipsis bold player-score fit ${item.highest === 4 ? "valid" : ""}`}>{item.bingo ? `BINGO #${item.bingo}`: item.highest}</p>
            </span>
            <span className="row-centre leaderboad-stat">
              <p className="fit">hits: </p>
              <p data-test='hits' className={`artist overflow-ellipsis bold player-score fit ${item.hits === 16 ? "valid" : ""}`}>{item.hits === 16 ? "DOUBLE BINGO": item.hits}</p>
            </span>

          </div>

        </div>
      );
    });


  }

  return (
    <div id="bingo-leaderboard" className={selected ? "desktop-section mobile-selected" : "desktop-section"}>
      <h1>leaderboard</h1>
      {renderLeaderboard()}
    </div>
  );

}

export default BingoLeaderboard;