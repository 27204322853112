import topArtists2015 from './topArtists2015.json';
import topArtists2016 from './topArtists2016.json';
import topArtists2017 from './topArtists2017.json';
import topArtists2018 from './topArtists2018.json';
import topArtists2019 from './topArtists2019.json';
import topArtists2020 from './topArtists2020.json';
import topArtists2021 from './topArtists2021.json';
import topArtists2022 from './topArtists2022.json';

export const topArtists = {
  2015: topArtists2015,
  2016: topArtists2016,
  2017: topArtists2017,
  2018: topArtists2018,
  2019: topArtists2019,
  2020: topArtists2020,
  2021: topArtists2021,
  2022: topArtists2022
};


