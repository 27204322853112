import React from 'react';

import '../../style/warningMessage.css';

const WarningMessage = ({ title, text }) => {

  return (
    <div className="warning-div">
      <div className="fr ac warning-title">
        <h1>{title}</h1>
        <img className="close-auth-warning" src={require("../../images/warning.png")} alt="exit modal"></img>
      </div>
      <p className="warning-message">{text}</p>
    </div>
  )
};

export default WarningMessage;