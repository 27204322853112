import React from 'react';

import { Header } from './Common';

import { useNavigate } from 'react-router-dom';


const Page404 = () => {

  const nav = useNavigate();
  const goHome = () => nav("/");

  return (
    <div>
      <Header 
        header="404 - page not found"
        subHeader="sorry, we're not quite sure what you're looking for."
      />

      <div id="section-breaker">
        <img src={require(`../images/jjjflowers.png`)} alt="triple-j flowers"></img>
      </div>
      
      <p className="light-text">let's head back to the start and try again</p>

      <button style={{padding: "0.2em 0.5em", marginBottom: "1em"}} 
          className="btn" onClick={goHome}>take me home</button>


    </div>

  )
};

export default Page404;