import React from 'react';

import { Header } from '../Common';
import ArchiveSwitcher from './ArchiveSwitcher';

import { Routes, Route, Navigate } from 'react-router-dom';

const Archives = () => {

  return (
    <div>    
      <Header 
        header="into the archives"
        subHeader="dive into the hottest tracks of previous years on triple j and around australia."
      />

      <Routes>
        <Route exact path='/' element={ <Navigate replace to="./most-played" /> } />
        <Route path='/:type/*' element={<ArchiveSwitcher />}> </Route>
        <Route path='/*' element={<Navigate replace to="/404" />} />
      </Routes>

    </div>

  )
};

export default Archives;