import React, { useContext } from 'react';

import SlidingMenuItem from './SlidingMenuItem';

import { TokenContext } from '../Providers/TokenProvider';
import { featureToggles } from '../../services/credentials';
import '../../style/slidingMenu.css';


const SlidingMenu = ({ menuOpen, menuItems, setMenuOpen, selectedIndex }) => {

  const [ token ] = useContext(TokenContext);

  //Need to filter the menu items, some will not be seen unless logged in
  const filterMenuItems = () => {
    return menuItems.filter(
      a => {
        if (a.hasOwnProperty("featureToggle")) console.log(featureToggles[a.featureToggle]);
        return (
          //Return all elements that aren't conditional or feature toggled
          (!a.conditionalRendering && !a.featureToggle)

          //Check they are logged in for conditional menus
          || (a.conditionalRendering === "logged in" && token && token !== null) 

          //If the menu item is based on a feature toggle, make sure it's set to true
          || (a.hasOwnProperty("featureToggle") && featureToggles[a.featureToggle]) 
        );
      }
    ) 
  }

  return (
    <div id="slidingMenu" className={menuOpen ? "slide-visible": "slide-hidden"}>
      <div id="slidingMenuItems" className="fc">
      { 
        filterMenuItems().map((item, i) => 
          <SlidingMenuItem key={i} item={item} setMenuOpen={setMenuOpen} selected={selectedIndex === i} />
        )
      }
      </div>
    </div>
  )
};


export default SlidingMenu;