import React from 'react';

import '../../style/addToShortlist.css';

const AddToShortlist = ({ added, addToShortlist, index, buttonContent="shortlist" }) => {

  const handleAddToShortlist = (index) => {
    if (!added) addToShortlist(index);
  }

  return (
    <div className="add-to-shortlist fr point" onClick={() => handleAddToShortlist(index)}>
      <img className="add-s" src={require(`../../images/add-button-${added ? "grey": "orang"}.png`)} alt="add track"></img>
      <p>{added ? "added" : buttonContent}</p>
    </div>
  )
};

export default AddToShortlist;