import React, { useState, useEffect, useContext } from 'react';

import { Link, useParams } from 'react-router-dom';
import { AuthPrompt, Loader } from '../../Common';
import Countdown from '../Countdown';
import NowPlaying from '../NowPlaying';
import PlayerBoards from './PlayerBoards';
import BingoLeaderboard from './BingoLeaderboard';
import { top3Outside3MenuItems } from '../../Content/Content';
import NoLinkTopMenu from '../../Menu/NoLinkTopMenu';
import "../../../style/top3Outside3Live.css";
import CompanionHeader from '../CompanionHeader';
import { companionInfos } from '../../Content/Content';
import { CompanionContext } from '../../Providers/CompanionProvider';
import { TokenContext } from '../../Providers/TokenProvider';
import CountdownTimer from '../CountdownTimer';
import { startTime } from '../../../services/abc';
import { TracksContext } from '../../Providers/TracksProvider';
import { apiGetCompanions } from '../../../services/functionApp';
import BingoHighlight from './BingoHighlight';
import LiveRedirect from '../LiveRedirect';
import { checkForBingoMatch } from '../../../services/GameHelpers';

const Hottest100BingoLive = ({simulation=false}) => {
  
  const { liveId } = useParams();
  const [ companionData, setCompanionData ] = useContext(CompanionContext);
  const [ fetchingComp, setFetchingComp ] = useState(false);
  const [ token ] = useContext(TokenContext);
  const [ tracks ] = useContext(TracksContext);
  const [ menuName, setMenuName ] = useState(top3Outside3MenuItems[0].name);
  const [ bingoHightlights, setBingoHighlights ] = useState([]);
  const topMenuItems = top3Outside3MenuItems;

  useEffect(() => {

    const calculateBingoHighlights = () => {

      if (!companionData || !tracks || tracks.length === 0) return;
  
      const highlights = [];
      const latestTrack = tracks[0];
      const boards = companionData.hottest100Bingo.boards;
  
      for (let board of boards) {
        for (let i = 0; i < board.tracks.length; i++) {
          if (board.tracks[i] === null) continue;
          const result = checkForBingoMatch(board.tracks[i], latestTrack);
          if (result) highlights.push({board, i, latestTrack});
        }
      }
  
      return highlights;
  
    };

    if (companionData) setBingoHighlights(calculateBingoHighlights());


    async function getCompanionData() {
      const companionsResponse = await apiGetCompanions(liveId);
      setCompanionData(companionsResponse);
      setFetchingComp(false);
    }

    if (liveId && !simulation) { 
      getCompanionData(); 
      setFetchingComp(true);
    }
  }, [liveId, setCompanionData, simulation, companionData, tracks]);




  if (liveId && !simulation) {
    if (companionData === null || fetchingComp) {
      return <Loader content={"loading game..."} />
    }

    if (!companionData || companionData === "False") {
      return (
        <>          
          <AuthPrompt 
            header="sign up"
            subHeader="sign in through spotify to view your top 5 outside 5 companion game"
            redirectEndpoint="companions/hottest-100-bingo/live"/>
          <h1>we couldn't find your game!</h1>
          <p>it looks like the id you entered "{liveId}" doesn't correspond with a registered game. feel free to try again below</p>
          <LiveRedirect companion={companionInfos[1].link} />
        </>
      );
    }

  }

  if (companionData && companionData.hottest100Bingo.boards.length === 0) {
    return (
      <>
        <h1>no data found</h1>
        <p>it looks like you don't have any players signed up for your bingo game</p>
        <Link to={`/companions/${companionInfos[1].link}/sign-up`}>
          <button className="btn">sign up here</button>
        </Link>
        <LiveRedirect companion={companionInfos[1].link} />
      </>
    );
  }


  if (!token && !liveId) {
    return (
      <>
        <AuthPrompt 
          header="sign up"
          subHeader="sign in through spotify to view your top 5 outside 5 companion game"
          redirectEndpoint="companions/hottest-100-bingo/live"/>
        <LiveRedirect companion={companionInfos[1].link} />
      </>
    )
  }

  if (Date.now() < startTime || tracks.length === 0 || companionData === null) return <CountdownTimer companionIndex={1} />;

  return (
    <div id="playing-now">
      
      <div id="top-5-desktop-view">
        <CompanionHeader companion={companionInfos[1]} redirectLink={true} mobileOnly={true} />
        <NoLinkTopMenu topMenuItems={topMenuItems} menuName={menuName} setMenuName={setMenuName} mobileOnly={true} />

        <div className="fr">
          <Countdown tracks={tracks} selected={menuName === "playing"} />
          <PlayerBoards boards={companionData.hottest100Bingo.boards} tracks={tracks} selected={menuName === "players"} simulation={simulation} />
          <BingoLeaderboard boards={companionData.hottest100Bingo.boards} tracks={tracks} selected={menuName === "ranking"} />
          <BingoHighlight highlights={bingoHightlights} />
          { tracks.length > 0 && <NowPlaying tracks={tracks} />  }
        </div>


      </div>



    </div>
  )
}


export default Hottest100BingoLive;