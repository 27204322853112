import React, { useState } from 'react';

import SpotifyLink from '../Common/SpotifyLink';

import ReactTooltip from 'react-tooltip';
import { syncPlaylistHelper } from '../../services/spotify';
import { fetchShortlist, generateAndLinkPlaylist } from '../../services/helpers';

import '../../style/linkedPlaylist.css';

const content = {
    found: {
      title: "linked playlist up to date",
      text: `your linked playlist is synced and ready to go!`,
      image: "tick"
    },
    unsynced: {
      title: "linked playlist out of sync",
      text: "it looks like your linked playlist is out of sync",
      image: "warning"
    },
    notFound: {
      title: "no linked spotify playlist found",
      text: "create a linked spotify playlist to listen to your top tracks all year round",
      image: "warning"
    }
}


const LinkedPlaylist = ({ shortlist, setShortlist, token }) => {

  const [ show, setShow ] = useState(false);

  const generatePlaylist = async () => {
    const playlistData = await generateAndLinkPlaylist(token, shortlist.tracks);
    if (!playlistData || playlistData.error) {
      console.log("Playlist couldn't be generated at this time");
      return;
    }
    const clone = JSON.parse(JSON.stringify(shortlist));
    clone.playlistSynced = true;
    clone.playlistData = playlistData;
    clone.linkedPlaylist = true;
    clone.playlistId = playlistData.id;
    setShortlist(clone);
  }

  const syncPlaylist = async (push, pull) => {
    const response = await syncPlaylistHelper(token, shortlist.playlistId, shortlist.tracks, push, pull);
    if (!response || response.error) {
      console.log("Couldn't sync playlist, please try again later");
      return;
    }

    const updatedShortlist = await fetchShortlist(token);
    setShortlist(updatedShortlist.shortlistItems);
  }

  const getContent = () => {
    let key = "notFound";
    if (shortlist.linkedPlaylist) key = shortlist?.playlistSynced?.synced ? "found" : "unsynced";
    const data = content[key];
    return data;
  }

  const generateHeader = () => {
    const data = getContent();
    return (
      <div id="linkedPlaylist">
        <div id="linked-header" className="fr ac point" onClick={() => setShow(!show)} >
          <div className="fr ac">   
            <img src={require(`../../images/${data.image}.png`)} className="linked-icon" alt="eligibility"></img>
            <h2>{data.title}</h2>
          </div>
          <img className="linked-icon" src={require(`../../images/${show ? "minimize": "expand"}.png`)} alt="accordian"></img>
        </div>
      </div>
    )
  }

  const generateActions = () => {
    if (shortlist?.playlistSynced?.synced) return;

    if (!shortlist.linkedPlaylist) return (
      <div id="linked-actions">
        <div className="linked-action-generate linked-generate" onClick={generatePlaylist}>
          <p className="no-margin">generate playlist</p>
          <img className="icon-arrow" alt="playlist" src={require(`../../images/playlist.png`)}></img>
        </div>
      </div>
    );

    //If we get here then it's not synced
    return (
      <div>
        <div id="linked-actions">
          {shortlist.playlistSynced.pull && 
          <div className="linked-action-list">
            <div className="linked-action linked-pull" onClick={() => syncPlaylist(false, true)} data-tip="pull the extra tracks from spotify into urpix"
            data-background-color="#2c2828">
              <p>pull</p>
              <img className="icon-arrow" alt="pull" src={require(`../../images/pull.png`)}></img>
              <ReactTooltip place="top" effect="float" />
            </div> 
            <p>{shortlist.playlistSynced.toPull} extra {shortlist.playlistSynced.toPull === 1 ? "track" : "tracks"} in spotify</p>
          </div>}
          {shortlist.playlistSynced.push && 
          <div className="linked-action-list">
            <div className="linked-action linked-push" onClick={() => syncPlaylist(true, false)} data-tip="push the extra tracks from urpix into spotify"
                data-background-color="#2c2828">
              <p>push</p>
              <img className="icon-arrow" alt="push" src={require(`../../images/push.png`)}></img>
              <ReactTooltip place="top" effect="float" />
            </div> 
            <p>{shortlist.playlistSynced.toPush} extra {shortlist.playlistSynced.toPush === 1 ? "track" : "tracks"} in urpix </p>
          </div>}
          <div className="linked-action linked-sync" onClick={() => syncPlaylist(true, true)} data-tip="consolidate your urpix and spotify playlists"
              data-background-color="#2c2828">
            <p>full sync</p>
            <img className="icon-playlist" alt="playlist" src={require(`../../images/playlist.png`)}></img>
            <ReactTooltip place="top" effect="float" />
          </div>
        </div>
      </div>
    );

  }

  return (
    <div id="linkedPlaylist">
      { generateHeader() }
      { show && 
        <div>
          <p>{getContent.text}</p>
          { !(shortlist?.tracks?.length > 0) && <p>start adding tracks to your shortlist and then you can sync to a playlist in spotify</p> }

          { shortlist.linkedPlaylist && shortlist?.tracks?.length > 0 && 
            <div id="playlist-section" className="fr ac">
              <img id="playlist-thumb" src={shortlist.playlistData.images[0]?.url} alt="playlist-logo"></img>
              <div id="playlist-header" className="fc">
                <h2 className="overflow-ellipsis" id="playlist-title">{shortlist.playlistData.name}</h2>
                <div className="fr ac">
                  <p>{`${shortlist.playlistData.tracks.items.length} ${shortlist.playlistData.tracks.items.length === 1 ? "track" : "tracks"}`}</p>
                  <SpotifyLink url={shortlist.playlistData.external_urls.spotify} resize={false} />
                </div>
              </div>
            </div>
          }
          { shortlist?.tracks?.length > 0 && generateActions() }

          {/* { shortlist?.tracks?.length > 0 && data.buttonText &&
            <div id="linked-action" className="fr ac" onClick={() => performAction()}>
              <p>{data.buttonText}</p>
              <img alt="playlist" src={require(`../../images/playlist.png`)}></img>
            </div>
          } */}
        </div>
      }
    </div>
  )
};

export default LinkedPlaylist;