import React, { useState, useContext } from 'react';

import '../../style/imageUploader.css';

import { TokenContext } from '../Providers/TokenProvider';
import { uploadImageWithSpotifyID } from '../../services/helpers';
import { imagesContainer } from '../../services/credentials';

const ImageUploader = ({ addImage, profileImage }) => {

  const MAX_IMAGE_SIZE = 500000;
  const ACCEPTED_FORMATS = ["png", "jpg", "jpeg"];
  const [ uploadSuccessful, setUploadSuccessful ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ token ] = useContext(TokenContext);

  const handleImageChange = async (e) => {
    setError(false);
    setUploadSuccessful(false);

    const file = e.target.files[0];

    if (file.size > MAX_IMAGE_SIZE) {
      setError("the filesize you selected was too large. please choose a file under 500kb.");
      return;
    }

    if (!ACCEPTED_FORMATS.find((a) => file.type.includes(a))) {
      setError(`the file format ${file.type} is not supported. please upload one of: ${ACCEPTED_FORMATS.join(", ")}`);
      return;
    }

    //If we get to here then image is good to upload
    const response = await uploadImageWithSpotifyID(token, file);
    if (!response) {
      setError("we were unable to upload your file at this time. please try again later");
      return;
    }

    const imageURL = `${imagesContainer}${response}`;
    await addImage(imageURL)
    setUploadSuccessful(imageURL);

  }


  return (
    <div>  
      <div className="fr ac m-top">
        <img className="upload-prof-img thumb-med"src={profileImage ? profileImage : require(`../../images/user.png`)} alt="profile-img"></img>
        {uploadSuccessful && 
          <div className="upload-success fr ac">
            <img className="thumb-mini" alt="upload-success" src={require("../../images/tick.png")}></img>
            <p>upload successful</p>
          </div>
        }

        {!uploadSuccessful && 
          <label className="custom-file-upload">
            upload new image
            <input className="custom-file-input"  type="file" name="myImage" onChange={(e) => {handleImageChange(e);}}/>
          </label>
        }
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  )
};

export default ImageUploader;