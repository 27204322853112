import React from 'react';

import { Link } from 'react-router-dom';

import '../../style/toggleSelector.css';


const ToggleSelector = ({toggleItems, className, value}) => {

  return (
    <div id="toggle-selector">
      { toggleItems.map((option, i) => <ToggleSelectorItem 
        key={i} option={option} isSelected={option.replaceAll(" ", "-")===value} className={className} />) }
    </div>

  )
};

const ToggleSelectorItem = ({isSelected, className, option}) => {

  return (
    <Link to={`./../${option.replace(" ", "-")}`} className={`${className} ${isSelected ? 'toggle-selected' : ''}`}>{option}</Link>
  )

};


export default ToggleSelector;