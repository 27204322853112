import React, { useState, useEffect, useRef } from 'react';
import { getHottest100 } from '../../services/abc';
import { countdown } from '../../services/simulationData';
import { useLocation } from 'react-router-dom';

export const TracksContext = React.createContext();




const TracksProvider = ({children}) => {

  const [ tracks, setTracks ] = useState([]);
  const [ trackCutoff, setTrackCutoff ] = useState(1);
  const location = useLocation();
  
  const trackNum = useRef();

  useEffect(() => {

    async function getLatestHottest100() {

      //Get hottest 100 data
      let response = null;
      if (location.pathname.includes('simulation')) {
        response = countdown.slice(100 - trackCutoff);
        for (let i = 0; i < response.length; i++) {
          response[i].placing = 100 - response.length + i + 1;
        }
      } else {
        response = await getHottest100();
      } 

      setTracks(response);

    }
    const interval = setInterval(() => {
      getLatestHottest100();
    }, location.pathname.includes('simulation') ? 1000 : 10000);

    return () => clearInterval(interval);

  }, [trackCutoff, location.pathname]);

  return (
    <TracksContext.Provider value={[tracks, setTracks]}>
      { location.pathname.includes('simulation') && location.pathname.includes("live") && 
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", zIndex: "9999999", position: "absolute"}}>
          <button data-test='prev-sim-track' onClick={() => setTrackCutoff((a) => a-1)}>Prev Track</button>
          <button data-test='next-sim-track' onClick={() => setTrackCutoff((a) => a+1)}>Next Track</button>
          <input id="sim-track-cutoff" ref={trackNum} type='text'></input>
          <button id="confirm-track-cutoff" onClick={()=>{setTrackCutoff(101 - trackNum.current.value)}}>go</button>
        </div>
      }
      {children}
    </TracksContext.Provider>
  )

};

export default TracksProvider;