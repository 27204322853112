import React from 'react';

const ErrorMessage = ({ data }) => {

  return (
    <div className="error-header">
      <div className="error-title fr ac">
        <div className="fr ac">
          <img  className="error-icon" src={require(`../../images/${data.imageSource}`)} alt="icon"></img>
          <h1>{data.header}</h1>
        </div>
        {data.closable && <img className="close-error" src={require("../../images/close.png")} 
            alt="error icon" onClick={() => data.setShow(false)}></img>}
      </div>
      <p className="light-text">{data.subHeader}</p>
    </div>
  )
};

export default ErrorMessage;