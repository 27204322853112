import React from 'react';

import { companionInfos } from '../../Content/Content';

import CompanionHeader from '../CompanionHeader';

import { Link } from 'react-router-dom';
import '../../../style/companions.css';

const Top3Outside3Info = () => {

  const companion = companionInfos[0];

  const renderRules = () => {
    return companion.rules.map((item, i) => {
      return (
        <div className="companion-rule fr ac">
          <img className="thumb-mini" src={require("../../../images/record.png")} alt="live link"></img>
          <p key={i}>{item}</p>
        </div>
      )
    })
  }

  return (
    <div>
      <div className="companion-info" >
        <CompanionHeader companion={companion} redirectLink={true} />
        <div className='fr ac'>
          <Link className="fr ac" to={`/companions/${companion.link}/sign-up`}>
            <img className="thumb-mini pad-right" src={require(`../../../images/sign-up.png`)} alt="more-info"></img>
            <p className="bold">sign up</p>
          </Link>
          <Link className="fr ac" to={`/companions/${companion.link}/live`} alt="live link">
            <img className="thumb-mini pad-right" src={require(`../../../images/gaming.png`)} alt="more-info"></img>
            <p className="bold">play</p>
          </Link>
        </div>
      </div>
      <div>
        <h1>rules</h1>
        {renderRules()}
      </div>
    </div>
  )
};

export default Top3Outside3Info;