
export const countdown = [
  {
   "recording": {
    "title": "Paint The Town Red",
    "artists": [
     {
      "name": "Doja Cat"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/VlRS1T9dTJHqbfr4VZGP7mXbyHk=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fdojacat-paintthetownsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T08:24:49+00:00",
   "placing": 1
  },
  {
   "recording": {
    "title": "The Worst Person Alive",
    "artists": [
     {
      "name": "G Flip"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/uD-meteehnuBr8MSGu4ajx3K7o0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fgflip-theworstpersonalivesingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T08:15:30+00:00",
   "placing": 2
  },
  {
   "recording": {
    "title": "Saving Up",
    "artists": [
     {
      "name": "Dom Dolla"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/TW9KkMSY2yxgvJXL_LGTZxTw_0I=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fdomdolla-savingupsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T08:09:26+00:00",
   "placing": 3
  },
  {
   "recording": {
    "title": "Rhyme Dust",
    "artists": [
     {
      "name": "MK & Dom Dolla"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/XoXzTcNEKSQ8vHt_QrrZ8ME1HBo=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmkdomdolla-rhymedustsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T08:04:16+00:00",
   "placing": 4
  },
  {
   "recording": {
    "title": "Prada",
    "artists": [
     {
      "name": "cassö x RAYE x D-Block Europe"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/bnEioszU_y4BsUWopUEPM-NTkMs=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fcassxrayexd-blockeurope-pradasingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T08:01:18+00:00",
   "placing": 5
  },
  {
   "recording": {
    "title": "adore u",
    "artists": [
     {
      "name": "Fred again.. & Obongjayar"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/t_PgzY00mrorpTozaGMKFXP2Gm4=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2FmiW98OYppg%2F32633c197bf1b0dada4b3fc06af1e32a.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:55:27+00:00",
   "placing": 6
  },
  {
   "recording": {
    "title": "What Was I Made For?",
    "artists": [
     {
      "name": "Billie Eilish"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/EJ1T44UrigyLAp2YgeZAkvz8dL0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fbillieeilish-whatwasimadeforfromthemotionpicturebarbiesingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:51:45+00:00",
   "placing": 7
  },
  {
   "recording": {
    "title": "Rush",
    "artists": [
     {
      "name": "Troye Sivan"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/s7dYC9BpWHflpJeCn2iI4yG_4qw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftroyesivan-rushsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:47:03+00:00",
   "placing": 8
  },
  {
   "recording": {
    "title": "Lovin On Me",
    "artists": [
     {
      "name": "Jack Harlow"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/1vkCzRlgm9X8dtIKur3dLMCC2M0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fjackharlow-lovinonmesingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:43:44+00:00",
   "placing": 9
  },
  {
   "recording": {
    "title": "Chemical",
    "artists": [
     {
      "name": "Post Malone"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/362DfzJ4gZagwJHzUV3Qd5V4jfM=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpostmalone-austin.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:39:58+00:00",
   "placing": 10
  },
  {
   "recording": {
    "title": "vampire",
    "artists": [
     {
      "name": "Olivia Rodrigo"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/FK9yKrJKfO15-KZ95oyimVcGlY0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Foliviarodrigo-vampiresingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:34:47+00:00",
   "placing": 11
  },
  {
   "recording": {
    "title": "Rumble",
    "artists": [
     {
      "name": "Skrillex, Fred Again.. & Flowdan"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/s3M0IXiPS6Kh3iHcQg4kBgtzvkw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Fdig%2Fcovers%2Fskrillexfredagainflowdan-rumblesingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:31:22+00:00",
   "placing": 12
  },
  {
   "recording": {
    "title": "Kill Bill",
    "artists": [
     {
      "name": "SZA"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/HVGWCcxfxFBA5eWYhNHqYepP2Bg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsza-sos.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:26:49+00:00",
   "placing": 13
  },
  {
   "recording": {
    "title": "Atmosphere",
    "artists": [
     {
      "name": "FISHER x Kita Alexander"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/aTTsrxSGWvNqP55ygxDfwtxsIwk=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2Fmi1g5QJmQg%2Fa8cf0b6551fa05ffde9af84c2fa07947.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:23:35+00:00",
   "placing": 14
  },
  {
   "recording": {
    "title": "(It Goes Like) Nanana",
    "artists": [
     {
      "name": "Peggy Gou"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/L3_fXQvfrBUn8VSCU-SBwlomwGk=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpeggygou-itgoeslikenananasingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:18:16+00:00",
   "placing": 15
  },
  {
   "recording": {
    "title": "Sprinter",
    "artists": [
     {
      "name": "Dave & Central Cee"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/6ahU1nhW-eaEZlk_SRi3KsHQc6Y=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F1d429826a7d73b7f888c075b63ed68df5346.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:14:04+00:00",
   "placing": 16
  },
  {
   "recording": {
    "title": "Back On 74",
    "artists": [
     {
      "name": "Jungle"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/UZ1B3U0Vl5ZO9Hqvbel_OBE0lhA=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fjungle-volcano.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:09:18+00:00",
   "placing": 17
  },
  {
   "recording": {
    "title": "Eat Your Man",
    "artists": [
     {
      "name": "Dom Dolla & Nelly Furtado"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/85l8PA8MfZnMQeOCK4Kp_9Az6WU=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fdomdollanellyfurtadio-eatyourmansingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T07:05:35+00:00",
   "placing": 18
  },
  {
   "recording": {
    "title": "Therapy",
    "artists": [
     {
      "name": "Budjerah"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/EAdJvtb-Envbhnriy7EM6pVS9Ec=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F2c733272bc2f0649b3ab262d4b9ac7526077.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:59:06+00:00",
   "placing": 19
  },
  {
   "recording": {
    "title": "Sorry Instead",
    "artists": [
     {
      "name": "Spacey Jane"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/yI1rjyMUH7fCSLEAy0Hzcrzv1oo=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fspaceyjane-herecomeseverbody.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:55:27+00:00",
   "placing": 20
  },
  {
   "recording": {
    "title": "greedy",
    "artists": [
     {
      "name": "Tate McRae"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/Ij7WQSrPEDh1BsnuRKLawU-H_gI=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftatemcrae-greedysingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:52:48+00:00",
   "placing": 21
  },
  {
   "recording": {
    "title": "Be Your Man",
    "artists": [
     {
      "name": "G Flip"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/SvZn7QWvoJRqZqjvgFwlVBEveOA=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fgflip-beyourmansingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:47:16+00:00",
   "placing": 22
  },
  {
   "recording": {
    "title": "Take It Off",
    "artists": [
     {
      "name": "FISHER x Aatig"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/rvgXZsZq1W7A5-9iZYryCO0PVFQ=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ffisherxaatig-takeitoffsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:42:50+00:00",
   "placing": 23
  },
  {
   "recording": {
    "title": "Good Enough",
    "artists": [
     {
      "name": "G Flip"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/wwoUWhAhgu9CLxHCE3qU8VUO5Pw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fgflip-drummer.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:37:53+00:00",
   "placing": 24
  },
  {
   "recording": {
    "title": "Rich Baby Daddy",
    "artists": [
     {
      "name": "Drake"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/ABhf4nywBSriRgU1Lsaiw26O4YE=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fdrake-forallthedogs.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:32:03+00:00",
   "placing": 25
  },
  {
   "recording": {
    "title": "Rough",
    "artists": [
     {
      "name": "G Flip"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/wwoUWhAhgu9CLxHCE3qU8VUO5Pw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fgflip-drummer.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:25:24+00:00",
   "placing": 26
  },
  {
   "recording": {
    "title": "Imposter Syndrome",
    "artists": [
     {
      "name": "Lime Cordiale"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/5hLMAWPCv2Rw9-MZUTpB8tFcogw=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F7ee55e2749e84062cf4a0e8192a8481d1616.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:21:22+00:00",
   "placing": 27
  },
  {
   "recording": {
    "title": "Dance The Night",
    "artists": [
     {
      "name": "Dua Lipa"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/dVR6yfeogW8G-lX4bBJs90v75eg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fdualipa-barbiethealbum.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:17:43+00:00",
   "placing": 28
  },
  {
   "recording": {
    "title": "Say Yes To Heaven",
    "artists": [
     {
      "name": "Lana Del Rey"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/-ydzkw9L80f8uH1Sr-17n7tYVTg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Flanadelrey-sayyestoheavensingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:13:18+00:00",
   "placing": 29
  },
  {
   "recording": {
    "title": "Not Strong Enough",
    "artists": [
     {
      "name": "boygenius"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/WvDj3GwjjQ3r3wKUx2jzr4pNq6g=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fboygenius-therecord.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:08:56+00:00",
   "placing": 30
  },
  {
   "recording": {
    "title": "One Of Your Girls",
    "artists": [
     {
      "name": "Troye Sivan"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/dcMAWA8x0VjnX6eUMYDQpJofFSA=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftroyesivan-somethingtogiveeachother.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:05:51+00:00",
   "placing": 31
  },
  {
   "recording": {
    "title": "bad idea right?",
    "artists": [
     {
      "name": "Olivia Rodrigo"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/Zh-fHwhaM3n2AT1sKBlDFK-waMo=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Foliviarodrigo-badidearightsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T06:01:43+00:00",
   "placing": 32
  },
  {
   "recording": {
    "title": "Got Me Started",
    "artists": [
     {
      "name": "Troye Sivan"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/fvFCbTn3Apis8AGIjbSiewj2Gqw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftroyesivan-gotmestartedsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:57:32+00:00",
   "placing": 33
  },
  {
   "recording": {
    "title": "get him back!",
    "artists": [
     {
      "name": "Olivia Rodrigo"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/eOYzTNsIQ1NL_dGlcTmM8WPJoLg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Foliviarodrigo-guts.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:53:59+00:00",
   "placing": 34
  },
  {
   "recording": {
    "title": "Baby again..",
    "artists": [
     {
      "name": "Fred again.. x Skrillex x Four Tet"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/pwI9grf--JYae14WDiUUKyoXr2c=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ffredagainxskrillexxfourtet-babyagainsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:48:13+00:00",
   "placing": 35
  },
  {
   "recording": {
    "title": "Boy's a liar Pt.2",
    "artists": [
     {
      "name": "PinkPantheress & Ice Spice"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/9P2CPfe4ss5TgF7s-8ytRODWNEo=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2Fmi2ewKZ4ye%2F390ff632329b82150a401f15027abec4.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:45:22+00:00",
   "placing": 36
  },
  {
   "recording": {
    "title": "Laced Up",
    "artists": [
     {
      "name": "Hilltop Hoods"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/OOKYbq7o4azMpw2ZjlVMG0q2aXw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fhilltophoods-lacedupsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:39:51+00:00",
   "placing": 37
  },
  {
   "recording": {
    "title": "Minivan",
    "artists": [
     {
      "name": "The Rions"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/0XoMCT9OMFapPKqhw-Ys65jjjt4=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftherions-minivanep.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:35:11+00:00",
   "placing": 38
  },
  {
   "recording": {
    "title": "Green Honda",
    "artists": [
     {
      "name": "BENEE"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/d4TgRw8QaC8ZAn9kg-4qmWYkLyU=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F14fff5a42e66b88b0e82fe505bccea5a5439.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:31:32+00:00",
   "placing": 39
  },
  {
   "recording": {
    "title": "Scary Movies",
    "artists": [
     {
      "name": "The Rions"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/Gpsgn6yLnxdC_O3VujWozYCCSvQ=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftherions-scarymoviessingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:26:37+00:00",
   "placing": 40
  },
  {
   "recording": {
    "title": "Houdini",
    "artists": [
     {
      "name": "Dua Lipa"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/Hnp4rAtntPg4h1Gec63Yf-r1Inw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fdualipa-houdinisingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:22:53+00:00",
   "placing": 41
  },
  {
   "recording": {
    "title": "Pedestal",
    "artists": [
     {
      "name": "Lime Cordiale"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/ht0t8jjFJz0CmwIrXJ2MD-KBsGU=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F60c99bbeccf2c3e3b3df99cafa1347fe5623.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:16:41+00:00",
   "placing": 42
  },
  {
   "recording": {
    "title": "Popular",
    "artists": [
     {
      "name": "The Weeknd, Playboy Carti & Madonna"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/hxC0q3RMFd0Iy5pnjAb9_QcIYt4=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftheweekndplayboycartimadonna-popularfromtheidolvol1musicfromthehbooriginalseriessingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:13:01+00:00",
   "placing": 43
  },
  {
   "recording": {
    "title": "My Love Mine All Mine",
    "artists": [
     {
      "name": "Mitski"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/2CvmqnRjgTlyAgkca9P8YfKFD2s=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmitski-thelandisinhospitableandsoarewe.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:10:12+00:00",
   "placing": 44
  },
  {
   "recording": {
    "title": "Still Have Room",
    "artists": [
     {
      "name": "Hockey Dad"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/rJZuoWY8ut7sXEh_cPj3xR4kkLo=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F60864bd0fa1867b5dae555bb227a29846878.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:06:51+00:00",
   "placing": 45
  },
  {
   "recording": {
    "title": "Strangers",
    "artists": [
     {
      "name": "Kenya Grace"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/4u54M-bPI600c4m1jmrEJldBO7c=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2Fmi1P3431Ng%2F05e221a9da9750a049e282e861023091.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T05:03:34+00:00",
   "placing": 46
  },
  {
   "recording": {
    "title": "Sweetheart",
    "artists": [
     {
      "name": "Old Mervs"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/WS4ZCxLIChd5-_b6Bt9Ov4wXEu8=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Foldmervs-sweetheartsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:57:52+00:00",
   "placing": 47
  },
  {
   "recording": {
    "title": "Padam Padam",
    "artists": [
     {
      "name": "Kylie Minogue"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/mCFqWua2-9xl3Jf5ASFRmI0hJrY=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Fc2b26aef5fadebde3a08afac095878628263.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:55:05+00:00",
   "placing": 48
  },
  {
   "recording": {
    "title": "Agora Hills",
    "artists": [
     {
      "name": "Doja Cat"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/sdHOKgtxYdFpJtI4Mg28NCqLfi0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fdojacat-scarlet.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:49:55+00:00",
   "placing": 49
  },
  {
   "recording": {
    "title": "Australia",
    "artists": [
     {
      "name": "G Flip"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/wwoUWhAhgu9CLxHCE3qU8VUO5Pw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fgflip-drummer.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:46:03+00:00",
   "placing": 50
  },
  {
   "recording": {
    "title": "ten",
    "artists": [
     {
      "name": "Fred again.. & Jozzy"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/iRuUvqGHaXdgPVRRAvniu9MZ5VM=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2FmiBeryEnq9%2Fdf868e76bf1782bf257ba286b91df441.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:39:36+00:00",
   "placing": 51
  },
  {
   "recording": {
    "title": "I Used To Be Fun",
    "artists": [
     {
      "name": "Teen Jesus and the Jean Teasers"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/M1JUBTi51zRfNRHEMR_dwr2jTfo=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fteenjesusandthejeanteasers-iloveyou.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:32:46+00:00",
   "placing": 52
  },
  {
   "recording": {
    "title": "Lookin' Out",
    "artists": [
     {
      "name": "King Stingray"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/4I1MPKMVUtOdquSyqD7BV3InKPs=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Fdig%2Fcovers%2Fkingstingray-lookinoutsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:27:54+00:00",
   "placing": 53
  },
  {
   "recording": {
    "title": "MORE THAN YOU KNOW",
    "artists": [
     {
      "name": "blink-182"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/msF6h-ETHMup6Z_cJucUiwisRIw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fblink-182-onemoretime.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:22:38+00:00",
   "placing": 54
  },
  {
   "recording": {
    "title": "We Don't Talk About It",
    "artists": [
     {
      "name": "Thelma Plum"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/XL-l_bIg911vlMdl5QNa5OYQuqs=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fthelmaplum-wedonttalkaboutitsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:19:02+00:00",
   "placing": 55
  },
  {
   "recording": {
    "title": "all-american bitch",
    "artists": [
     {
      "name": "Olivia Rodrigo"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/eOYzTNsIQ1NL_dGlcTmM8WPJoLg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Foliviarodrigo-guts.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:15:57+00:00",
   "placing": 56
  },
  {
   "recording": {
    "title": "DArkSide",
    "artists": [
     {
      "name": "Bring Me The Horizon"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/bXD0oWW89C_C6PF1ijUUjMInuxE=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fbringmethehorizon-darksidesingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:10:33+00:00",
   "placing": 57
  },
  {
   "recording": {
    "title": "LosT",
    "artists": [
     {
      "name": "Bring Me The Horizon"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/C-3XU7I2AezShHrU1aeqWCGwxu8=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fbringmethehorizon-lostsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:07:04+00:00",
   "placing": 58
  },
  {
   "recording": {
    "title": "Spin Me Like Your Records",
    "artists": [
     {
      "name": "Pacific Avenue"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/kasT6HRQE1_wzkuy_gHivIul8Tg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpacificavenue-spinmelikeyourrecords.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T04:01:28+00:00",
   "placing": 59
  },
  {
   "recording": {
    "title": "A&W",
    "artists": [
     {
      "name": "Lana Del Rey"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/zlclnSFio8WfE9gl8ZSPaWXBJPY=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Flanadelrey-didyouknowthattheresatunnelunderoceanblvd.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:53:44+00:00",
   "placing": 60
  },
  {
   "recording": {
    "title": "LOVE AGAIN",
    "artists": [
     {
      "name": "The Kid LAROI"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/8a6w0P4En9d_JsAnN1woYY710fc=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2FmiLg4w1x2P%2F527d7cce7be44d0fee7cccba9215b35b.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:48:21+00:00",
   "placing": 61
  },
  {
   "recording": {
    "title": "DOGTOOTH",
    "artists": [
     {
      "name": "Tyler, The Creator"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/OSz7DNAGWuBZa885BsAk8Z6yjLA=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftylerthecreator-callmeifyougetlosttheestatesale.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:45:00+00:00",
   "placing": 62
  },
  {
   "recording": {
    "title": "Perfect For You",
    "artists": [
     {
      "name": "Peach PRC"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/JYHrQZOHizg9UUqjg9OFaRt0DvE=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F160666924e787723e1dece2af5d716b79624.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:41:53+00:00",
   "placing": 63
  },
  {
   "recording": {
    "title": "Fall At Your Feet",
    "artists": [
     {
      "name": "Peking Duk"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/Kj-BXcDjrDGX9f-GG3dXmz-lxUY=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2Fmi7gVawlmP%2Fc27a4e06dc1c39b3ab9ab04d0066648a.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:36:06+00:00",
   "placing": 64
  },
  {
   "recording": {
    "title": "Real Life",
    "artists": [
     {
      "name": "G Flip"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/wwoUWhAhgu9CLxHCE3qU8VUO5Pw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fgflip-drummer.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:32:41+00:00",
   "placing": 65
  },
  {
   "recording": {
    "title": "Barbie World (with Aqua)",
    "artists": [
     {
      "name": "Nicki Minaj & Ice Spice"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/IJzRVTz308J4bzh7uAL30XtHOWI=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fnickiminajicespice-barbieworldsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:29:39+00:00",
   "placing": 66
  },
  {
   "recording": {
    "title": "Letting Go",
    "artists": [
     {
      "name": "Angie McMahon"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/ZYs55XR8JJ0J98OBUEz5jjvqmkA=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fangiemcmahon-lettinggosingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:23:24+00:00",
   "placing": 67
  },
  {
   "recording": {
    "title": "F U Goodbye",
    "artists": [
     {
      "name": "Peach PRC"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/JYHrQZOHizg9UUqjg9OFaRt0DvE=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F160666924e787723e1dece2af5d716b79624.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:20:09+00:00",
   "placing": 68
  },
  {
   "recording": {
    "title": "Leaving The Light",
    "artists": [
     {
      "name": "Genesis Owusu"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/t9Sfo7GJmWPA0DIHtcG8ULlWtj8=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fgenesisowusu-leavingthelightsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:16:27+00:00",
   "placing": 69
  },
  {
   "recording": {
    "title": "Snooze",
    "artists": [
     {
      "name": "SZA"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/HVGWCcxfxFBA5eWYhNHqYepP2Bg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsza-sos.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:13:04+00:00",
   "placing": 70
  },
  {
   "recording": {
    "title": "Take What You Want",
    "artists": [
     {
      "name": "The Rions"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/0XoMCT9OMFapPKqhw-Ys65jjjt4=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftherions-minivanep.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:06:51+00:00",
   "placing": 71
  },
  {
   "recording": {
    "title": "Stockholm",
    "artists": [
     {
      "name": "DICE"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/S7PC_5JLAPUFn203_dIgPjLhp-Y=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Fcc8bd9ae40d341fb980b0abb1b72aff02106.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T03:02:46+00:00",
   "placing": 72
  },
  {
   "recording": {
    "title": "Water",
    "artists": [
     {
      "name": "Tyla"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/hkv994M3RqMNH2N4GfqsifbJ_qU=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2F98e292b5a8e39fcae0cbe2aa0bc019e97317.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:57:59+00:00",
   "placing": 73
  },
  {
   "recording": {
    "title": "Speedracer",
    "artists": [
     {
      "name": "Teenage Dads"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/oLO8EvK7ZFI5lT6BGIoBUDFtM0Y=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fteenagedads-speedracersingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:54:57+00:00",
   "placing": 74
  },
  {
   "recording": {
    "title": "Nobody Gets Me",
    "artists": [
     {
      "name": "SZA"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/HVGWCcxfxFBA5eWYhNHqYepP2Bg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsza-sos.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:51:32+00:00",
   "placing": 75
  },
  {
   "recording": {
    "title": "Sofa King",
    "artists": [
     {
      "name": "Royel Otis"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/mx46R7Ae9ZpD5GDw3BVXmO6Wg9Y=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Froyelotis-sofakingsep.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:45:15+00:00",
   "placing": 76
  },
  {
   "recording": {
    "title": "I DON'T WANNA BE LIKE YOU",
    "artists": [
     {
      "name": "Ruel"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/39GZB1k7yj6VcYfaGt26zZ5Vfbw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fruel-4thwall.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:40:58+00:00",
   "placing": 77
  },
  {
   "recording": {
    "title": "BLEED",
    "artists": [
     {
      "name": "The Kid LAROI"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/8a6w0P4En9d_JsAnN1woYY710fc=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2FmiLg4w1x2P%2F527d7cce7be44d0fee7cccba9215b35b.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:38:04+00:00",
   "placing": 78
  },
  {
   "recording": {
    "title": "Video Killed The Radio Star",
    "artists": [
     {
      "name": "Teenage Dads"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/2DQq3vlrrFj5qK_SJw5DQ31dg5o=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2Fmi5gGlNvp9%2Ff63c42c371d473a8bb5a5f1ac7e79d0e.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:34:05+00:00",
   "placing": 79
  },
  {
   "recording": {
    "title": "7 Days",
    "artists": [
     {
      "name": "G Flip"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/wwoUWhAhgu9CLxHCE3qU8VUO5Pw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fgflip-drummer.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:28:30+00:00",
   "placing": 80
  },
  {
   "recording": {
    "title": "Like A Girl Does",
    "artists": [
     {
      "name": "Peach PRC"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/YG2KZBqXHrEGvnejCk6r94Ue5B0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpeachprc-likeagirldoessingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:25:34+00:00",
   "placing": 81
  },
  {
   "recording": {
    "title": "exes",
    "artists": [
     {
      "name": "Tate McRae"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/7p41qiATRMJG1r3wjQDHzKDViVc=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftatemcrae-thinklater.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:21:59+00:00",
   "placing": 82
  },
  {
   "recording": {
    "title": "The Summoning",
    "artists": [
     {
      "name": "Sleep Token"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/Tb5xr2Dtr7z8_sj47rZ7uwOGLYI=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsleeptoken-takemebacktoeden.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:12:53+00:00",
   "placing": 83
  },
  {
   "recording": {
    "title": "Trippin Up",
    "artists": [
     {
      "name": "The Jungle Giants"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/zfxXzWHtaVhd-opqhm4Aze38F7g=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Fc326bdc74dc8b2799f8bc8b24eeac0905317.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:09:30+00:00",
   "placing": 84
  },
  {
   "recording": {
    "title": "Glue Song",
    "artists": [
     {
      "name": "beabadoobee"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/Wqk8UXGqUyRqDQE-MAo7Rq_UQfk=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fbeabadoobee-gluesongsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:04:50+00:00",
   "placing": 85
  },
  {
   "recording": {
    "title": "Never Felt So Alone",
    "artists": [
     {
      "name": "Labrinth"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/kZ-Y-EtMWdNWLO2-tzWlPp3xM9s=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Flabrinth-neverfeltsoalonesingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T02:02:08+00:00",
   "placing": 86
  },
  {
   "recording": {
    "title": "Fine Day Anthem",
    "artists": [
     {
      "name": "Skrillex & Boys Noize"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/608RHiSrIvUucFstbajvVki7TXU=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fskrillexboysnoize-finedayanthemsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:57:34+00:00",
   "placing": 87
  },
  {
   "recording": {
    "title": "Midnight Driving",
    "artists": [
     {
      "name": "Teenage Dads"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/WXalZLCD5GNB4i2r2xadYlvro2w=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fteenagedads-midnightdrivingep.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:53:14+00:00",
   "placing": 88
  },
  {
   "recording": {
    "title": "Nothing Matters",
    "artists": [
     {
      "name": "The Last Dinner Party"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/CZxfUeBMjkl0QOBnr-mAxfPdLwg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fthelastdinnerparty-nothingmatterssingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:47:54+00:00",
   "placing": 89
  },
  {
   "recording": {
    "title": "Nightmare",
    "artists": [
     {
      "name": "Polaris"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/YO5zwBllWLbJ-Zn-AfZWfR6q-n0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpolaris-fatalism.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:43:24+00:00",
   "placing": 90
  },
  {
   "recording": {
    "title": "Did you know that there's a tunnel under Ocean Blvd",
    "artists": [
     {
      "name": "Lana Del Rey"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/zlclnSFio8WfE9gl8ZSPaWXBJPY=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Flanadelrey-didyouknowthattheresatunnelunderoceanblvd.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:37:44+00:00",
   "placing": 91
  },
  {
   "recording": {
    "title": "Strawberry Daydream",
    "artists": [
     {
      "name": "Pacific Avenue"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/SSojr_x1lCwLr669n0vNAakVfdE=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpacificavenue-flowers.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:33:56+00:00",
   "placing": 92
  },
  {
   "recording": {
    "title": "No Bad Days",
    "artists": [
     {
      "name": "The Terrys"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/zSpkpaqXoPlG1NMkdeIy47PdngE=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Ftheterrys-nobaddays.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:29:36+00:00",
   "placing": 93
  },
  {
   "recording": {
    "title": "Sweat You Out My System",
    "artists": [
     {
      "name": "MAY-A"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/a9XqKmoQWeJMAGCIgr3fdbiXQYg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmay-a-sweatyououtmysystemsingle.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:25:57+00:00",
   "placing": 94
  },
  {
   "recording": {
    "title": "Welcome to the DCC",
    "artists": [
     {
      "name": "Nothing But Thieves"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/9vte9JyvaGDok9CGnsQ7uTyE0i4=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2Fmix9d2GwyP%2F6dc95656d536887b458c337efa93758b.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:22:18+00:00",
   "placing": 95
  },
  {
   "recording": {
    "title": "Boys Light Up",
    "artists": [
     {
      "name": "Chillinit"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/t8vt6UZrDMmtoTIL_UILyfV0-40=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2FmiW97Q748P%2F222e2dd6e40de2dd62aa271c3c50ca32.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:17:44+00:00",
   "placing": 96
  },
  {
   "recording": {
    "title": "Stay Blessed",
    "artists": [
     {
      "name": "Genesis Owusu"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/9Dd77PYQlcXMxIeOInZp2mzoVc0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Fdig%2Fcovers%2Fgenesisowusu-struggler.png"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:14:05+00:00",
   "placing": 97
  },
  {
   "recording": {
    "title": "Cool About It",
    "artists": [
     {
      "name": "boygenius"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/WvDj3GwjjQ3r3wKUx2jzr4pNq6g=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fboygenius-therecord.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:11:04+00:00",
   "placing": 98
  },
  {
   "recording": {
    "title": "I Miss You",
    "artists": [
     {
      "name": "Slowly Slowly"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/jXcCx8fGsvscnrbWEOPxcdG8xEI=/160x160/center/middle/https%3A%2F%2Fs3-ap-southeast-2.amazonaws.com%2Fabc-dn-mapi-production%2Frelease%2FmiW98OYrbg%2F57233946ee21324d646c563bbc526315.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:06:15+00:00",
   "placing": 99
  },
  {
   "recording": {
    "title": "Lost The Breakup",
    "artists": [
     {
      "name": "Maisie Peters"
     }
    ],
    "releases": [
     {
      "artwork": [
       {
        "sizes": [
         {
          "url": "a"
         },
         {
          "url": "https://resize.abcradio.net.au/-ljFeWKc4nI4RYS5duKiNbIccZ0=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmaisiepeters-thegoodwitch.jpg"
         }
        ]
       }
      ]
     }
    ]
   },
   "playedTime": "2024-01-27T01:02:40+00:00",
   "placing": 100
  }
 ]

export const countdown2022 = [
  {
      "recording": {
          "title": "in the wake of your leave",
          "artists": [{ "name": "Gang of Youths" }],
          "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
          { "url": "https://i.scdn.co/image/ab67616d00001e02808f20556bdabdfc6a98112d" }]}]}]},
      "placing": 1
  },
  {
    "recording": {
        "title": "Stars In My Eyes",
        "artists": [{ "name": "Ball Park Music" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02e0844f74da14e3843344d659" }]}]}]},
    "placing": 2
  },
  {
    "recording": {
        "title": "Hardlight",
        "artists": [{ "name": "Spacey Jane" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02d0fd973f50ff727e723f8d44" }]}]}]},
    "placing": 3
  },
  {
    "recording": {
        "title": "Say Nothing",
        "artists": [{ "name": "Flume" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02ed1bbd0cdd419a5c8f742ff9" }]}]}]},
    "placing": 4
  },
  {
    "recording": {
        "title": "New Gold",
        "artists": [{ "name": "Gorillaz" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02fda889bb57058a4a1b88edcd" }]}]}]},
    "placing": 5
  },
  {
    "recording": {
        "title": "Sitting Up",
        "artists": [{ "name": "Spacey Jane" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02cc3d962daa7e5d11efc6563e" }]}]}]},
    "placing": 6
  },
  {
    "recording": {
        "title": "This Is Why",
        "artists": [{ "name": "Paramore" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0204ad4e3c0eafac334163cbe4" }]}]}]},
    "placing": 7
  },
  {
    "recording": {
        "title": "Camp Dog",
        "artists": [{ "name": "King Stingray" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0270c588dda0cc31eeec5df97c" }]}]}]},
    "placing": 8
  },
  {
    "recording": {
        "title": "Girl Sports",
        "artists": [{ "name": "Teen Jesus and the Jean Teasers" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02ca70d53853d829a6925f068c" }]}]}]},
    "placing": 9
  },
  {
    "recording": {
        "title": "Delilah (pull me out of this)",
        "artists": [{ "name": "Fred again.." }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e029c856c6f2c6161af49446bf8" }]}]}]},
    "placing": 10
  },
  {
    "recording": {
        "title": "Let's Go",
        "artists": [{ "name": "King Stingray" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0242cbdfc0b2a1bd0676f682ae" }]}]}]},
    "placing": 11
  },  {
    "recording": {
        "title": "B.O.T.A. (Baddest Of Them All)",
        "artists": [{ "name": "Eliza Rose & Interplanetary Criminal" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02eb15b994e15a3a6634d1694e" }]}]}]},
    "placing": 12
  },
  {
    "recording": {
        "title": "Get Inspired",
        "artists": [{ "name": "Genesis Owusu" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e020246531ebdc824f8739e0abf" }]}]}]},
    "placing": 13
  },
  {
    "recording": {
        "title": "Bad Habit",
        "artists": [{ "name": "Steve Lacy" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e029904ecb0b1148a128bdd5e36" }]}]}]},
    "placing": 14
  },
  {
    "recording": {
        "title": "Glimpse of Us",
        "artists": [{ "name": "Joji" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0208596cc28b9f5b00bfe08ae7" }]}]}]},
    "placing": 15
  },
  {
    "recording": {
        "title": "It's Been A Long Day",
        "artists": [{ "name": "Spacey Jane" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02cc3d962daa7e5d11efc6563e" }]}]}]},
    "placing": 16
  },
  {
    "recording": {
        "title": "Backseat Of My Mind",
        "artists": [{ "name": "Thelma Plum" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02bcb37b2ac07f051631a5a034" }]}]}]},
    "placing": 17
  },
  {
    "recording": {
        "title": "Stranger Days",
        "artists": [{ "name": "Skegss" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02937c66d13da9281eee3abd03" }]}]}]},
    "placing": 18
  },
  {
    "recording": {
        "title": "sTraNgeRs",
        "artists": [{ "name": "Bring Me The Horizon" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0225580fd82993317d2ac089d9" }]}]}]},
    "placing": 19
  },
  {
    "recording": {
        "title": "God Is A Freak",
        "artists": [{ "name": "Peach PRC" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02cef51088373cbcba6b8ab28b" }]}]}]},
    "placing": 20
  },
  {
    "recording": {
        "title": "About Damn Time",
        "artists": [{ "name": "Lizzo" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02b817e721691aff3d67f26c04" }]}]}]},
    "placing": 21
  },
  {
    "recording": {
        "title": "Shooting Start [Ft. Toro y Moi] [triple j Like A Version 2022]",
        "artists": [{ "name": "Flume" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e023eaea3639333298c30b1cd24" }]}]}]},
    "placing": 22
  },
  {
    "recording": {
        "title": "Kamikaze",
        "artists": [{ "name": "Voilent Soho" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e023eb9261bb5db1ada76ab43b1" }]}]}]},
    "placing": 23
  },
  {
    "recording": {
        "title": "Am I Ever Gonna See Your Face Again [triple j Like A Version 2022]",
        "artists": [{ "name": "Dune Rats" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "" }]}]}]},
    "placing": 24
  },
  {
    "recording": {
        "title": "I Don't Wanna Do Nothing Forever",
        "artists": [{ "name": "The Smith Street Band" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0219f9dcf1c43c489bd2498817" }]}]}]},
    "placing": 25
  },
  {
    "recording": {
        "title": "Passing Through",
        "artists": [{ "name": "Sly Withers" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02efdaebc851b0f144bda88f55" }]}]}]},
    "placing": 26
  },
  {
    "recording": {
        "title": "GAY 4 ME [Ft. Lauren Sanderson]",
        "artists": [{ "name": "G Flip" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02b9136e94d7e75b27db04df91" }]}]}]},
    "placing": 27
  },
  {
    "recording": {
        "title": "Turn On The Lights again.. [Ft Future]",
        "artists": [{ "name": "Fred again.." }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02f53460be9a3f788777b0fe5c" }]}]}]},
    "placing": 28
  },
  {
    "recording": {
        "title": "Walkin",
        "artists": [{ "name": "Denzel Curry" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e025b924aa009b61b6b9d831eec" }]}]}]},
    "placing": 29
  },
  {
    "recording": {
        "title": "N95",
        "artists": [{ "name": "Kendrick Lamar" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02a291fc7fb5c6b3d5ba818729" }]}]}]},
    "placing": 30
  },
  {
    "recording": {
        "title": "Leaving For London",
        "artists": [{ "name": "Pacific Avenue" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0203a44ddfaf3486339a3004b2" }]}]}]},
    "placing": 31
  },
  {
    "recording": {
        "title": "Jungle",
        "artists": [{ "name": "Fred again.." }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02f53460be9a3f788777b0fe5c" }]}]}]},
    "placing": 32
  },
  {
    "recording": {
        "title": "Facts of Life",
        "artists": [{ "name": "Line Cordiale" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02db8d9fa21c16a9be84f1211e" }]}]}]},
    "placing": 33
  },
  {
    "recording": {
        "title": "Anakin",
        "artists": [{ "name": "The Rions" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0208dfb868e80fe1c3a57d2c91" }]}]}]},
    "placing": 34
  },
  {
    "recording": {
        "title": "Big City Life",
        "artists": [{ "name": "Luude" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0249f6df1a9bd8675d2b3e4e14" }]}]}]},
    "placing": 35
  },
  {
    "recording": {
        "title": "Ur Mum",
        "artists": [{ "name": "Wet Leg" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02ea1126a33b0fb88e79915262" }]}]}]},
    "placing": 36
  },
  {
    "recording": {
        "title": "Milk",
        "artists": [{ "name": "Beddy Rays" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0287e2608146e6006c334f1b8a" }]}]}]},
    "placing": 37
  },
  {
    "recording": {
        "title": "Ripple [prod. Flume/Chrome Sparks]",
        "artists": [{ "name": "Sycco" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0227b9b3428b26a83cfc6e0d15" }]}]}]},
    "placing": 38
  },
  {
    "recording": {
        "title": "Forget You",
        "artists": [{ "name": "Slowly Slowly" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02fcceb8ad77d54319593a3d2f" }]}]}]},
    "placing": 39
  },
  {
    "recording": {
        "title": "Teddy",
        "artists": [{ "name": "Teenage Dads" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e027dae2a91e86c1d513ec93a20" }]}]}]},
    "placing": 40
  },
  {
    "recording": {
        "title": "Terrible",
        "artists": [{ "name": "Teenage Joans" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02bcfba25df6a55620f0691408" }]}]}]},
    "placing": 41
  },
  {
    "recording": {
        "title": "Yellow [tiple j Like A Version 2022]",
        "artists": [{ "name": "King Stingray" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02c3c9109636a187c61b6c7717" }]}]}]},
    "placing": 42
  },
  {
    "recording": {
        "title": "2am",
        "artists": [{ "name": "Foals" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0245a99e9953b74689ae4ff83d" }]}]}]},
    "placing": 43
  },
  {
    "recording": {
        "title": "Wish You Well [Ft. Bernard Fanning]",
        "artists": [{ "name": "Baker Boy" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e021daf4d4dac7a02d9da515285" }]}]}]},
    "placing": 44
  },
  {
    "recording": {
        "title": "I'm In Love With You",
        "artists": [{ "name": "The 1975" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0298551386d3d790a830bc24b8" }]}]}]},
    "placing": 45
  },
  {
    "recording": {
        "title": "Is This What It Feels Like To Feel Like This?",
        "artists": [{ "name": "The Wombats" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e020cef092e364bb51769be158b" }]}]}]},
    "placing": 46
  },
  {
    "recording": {
        "title": "Miracle Maker [Ft. Clementine Douglas]",
        "artists": [{ "name": "Dom Dolla" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02738457fb88b212cbab3a215d" }]}]}]},
    "placing": 47
  },
  {
    "recording": {
        "title": "Break My Soul",
        "artists": [{ "name": "Beyoncé" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02ef5c4d1b49aa500905423be3" }]}]}]},
    "placing": 48
  },
  {
    "recording": {
        "title": "Clarity",
        "artists": [{ "name": "Vance Joy" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02f3ecfb3a692ea5c4e7beec1a" }]}]}]},
    "placing": 49
  },
  {
    "recording": {
        "title": "Handful",
        "artists": [{ "name": "Beddy Rays" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0287e2608146e6006c334f1b8a" }]}]}]},
    "placing": 50
  },
  {
    "recording": {
        "title": "Thousand Miles",
        "artists": [{ "name": "The Kid LAROI" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0286322307ddfb6e9c87b82d39" }]}]}]},
    "placing": 51
  },
  {
    "recording": {
        "title": "The Brown Snake",
        "artists": [{ "name": "Thelma Plum" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0218cc67167865a4c9bee7b817" }]}]}]},
    "placing": 52
  },
  {
    "recording": {
        "title": "Alright",
        "artists": [{ "name": "Sam Fender" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e026d4573e585f8e09e489f8347" }]}]}]},
    "placing": 53
  },
  {
    "recording": {
        "title": "Colin",
        "artists": [{ "name": "Lime Cordiale" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02a554e35b99aacf0ee91f653c" }]}]}]},
    "placing": 54
  },
  {
    "recording": {
        "title": "Weirder & Weirder",
        "artists": [{ "name": "Ball Park Music" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02bfcf14df8aa20106a07b54bf" }]}]}]},
    "placing": 55
  },
  {
    "recording": {
        "title": "2 Be Loved (Am I Ready)",
        "artists": [{ "name": "Lizzo" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02caa75a1b27530c05d8b76675" }]}]}]},
    "placing": 56
  },
  {
    "recording": {
        "title": "Cuff It",
        "artists": [{ "name": "Beyoncé" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e020e58a0f8308c1ad403d105e7" }]}]}]},
    "placing": 57
  },
  {
    "recording": {
        "title": "First Class",
        "artists": [{ "name": "Jack Harlow" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02fd1a49a5b8b9eca67f3911d8" }]}]}]},
    "placing": 58
  },
  {
    "recording": {
        "title": "Slugger 1.4 [2014 Export.WAV]",
        "artists": [{ "name": "Flume" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02af8f828232b13d28d95619ad" }]}]}]},
    "placing": 59
  },
  {
    "recording": {
        "title": "Manny",
        "artists": [{ "name": "Ball Park Music" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02e0844f74da14e3843344d659" }]}]}]},
    "placing": 60
  },
  {
    "recording": {
        "title": "Quicksand",
        "artists": [{ "name": "Hatchie" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e025b74996c8bbc8c787810f953" }]}]}]},
    "placing": 61
  },
  {
    "recording": {
        "title": "Running with the Hurricane",
        "artists": [{ "name": "Camp Cope" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02868dad6064db5ac8389a00b8" }]}]}]},
    "placing": 62
  },
  {
    "recording": {
        "title": "Why Do I Keep You?",
        "artists": [{ "name": "Telenova" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0268e0d2c43b380afc459b226f" }]}]}]},
    "placing": 63
  },
  {
    "recording": {
        "title": "Body Paint",
        "artists": [{ "name": "Arctic Monkeys" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02c5de838d6033c5b7a3fe64cb" }]}]}]},
    "placing": 64
  },
  {
    "recording": {
        "title": "The Dripping Tap",
        "artists": [{ "name": "King Gizzard & The Lizard Wizard" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0280c6db47e75d58e25b72f2ca" }]}]}]},
    "placing": 65
  },
  {
    "recording": {
        "title": "The Foundations of Decay",
        "artists": [{ "name": "My Chemical Romance" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e026e30fdfa40ded68773542d56" }]}]}]},
    "placing": 66
  },
  {
    "recording": {
        "title": "Burn Dem Bridges",
        "artists": [{ "name": "Skin On Skin" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02d6557b2c71a7bf464cfdde12" }]}]}]},
    "placing": 67
  },
  {
    "recording": {
        "title": "There'd Better Be A Mirrorball",
        "artists": [{ "name": "Arctic Monkeys" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02e3244b6267bfca61c83b0678" }]}]}]},
    "placing": 68
  },
  {
    "recording": {
        "title": "Everybody's Saying Thursday's The Weekend",
        "artists": [{ "name": "DMA's" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e026eb934e2122aeba5062bc4a2" }]}]}]},
    "placing": 79
  },
  {
    "recording": {
        "title": "Pulling Through",
        "artists": [{ "name": "Spacey Jane" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02cc3d962daa7e5d11efc6563e" }]}]}]},
    "placing": 70
  },
  {
    "recording": {
        "title": "6L GTR",
        "artists": [{ "name": "The Chats" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02c0ce1c70366805390a132ae0" }]}]}]},
    "placing": 71
  },
  {
    "recording": {
        "title": "Singing Blackbird",
        "artists": [{ "name": "Adam Newling" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e028f2d556341741eca4da7ffb6" }]}]}]},
    "placing": 72
  },
  {
    "recording": {
        "title": "goal of the century",
        "artists": [{ "name": "Gang of Youths" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02606d5328452b81683a125450" }]}]}]},
    "placing": 73
  },
  {
    "recording": {
        "title": "Proof [Ft. Stella Donnelly]",
        "artists": [{ "name": "Methyl Ethyl" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0286fb4311fc3843e3bb3daba5" }]}]}]},
    "placing": 74
  },
  {
    "recording": {
        "title": "Cracker Island [Ft. Thundercat]",
        "artists": [{ "name": "The Gorillaz" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02efbba3463588a325949874d5" }]}]}]},
    "placing": 75
  },
  {
    "recording": {
        "title": "Deepest Darkness",
        "artists": [{ "name": "Ocean Alley" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e022e3f5c62883c2ae1d60f3b0a" }]}]}]},
    "placing": 76
  },
  {
    "recording": {
        "title": "Vegas",
        "artists": [{ "name": "Doja Cat" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0229e2914af6e83df7e963d365" }]}]}]},
    "placing": 77
  },
  {
    "recording": {
        "title": "EDGING",
        "artists": [{ "name": "blink-182" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02992db40efc4367bebea586f7" }]}]}]},
    "placing": 78
  },
  {
    "recording": {
        "title": "GTFO",
        "artists": [{ "name": "Genesis Owusu" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02076c5e0639fe91be474cdcbd" }]}]}]},
    "placing": 79
  },
  {
    "recording": {
        "title": "Forever Drunk",
        "artists": [{ "name": "Peach PRC" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02d020c26c90703defe0541d83" }]}]}]},
    "placing": 80
  },
  {
    "recording": {
        "title": "Yet",
        "artists": [{ "name": "Spacey Jane" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02cc3d962daa7e5d11efc6563e" }]}]}]},
    "placing": 81
  },
  {
    "recording": {
        "title": "maybe [Ft. Bring Me The Horizon]",
        "artists": [{ "name": "Machine Gun Kelly" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02b4683d9ac3c5f14a71523c84" }]}]}]},
    "placing": 82
  },
  {
    "recording": {
        "title": "the kingdom is within you",
        "artists": [{ "name": "Gang of Youths" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02606d5328452b81683a125450" }]}]}]},
    "placing": 83
  },
  {
    "recording": {
        "title": "Jackie Down The Line",
        "artists": [{ "name": "Fontaines D.C." }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e020e432a33b3b2570955c1bbf4" }]}]}]},
    "placing": 84
  },
  {
    "recording": {
        "title": "Sidelines",
        "artists": [{ "name": "Phoebe Bridgers" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0223a9bfabf54914f791bea535" }]}]}]},
    "placing": 85
  },
  {
    "recording": {
        "title": "This Hell",
        "artists": [{ "name": "Rina Sawayama" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02227eaa88581de85f3024dee3" }]}]}]},
    "placing": 86
  },
  {
    "recording": {
        "title": "pity party [Ft. Royal & the Serpent]",
        "artists": [{ "name": "Stand Atlantic" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02a7e350a4babfd4d315d2891d" }]}]}]},
    "placing": 87
  },
  {
    "recording": {
        "title": "Surprise Me [Ft. Azelia Banks]",
        "artists": [{ "name": "Mallrat" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0287a2299e9c45a91b8df457f8" }]}]}]},
    "placing": 88
  },
  {
    "recording": {
        "title": "I Was Neon",
        "artists": [{ "name": "Julia Jacklin" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02b819c8b6db769a1e4bd544eb" }]}]}]},
    "placing": 89
  },
  {
    "recording": {
        "title": "Oysters In My Pocket",
        "artists": [{ "name": "Royel Otis" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0225fbcf05f82cadcf67010cdd" }]}]}]},
    "placing": 90
  },
  {
    "recording": {
        "title": "Red Flags",
        "artists": [{ "name": "The Buoys" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02dfe732a84ad72717b9e0dd03" }]}]}]},
    "placing": 91
  },
  {
    "recording": {
        "title": "Lydia Wears A Cross",
        "artists": [{ "name": "Julia Jacklin" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0215650169fa3df417c841adb3" }]}]}]},
    "placing": 92
  },
  {
    "recording": {
        "title": "Home",
        "artists": [{ "name": "Ocean Alley" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0252f8ca6a60e1fd6aca725cc5" }]}]}]},
    "placing": 93
  },
  {
    "recording": {
        "title": "It's A Killa",
        "artists": [{ "name": "FISHER & Shermanology" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e025a8d9b0e4dc92b2e846334ff" }]}]}]},
    "placing": 94
  },
  {
    "recording": {
        "title": "Nothing On",
        "artists": [{ "name": "Slowly Slowly" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0229fc3e6d5b391f8e0f9a9708" }]}]}]},
    "placing": 95
  },
  {
    "recording": {
        "title": "Country Club",
        "artists": [{ "name": "Lime Cordiale" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0267a9255fe1e0ee7fc9fd41a7" }]}]}]},
    "placing": 96
  },
  {
    "recording": {
        "title": "Luvin U Is Easy",
        "artists": [{ "name": "Confidence Man" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e02a000fe8e75b833cc65fcef85" }]}]}]},
    "placing": 97
  },
  {
    "recording": {
        "title": "STAR WALKIN' (League Of Legends Worlds Anthem)",
        "artists": [{ "name": "Lil Nas X" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e0204cd9a1664fb4539a55643fe" }]}]}]},
    "placing": 98
  },
  {
    "recording": {
        "title": "Free",
        "artists": [{ "name": "Florence + The Machine" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e025fd9e21618182df7d70b2186" }]}]}]},
    "placing": 99
  },
  {
    "recording": {
        "title": "Glitch",
        "artists": [{ "name": "Parkway Drive" }],
        "releases": [{ "artwork": [{  "sizes": [{ "url": "a" },
        { "url": "https://i.scdn.co/image/ab67616d00001e027503e94c39c17a0838a05856" }]}]}]},
    "placing": 100
  }
  

];
