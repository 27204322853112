import React from 'react';

import '../../style/topMenu.css';


const NoLinkTopMenu = ({ topMenuItems, menuName, setMenuName, mobileOnly=false }) => {

  return (
    <div id="topMenu" className={mobileOnly ? "mobile-only nolink-menu fr ac" : "nolink-menu fr ac"}>
      { topMenuItems.map((item, i) => <NoLinkTopMenuItem key={i} item={item} isSelected={item.name===menuName} setMenuName={setMenuName} />) }
    </div>
  )
};

const NoLinkTopMenuItem = ({ item, isSelected, setMenuName }) => {

  return (
    <div to={item.link} className="point fc ac jc" onClick={() => setMenuName(item.name)}>
      <img className="top-menu-item-image" alt="menu item logo" 
      src={require(`../../images/menu-items/top-menu/${isSelected 
        ? item.imageSource + "-orang" 
        : item.imageSource}.png`)} >
      </img>

      <p className="top-menu-item-name">{item.name}</p>
      { isSelected && <div className="stroke-underline"></div> }
    </div>
  )
};


export default NoLinkTopMenu;