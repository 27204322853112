const top3Outside3MenuItems = [
  {
    name: "playing",
    imageSource: "vinyl"
  },
  {
   name: "players",
   imageSource: "friend" 
  },
  {
    name: "ranking",
    imageSource: "leaderboard"
  }
]

const spotifyMenuItems = [
  {
    name: "top tracks",
    imageSource: "vinyl",
    link: "top/tracks"
  },
  {
    name: "top artists",
    imageSource: "guitar",
    link: "top/artists"
  }
];

const hottest100MenuItems = [
  {
    name: "shortlist",
    imageSource: "contact-list",
    link: "shortlist"
  },
  {
    name: "trending",
    imageSource: "fire",
    link: "trending"
  }
];

const tripleJMenuItems = [
  {
    name: "playing",
    imageSource: "play-button",
    link: "playing"
  },
  {
    name: "popular now",
    imageSource: "subscriber",
    link: "popular-now"
  }, {
    name: "archives",
    imageSource: "documents",
    link: "archives"
  }
];

const webIcon = {
  name: "urpix",
  imageSource: "guitar-icon.png",
  link: "",
};


const sideMenuItems = [
  {
    name: "home",
    imageSource: "house.png",
    link: "/"
  },
  {
    name: "my shortlist",
    imageSource: "jjjflower.png",
    link: "/hottest-100"
  },
  {
    name: "triple j",
    imageSource: "jjjdrum.png",
    link: "/triple-j"
  },
  {
    name: "my top spotify",
    imageSource: "spotify.png",
    link: "/spotify"
  },
  {
    name: "companions",
    imageSource: "companions.png",
    link: "/companions",
    featureToggle: "companions"
  },
  {
    name: "feedback",
    imageSource: "feedback.png",
    link: "/feedback"
  },
  // {
  //   name: "settings",
  //   imageSource: "gear.png",
  //   link: "/settings"
  // },
  {
    name: "logout",
    imageSource: "logout.png",
    link: "/logout/clear-token",
    conditionalRendering: "logged in"
  }
];

const socials = [
  {
    name: "gmail",
    image: "gmail.png",
    text: "jesse10klein@gmail.com"
  },  
  {
    name: "github",
    image: "github.png",
    text: "@jesse10klein"
  }
];

const companionInfos = [
  {
    name: "top 5 outside 5",
    image: "podium.png",
    link: "top-5-outside-5",
    description: "pick five songs that you think will get as close to the top 5 as possible. be careful not to bust!",
    rules: [
      "each player must pick 5 songs that they think will get closest to the top 5, without making it in the top 5",
      "players score points for each song, based on how close it is to the top 5",
      "if one of a player’s songs makes it in the top 5, they are considered busted and are out of the game",
      "songs that place in the top 200 countdown don’t receive any points",
      "the amount of points a song receives is: 104 - placing. so 4th place receives 100 points, and 100th place receives 4 points",
      "the player with the most points at the end of the countdown wins"
    ],
    isPlayable: true
  },
  {
    name: "hottest 100 bingo",
    image: "bingo.png",
    link: "hottest-100-bingo",
    description: "fill out bingo boards as individuals or as a team. first to get 4 in a row wins!",
    rules: [
      "create a single bingo board for the party, or each player can create their own.",
      "pick a song or artist to be placed in each square",
      "first player to get bingo wins",
      "you may place no more than 5 artists on your bingo board, and no more than 2 in a single row/column (since artists can have multiple songs in the countdown)"
    ],
    isPlayable: true
  },
  {
    name: "countdown tracker",
    image: "jjjflowers.png",
    link: "countdown-tracker",
    description: "no games, no funny business, just the countdown tracked live on the big screen",
    rules: [],
    isPlayable: false
  }
]


const hottest100Results = {
  2023: [
    {
      "placing": 1,
      "track": "Paint the Town Red",
      "artist": "Doja Cat",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e025095063d561e992c06cf1d8c"
    },
    {
      "placing": 2,
      "track": "The Worst Person Alive",
      "artist": "G Flip",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02748fca61f250ad58cdec27d0"
    },
    {
      "placing": 3,
      "track": "Saving Up",
      "artist": "Dom Dolla",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02585c329dfd9cdeb22b6cd247"
    },
    {
      "placing": 4,
      "track": "Rhyme Dust",
      "artist": "MK and Dom Dolla",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02fb94641e49b1c73297e9e717"
    },
    {
      "placing": 5,
      "track": "Prada",
      "artist": "Cassö",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02e4b7dccdfd54375ded9ccc5c"
    },
    {
      "placing": 6,
      "track": "Adore U",
      "artist": "Fred Again and Obongjayar",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02f14e70dbc29485778e114039"
    },
    {
      "placing": 7,
      "track": "What Was I Made For?",
      "artist": "Billie Eilish",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02ed317ec13d3de9e01fb99c9e"
    },
    {
      "placing": 8,
      "track": "Rush",
      "artist": "Troye Sivan",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0267103283a4eb57578a428252"
    },
    {
      "placing": 9,
      "track": "Lovin on Me",
      "artist": "Jack Harlow",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02b2ee67d1b612c479f17a171c"
    },
    {
      "placing": 10,
      "track": "Chemical",
      "artist": "Post Malone",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02f76f8deeba5370c98ad38f1c"
    },
    {
      "placing": 11,
      "track": "Vampire",
      "artist": "Olivia Rodrigo",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02e85259a1cae29a8d91f2093d"
    },
    {
      "placing": 12,
      "track": "Rumble",
      "artist": "Skrillex, Fred Again and Flowdan",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02352f154c54727bc8024629bc"
    },
    {
      "placing": 13,
      "track": "Kill Bill",
      "artist": "SZA",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020c471c36970b9406233842a5"
    },
    {
      "placing": 14,
      "track": "Atmosphere",
      "artist": "Fisher and Kita Alexander",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e026b4fbc75e53e844a678fa8a5"
    },
    {
      "placing": 15,
      "track": "(It Goes Like) Nanana",
      "artist": "Peggy Gou",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02a658ab0f60cc249da0b97de5"
    },
    {
      "placing": 16,
      "track": "Sprinter",
      "artist": "Dave and Central Cee",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02e3a09a9ae3f1fa102c110e60"
    },
    {
      "placing": 17,
      "track": "Back on 74",
      "artist": "Jungle",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0277619f14cb03e11baf5761d1"
    },
    {
      "placing": 18,
      "track": "Eat Your Man",
      "artist": "Dom Dolla and Nelly Furtado",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0239f146ab788622f26a705e57"
    },
    {
      "placing": 19,
      "track": "Therapy",
      "artist": "Budjerah",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02a16bce82dfd150ceba562f68"
    },
    {
      "placing": 20,
      "track": "Sorry Instead",
      "artist": "Spacey Jane",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02186620c3f53f0ccda5fcad42"
    },
    {
      "placing": 21,
      "track": "Greedy",
      "artist": "Tate McRae",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0222fd802bc61db666c7c81aa8"
    },
    {
      "placing": 22,
      "track": "Be Your Man",
      "artist": "G Flip",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020a047f3843059801ab7ecb18"
    },
    {
      "placing": 23,
      "track": "Take It Off",
      "artist": "Fisher and Aatig",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02040567881df634df03c2da6a"
    },
    {
      "placing": 24,
      "track": "Good Enough",
      "artist": "G Flip",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e024406d6a9977c215ea6ba0e41"
    },
    {
      "placing": 25,
      "track": "Rich Baby Daddy",
      "artist": "Drake featuring Sexyy Red and SZA",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020ebdbd881e232d18445ace27"
    },
    {
      "placing": 26,
      "track": "Rough",
      "artist": "G Flip",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e024406d6a9977c215ea6ba0e41"
    },
    {
      "placing": 27,
      "track": "Imposter Syndrome",
      "artist": "Lime Cordiale",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02f4f7ea9361f28e4bdb8e76e0"
    },
    {
      "placing": 28,
      "track": "Dance the Night",
      "artist": "Dua Lipa",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02d16ac0ec27653182d719ab36"
    },
    {
      "placing": 29,
      "track": "Say Yes to Heaven",
      "artist": "Lana Del Rey",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02aa27708d07f49c82ff0d0dae"
    },
    {
      "placing": 30,
      "track": "Not Strong Enough",
      "artist": "Boygenius",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0243fc02bcfa7cd4e6bb66aa22"
    },
    {
      "placing": 31,
      "track": "One of Your Girls",
      "artist": "Troye Sivan",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0267103283a4eb57578a428252"
    },
    {
      "placing": 32,
      "track": "Bad Idea Right?",
      "artist": "Olivia Rodrigo",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02e85259a1cae29a8d91f2093d"
    },
    {
      "placing": 33,
      "track": "Got Me Started",
      "artist": "Troye Sivan",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0267103283a4eb57578a428252"
    },
    {
      "placing": 34,
      "track": "Get Him Back!",
      "artist": "Olivia Rodrigo",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02e85259a1cae29a8d91f2093d"
    },
    {
      "placing": 35,
      "track": "Baby Again..",
      "artist": "Fred Again, Skrillex and Four Tet",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02caa718aa1278c4016a17bc05"
    },
    {
      "placing": 36,
      "track": "Boy's a Liar Pt. 2",
      "artist": "PinkPantheress and Ice Spice",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e029567e1aa41657425d046733b"
    },
    {
      "placing": 37,
      "track": "Laced Up",
      "artist": "Hilltop Hoods",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02710b75e81fd4c216b9f806ec"
    },
    {
      "placing": 38,
      "track": "Minivan",
      "artist": "The Rions",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0269e4b07304ae8def2f300265"
    },
    {
      "placing": 39,
      "track": "Green Honda",
      "artist": "Benee",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02211185df845250182852e4cf"
    },
    {
      "placing": 40,
      "track": "Scary Movies",
      "artist": "The Rions",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e026da2a284a40cdb295b98854a"
    },
    {
      "placing": 41,
      "track": "Houdini",
      "artist": "Dua Lipa",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02001d5706fddc72561f6488af"
    },
    {
      "placing": 42,
      "track": "Pedestal",
      "artist": "Lime Cordiale",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0265ea3484f00420d5fb3d850a"
    },
    {
      "placing": 43,
      "track": "Popular",
      "artist": "The Weeknd, Playboi Carti and Madonna",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e024c8f092adc59b4bf4212389d"
    },
    {
      "placing": 44,
      "track": "My Love Mine All Mine",
      "artist": "Mitski",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0234f21d3047d85440dfa37f10"
    },
    {
      "placing": 45,
      "track": "Still Have Room",
      "artist": "Hockey Dad",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02bb48ebb0247c41cd17e87e33"
    },
    {
      "placing": 46,
      "track": "Strangers",
      "artist": "Kenya Grace",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e024756c2e9ae436437cd75e9f3"
    },
    {
      "placing": 47,
      "track": "Sweetheart",
      "artist": "Old Mervs",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02f7c4f460bc66922f859a43d0"
    },
    {
      "placing": 48,
      "track": "Padam Padam",
      "artist": "Kylie Minogue",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020536a87c690530562f30d493"
    },
    {
      "placing": 49,
      "track": "Agora Hills",
      "artist": "Doja Cat",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02a840032f14ab22da396e43cb"
    },
    {
      "placing": 50,
      "track": "Australia",
      "artist": "G Flip",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e024406d6a9977c215ea6ba0e41"
    },
    {
      "placing": 51,
      "track": "Ten",
      "artist": "Fred Again and Jozzy",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02d6cddb90ea739c5c20b9edb4"
    },
    {
      "placing": 52,
      "track": "I Used to Be Fun",
      "artist": "Teen Jesus and the Jean Teasers",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020840ed39457e2bc4232d69bb"
    },
    {
      "placing": 53,
      "track": "Lookin' Out",
      "artist": "King Stingray",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02de13b06f5be88ab58c4a82aa"
    },
    {
      "placing": 54,
      "track": "More Than You Know",
      "artist": "Blink-182",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02958241aa80311b139ef14857"
    },
    {
      "placing": 55,
      "track": "We Don't Talk About It",
      "artist": "Thelma Plum",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0288d32b9b6eeb0b1332d13a05"
    },
    {
      "placing": 56,
      "track": "All-American Bitch",
      "artist": "Olivia Rodrigo",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02e85259a1cae29a8d91f2093d"
    },
    {
      "placing": 57,
      "track": "Darkside",
      "artist": "Bring Me the Horizon",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0201aa13aa3a7c9f2df2db0d0f"
    },
    {
      "placing": 58,
      "track": "Lost",
      "artist": "Bring Me the Horizon",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02068b7b754072969555a095f5"
    },
    {
      "placing": 59,
      "track": "Spin Me Like Your Records",
      "artist": "Pacific Avenue",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0226b36b87e5b267af8d85a8b2"
    },
    {
      "placing": 60,
      "track": "A&W",
      "artist": "Lana Del Rey",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0259ae8cf65d498afdd5585634"
    },
    {
      "placing": 61,
      "track": "Love Again",
      "artist": "The Kid Laroi",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02a53643fc03785efb9926443d"
    },
    {
      "placing": 62,
      "track": "Dogtooth",
      "artist": "Tyler the Creator",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02aa95a399fd30fbb4f6f59fca"
    },
    {
      "placing": 63,
      "track": "Perfect for You",
      "artist": "Peach PRC",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02b17d54e5aa20759762a3196d"
    },
    {
      "placing": 64,
      "track": "Fall at Your Feet (Like a Version)",
      "artist": "Peking Duk featuring Julia Stone",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0200a62ec2d0c40b41b12410bc"
    },
    {
      "placing": 65,
      "track": "Real Life",
      "artist": "G Flip",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e024406d6a9977c215ea6ba0e41"
    },
    {
      "placing": 66,
      "track": "Barbie World",
      "artist": "Nicki Minaj, Ice Spice and Aqua",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e027d36503f38307834b70bf097"
    },
    {
      "placing": 67,
      "track": "Letting Go",
      "artist": "Angie McMahon",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02cc6ab5ba4f58e34a4dc8f0e3"
    },
    {
      "placing": 68,
      "track": "F U Goodbye",
      "artist": "Peach PRC",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02e390e4f539c4eaf6fa5add86"
    },
    {
      "placing": 69,
      "track": "Leaving the Light",
      "artist": "Genesis Owusu",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0245baa260e34b486496512aee"
    },
    {
      "placing": 70,
      "track": "Snooze",
      "artist": "SZA",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020c471c36970b9406233842a5"
    },
    {
      "placing": 71,
      "track": "Take What You Want",
      "artist": "The Rions",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e022c996cf9fcab39e17ed87d52"
    },
    {
      "placing": 72,
      "track": "Stockholm",
      "artist": "Dice",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02ff48824284e6a4e4f23eb968"
    },
    {
      "placing": 73,
      "track": "Water",
      "artist": "Tyla",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02d20231861e86a6f74ef2393e"
    },
    {
      "placing": 74,
      "track": "Speedracer",
      "artist": "Teenage Dads",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020b10606ccf17d8d4eaa000ce"
    },
    {
      "placing": 75,
      "track": "Nobody Gets Me",
      "artist": "SZA",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020c471c36970b9406233842a5"
    },
    {
      "placing": 76,
      "track": "Sofa King",
      "artist": "Royel Otis",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02bcd1d8e3c20365e926518aa8"
    },
    {
      "placing": 77,
      "track": "I Don't Wanna Be Like You",
      "artist": "Ruel",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e020393be737d337be7e11ee352"
    },
    {
      "placing": 78,
      "track": "Bleed",
      "artist": "The Kid Laroi",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02f538085647c9f3790dc2e5e4"
    },
    {
      "placing": 79,
      "track": "Video Killed the Radio Star (Like a Version)",
      "artist": "Teenage Dads",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0234d9d8a6df0a68061d7ebb1c"
    },
    {
      "placing": 80,
      "track": "7 Days",
      "artist": "G Flip",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e024406d6a9977c215ea6ba0e41"
    },
    {
      "placing": 81,
      "track": "Like a Girl Does",
      "artist": "Peach PRC",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02f50289b27ab9cc86d36b4213"
    },
    {
      "placing": 82,
      "track": "Exes",
      "artist": "Tate McRae",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e027ecf8ea5d7f266112e6d7b20"
    },
    {
      "placing": 83,
      "track": "The Summoning",
      "artist": "Sleep Token",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e029d62fac089698961eab30227"
    },
    {
      "placing": 84,
      "track": "Trippin Up",
      "artist": "The Jungle Giants",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02ffb22dbe3f565b47a37d2db6"
    },
    {
      "placing": 85,
      "track": "Glue Song",
      "artist": "Beabadoobee",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02e3862aeefcb2f0860ef017e4"
    },
    {
      "placing": 86,
      "track": "Never Felt So Alone",
      "artist": "Labrinth",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e027dda6cb97a57f116f6fbf0be"
    },
    {
      "placing": 87,
      "track": "Fine Day Anthem",
      "artist": "Skrillex and Boys Noize",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02785ac2656966e79cc0a5081c"
    },
    {
      "placing": 88,
      "track": "Midnight Driving",
      "artist": "Teenage Dads",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02d27db2376ba7040a3a585241"
    },
    {
      "placing": 89,
      "track": "Nothing Matters",
      "artist": "The Last Dinner Party",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02123baf784d69e7d462eb4bca"
    },
    {
      "placing": 90,
      "track": "Nightmare",
      "artist": "Polaris",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02b88cf15479017849d94ab889"
    },
    {
      "placing": 91,
      "track": "Did You Know That There's a Tunnel Under Ocean Blvd",
      "artist": "Lana Del Rey",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0259ae8cf65d498afdd5585634"
    },
    {
      "placing": 92,
      "track": "Strawberry Daydream",
      "artist": "Pacific Avenue",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0226b36b87e5b267af8d85a8b2"
    },
    {
      "placing": 93,
      "track": "No Bad Days",
      "artist": "The Terrys",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e025e7074dfd650dd7378074ca3"
    },
    {
      "placing": 94,
      "track": "Sweat You Out My System",
      "artist": "May-a",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e022ab263a61f97b90c741b5977"
    },
    {
      "placing": 95,
      "track": "Welcome to the DCC",
      "artist": "Nothing but Thieves",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0267b6e438b2d1dfec09918926"
    },
    {
      "placing": 96,
      "track": "Boys Light Up",
      "artist": "Chillinit",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e029c9e8be25886568dd168b427"
    },
    {
      "placing": 97,
      "track": "Stay Blessed",
      "artist": "Genesis Owusu",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e027ff1b08f754032ec58c8ab09"
    },
    {
      "placing": 98,
      "track": "Cool About It",
      "artist": "Boygenius",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0243fc02bcfa7cd4e6bb66aa22"
    },
    {
      "placing": 99,
      "track": "I Miss You (Like a Version)",
      "artist": "Slowly Slowly",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e0257049737c2b8b3ea30bd4271"
    },
    {
      "placing": 100,
      "track": "Lost the Breakup",
      "artist": "Maisie Peters",
      "imageSource": "https://i.scdn.co/image/ab67616d00001e02ca69bf6c658aa5fd60778c10"
    }
  ],
  2022: [
    {
      placing: 1,
      track: "Say Nothing",
      artist: "Flume featuring May-a",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02ed1bbd0cdd419a5c8f742ff9"
    },
    {
      placing: 2,
      track: "B.O.T.A. (Baddest of Them All)",
      artist: "Eliza Rose and Interplanetary Criminal",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02eb15b994e15a3a6634d1694e"
    },
    {
      placing: 3,
      track: "Hardlight",
      artist: "Spacey Jane",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02d0fd973f50ff727e723f8d44"
    },
    {
      placing: 4,
      track: "Bad Habit",
      artist: "Steve Lacy",
      imageSource: "https://i.scdn.co/image/ab67616d00001e029904ecb0b1148a128bdd5e36"
    },
    {
      placing: 5,
      track: "It's Been a Long Day",
      artist: "Spacey Jane",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02d0fd973f50ff727e723f8d44"
    },
    {
      placing: 6,
      track: "Sitting Up",
      artist: "Spacey Jane",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02d0fd973f50ff727e723f8d44"
    },
    {
      placing: 7,
      track: "About Damn Time",
      artist: "Lizzo",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02b817e721691aff3d67f26c04"
    },
    {
      placing: 8,
      track: "Stars in My Eyes",
      artist: "Ball Park Music",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02447a4fcf2ba59897c6d2321c"
    },
    {
      placing: 9,
      track: "In the Wake of Your Leave",
      artist: "Gang of Youths",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02808f20556bdabdfc6a98112d"
    },
    {
      placing: 10,
      track: "Glimpse of Us",
      artist: "Joji",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0208596cc28b9f5b00bfe08ae7"
    },
    {
      placing: 11,
      track: "Gay 4 Me",
      artist: "G Flip featuring Lauren Sanderson",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02b9136e94d7e75b27db04df91"
    },
    {
      placing: 12,
      track: "First Class",
      artist: "Jack Harlow",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02fd1a49a5b8b9eca67f3911d8"
    },
    {
      placing: 13,
      track: "New Gold",
      artist: "Gorillaz featuring Tame Impala and Bootie Brown",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02fda889bb57058a4a1b88edcd"
    },
    {
      placing: 14,
      track: "Delilah (Pull Me Out of This)",
      artist: "Fred Again",
      imageSource: "https://i.scdn.co/image/ab67616d00001e029c856c6f2c6161af49446bf8"
    },
    {
      placing: 15,
      track: "Facts of Life",
      artist: "Lime Cordiale",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02db8d9fa21c16a9be84f1211e"
    },
    {
      placing: 16,
      track: "God Is a Freak",
      artist: "Peach PRC",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02cef51088373cbcba6b8ab28b"
    },
    {
      placing: 17,
      track: "Get Inspired",
      artist: "Genesis Owusu",
      imageSource: "https://i.scdn.co/image/ab67616d00001e020246531ebdc824f8739e0abf"
    },
    {
      placing: 18,
      track: "Turn On the Lights Again",
      artist: "Fred Again and Swedish House Mafia featuring Future",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02f53460be9a3f788777b0fe5c"
    },
    {
      placing: 19,
      track: "Stranger Days",
      artist: "Skegss",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02937c66d13da9281eee3abd03"
    },
    {
      placing: 20,
      track: "Shirt",
      artist: "SZA",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02e5d70475c1cb52776fb35265"
    },
    {
      placing: 21,
      track: "Backseat of My Mind",
      artist: "Thelma Plum",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02bcb37b2ac07f051631a5a034"
    },
    {
      placing: 22,
      track: "Vegas",
      artist: "Doja Cat",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0229e2914af6e83df7e963d365"
    },
    {
      placing: 23,
      track: "Am I Ever Gonna See Your Face Again (Like a Version)",
      artist: "Dune Rats",
      imageSource: "https://live-production.wcms.abc-cdn.net.au/7285cd36cd9bcdddd8a7b905715a3b12?impolicy=wcms_crop_resize&cropH=1080&cropW=1920&xPos=0&yPos=0&width=862&height=485"
    },
    {
      placing: 24,
      track: "Cuff It",
      artist: "Beyoncé",
      imageSource: "https://i.scdn.co/image/ab67616d00001e020e58a0f8308c1ad403d105e7"
    },
    {
      placing: 25,
      track: "Pulling Through",
      artist: "Spacey Jane",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02cc3d962daa7e5d11efc6563e"
    },
    {
      placing: 26,
      track: "Jungle",
      artist: "Fred Again",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02f53460be9a3f788777b0fe5c"
    },
    {
      placing: 27,
      track: "Camp Dog",
      artist: "King Stingray",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0270c588dda0cc31eeec5df97c"
    },
    {
      placing: 28,
      track: "Big City Life",
      artist: "Luude and Mattafix",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0249f6df1a9bd8675d2b3e4e14"
    },
    {
      placing: 29,
      track: "Clarity",
      artist: "Vance Joy",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02f3ecfb3a692ea5c4e7beec1a"
    },
    {
      placing: 30,
      track: "Shooting Stars (Like a Version)",
      artist: "Flume featuring Toro y Moi",
      imageSource: "https://i.scdn.co/image/ab67616d00001e023eaea3639333298c30b1cd24"
    },
    {
      placing: 31,
      track: "Wish You Well",
      artist: "Baker Boy featuring Bernard Fanning",
      imageSource: "https://i.scdn.co/image/ab67616d00001e021daf4d4dac7a02d9da515285"
    },
    {
      placing: 32,
      track: "TV",
      artist: "Billie Eilish",
      imageSource: "https://i.scdn.co/image/ab67616d00001e027a4781629469bb83356cd318"
    },
    {
      placing: 33,
      track: "Thousand Miles",
      artist: "The Kid Laroi",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0286322307ddfb6e9c87b82d39"
    },
    {
      placing: 34,
      track: "Colin",
      artist: "Lime Cordiale",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02a554e35b99aacf0ee91f653c"
    },
    {
      placing: 35,
      track: "Let's Go",
      artist: "King Stingray",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0242cbdfc0b2a1bd0676f682ae"
    },
    {
      placing: 36,
      track: "2 Be Loved (Am I Ready)",
      artist: "Lizzo",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02caa75a1b27530c05d8b76675"
    },
    {
      placing: 37,
      track: "Miracle Maker",
      artist: "Dom Dolla featuring Clementine Douglas",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02738457fb88b212cbab3a215d"
    },
    {
      placing: 38,
      track: "Strangers",
      artist: "Bring Me the Horizon",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0225580fd82993317d2ac089d9"
    },
    {
      placing: 39,
      track: "Doja",
      artist: "Central Cee",
      imageSource: "https://i.scdn.co/image/ab67616d00001e023c7d945b6baf935e8a0ebdaa"
    },
    {
      placing: 40,
      track: "Yet",
      artist: "Spacey Jane",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02cc3d962daa7e5d11efc6563e"
    },
    {
      placing: 41,
      track: "N95",
      artist: "Kendrick Lamar",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02a291fc7fb5c6b3d5ba818729"
    },
    {
      placing: 42,
      track: "Break My Soul",
      artist: "Beyoncé",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02ef5c4d1b49aa500905423be3"
    },
    {
      placing: 43,
      track: "Yellow (Like a Version)",
      artist: "King Stingray",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02c3c9109636a187c61b6c7717"
    },
    {
      placing: 44,
      track: "Rich Flex",
      artist: "Drake and 21 Savage",
      imageSource: "https://i.scdn.co/image/ab67616d00001e020f76fb9c0ab0991c70829a91"
    },
    {
      placing: 45,
      track: "This Is Why",
      artist: "Paramore",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0204ad4e3c0eafac334163cbe4"
    },
    {
      placing: 46,
      track: "Ripple",
      artist: "Sycco featuring Flume and Chrome Sparks",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0227b9b3428b26a83cfc6e0d15"
    },
    {
      placing: 47,
      track: "Growing Up Is _____",
      artist: "Ruel",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0223796fe755a38accf0e3b61d"
    },
    {
      placing: 48,
      track: "It's a Killa",
      artist: "Fisher and Shermanology",
      imageSource: "https://i.scdn.co/image/ab67616d00001e025a8d9b0e4dc92b2e846334ff"
    },
    {
      placing: 49,
      track: "Star Walkin' (League of Legends Worlds Anthem)",
      artist: "Lil Nas X",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0204cd9a1664fb4539a55643fe"
    },
    {
      placing: 50,
      track: "Slugger 1.4 (2014 Export.WAV)",
      artist: "Flume",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02af8f828232b13d28d95619ad"
    },
    {
      placing: 51,
      track: "I Like You (A Happier Song)",
      artist: "Post Malone featuring Doja Cat",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0234362676667a4322838ccc97"
    },
    {
      placing: 52,
      track: "Burn Dem Bridges",
      artist: "Skin on Skin",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02d6557b2c71a7bf464cfdde12"
    },
    {
      placing: 53,
      track: "6L GTR",
      artist: "The Chats",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02c0ce1c70366805390a132ae0"
    },
    {
      placing: 54,
      track: "A Whole Day's Night",
      artist: "Hilltop Hoods featuring Montaigne and Tom Thum",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02519a45dbec034ecd602ff9e9"
    },
    {
      placing: 55,
      track: "Girl Sports",
      artist: "Teen Jesus and the Jean Teasers",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02ca70d53853d829a6925f068c"
    },
    {
      placing: 56,
      track: "Deepest Darkness",
      artist: "Ocean Alley",
      imageSource: "https://i.scdn.co/image/ab67616d00001e022e3f5c62883c2ae1d60f3b0a"
    },
    {
      placing: 57,
      track: "Leaving for London",
      artist: "Pacific Avenue",
      imageSource: "https://i.scdn.co/image/ab67616d00001e028a50b46ae12abbdd4cd1e3ed"
    },
    {
      placing: 58,
      track: "I'm in Love with You",
      artist: "The 1975",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0298551386d3d790a830bc24b8"
    },
    {
      placing: 59,
      track: "Double Vision",
      artist: "Ocean Alley",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02672c078b423b6d579c610bd4"
    },
    {
      placing: 60,
      track: "Country Club",
      artist: "Lime Cordiale",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0267a9255fe1e0ee7fc9fd41a7"
    },
    {
      placing: 61,
      track: "Messy in Heaven",
      artist: "Venbee and Goddard",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0202402656bca0b5b39489f39a"
    },
    {
      placing: 62,
      track: "Home",
      artist: "Ocean Alley",
      imageSource: "https://i.scdn.co/image/ab67616d00001e021ed0d278dcb0e859f6c1bf4e"
    },
    {
      placing: 63,
      track: "Teddy",
      artist: "Teenage Dads",
      imageSource: "https://i.scdn.co/image/ab67616d00001e027dae2a91e86c1d513ec93a20"
    },
    {
      placing: 64,
      track: "Anakin",
      artist: "The Rions",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0208dfb868e80fe1c3a57d2c91"
    },
    {
      placing: 65,
      track: "Forever Drunk",
      artist: "Peach PRC",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02d020c26c90703defe0541d83"
    },
    {
      placing: 66,
      track: "The Brown Snake",
      artist: "Thelma Plum",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0218cc67167865a4c9bee7b817"
    },
    {
      placing: 67,
      track: "Escapism",
      artist: "Raye featuring 070 Shake",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02025dacb513f2b67e6432d56e"
    },
    {
      placing: 68,
      track: "Yeah the Girls",
      artist: "Fisher featuring Meryll",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0226c393e730b90534219d33fc"
    },
    {
      placing: 69,
      track: "Maybe",
      artist: "Machine Gun Kelly featuring Bring Me the Horizon",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02b4683d9ac3c5f14a71523c84"
    },
    {
      placing: 70,
      track: "Only Wanna Be with You",
      artist: "Amy Shark",
      imageSource: "https://i.scdn.co/image/ab67616d00001e029412b979a655de70cd90c374"
    },
    {
      placing: 71,
      track: "Show Business",
      artist: "Hilltop Hoods featuring Eamon",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02bca100ecf2eae93bd1fe956f"
    },
    {
      placing: 72,
      track: "Static",
      artist: "Steve Lacy",
      imageSource: "https://i.scdn.co/image/ab67616d00001e025cc2b8beee1bae039987f2d2"
    },
    {
      placing: 73,
      track: "No Choice",
      artist: "Tame Impala",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0222b9cdd0c4ce8cee5857f6a4"
    },
    {
      placing: 74,
      track: "She's All I Wanna Be",
      artist: "Tate McRae",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02f7916a35ffdd6cb90bbbdf2f"
    },
    {
      placing: 75,
      track: "Bothers Me",
      artist: "Spacey Jane",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02cc3d962daa7e5d11efc6563e"
    },
    {
      placing: 76,
      track: "Jimmy Cooks",
      artist: "Drake featuring 21 Savage",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0222dfcfcc0742f339986d20cb"
    },
    {
      placing: 77,
      track: "There'd Better Be a Mirrorball",
      artist: "Arctic Monkeys",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02e3244b6267bfca61c83b0678"
    },
    {
      placing: 78,
      track: "Kamikaze",
      artist: "Violent Soho",
      imageSource: "https://i.scdn.co/image/ab67616d00001e023eb9261bb5db1ada76ab43b1"
    },
    {
      placing: 79,
      track: "Body Paint",
      artist: "Arctic Monkeys",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02b39b65c9df5e0bf0c04c0a6a"
    },
    {
      placing: 80,
      track: "Walkin",
      artist: "Denzel Curry",
      imageSource: "https://i.scdn.co/image/ab67616d00001e025b924aa009b61b6b9d831eec"
    },
    {
      placing: 81,
      track: "Beach Boy",
      artist: "Benee",
      imageSource: "https://i.scdn.co/image/ab67616d00001e023404bf31c77aeb8d5db7a817"
    },
    {
      placing: 82,
      track: "Is This What It Feels Like to Feel Like This?",
      artist: "The Wombats",
      imageSource: "https://i.scdn.co/image/ab67616d00001e020cef092e364bb51769be158b"
    },
    {
      placing: 83,
      track: "Handful",
      artist: "Beddy Rays",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0287e2608146e6006c334f1b8a"
    },
    {
      placing: 84,
      track: "The 30th",
      artist: "Billie Eilish",
      imageSource: "https://i.scdn.co/image/ab67616d00001e027a4781629469bb83356cd318"
    },
    {
      placing: 85,
      track: "Everybody's Saying Thursday's the Weekend",
      artist: "DMA's",
      imageSource: "https://i.scdn.co/image/ab67616d00001e026eb934e2122aeba5062bc4a2"
    },
    {
      placing: 86,
      track: "Glitch",
      artist: "Parkway Drive",
      imageSource: "https://i.scdn.co/image/ab67616d00001e027503e94c39c17a0838a05856"
    },
    {
      placing: 87,
      track: "GTFO",
      artist: "Genesis Owusu",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02076c5e0639fe91be474cdcbd"
    },
    {
      placing: 88,
      track: "Oh Caroline",
      artist: "The 1975",
      imageSource: "https://i.scdn.co/image/ab67616d00001e021f44db452a68e229650a302c"
    },
    {
      placing: 89,
      track: "Show Me Your God",
      artist: "The Amity Affliction",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0229af62eafdba762a2270ef47"
    },
    {
      placing: 90,
      track: "I Don't Wanna Do Nothing Forever",
      artist: "The Smith Street Band",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0219f9dcf1c43c489bd2498817"
    },
    {
      placing: 91,
      track: "Free",
      artist: "Florence and the Machine",
      imageSource: "https://i.scdn.co/image/ab67616d00001e025fd9e21618182df7d70b2186"
    },
    {
      placing: 92,
      track: "Alright",
      artist: "Sam Fender",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02cd7206342e1716a0fafd1ab6"
    },
    {
      placing: 93,
      track: "Holy Moley",
      artist: "Lime Cordiale and Idris Elba",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02fb565a9a7858330fa4d7017a"
    },
    {
      placing: 94,
      track: "Lupa",
      artist: "King Stingray",
      imageSource: "https://i.scdn.co/image/ab67616d00001e025776c0c1e8490d957746791b"
    },
    {
      placing: 95,
      track: "Evergreen (You Didn't Deserve Me at All)",
      artist: "Omar Apollo",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0290cf5d1ccfca2beb86149a19"
    },
    {
      placing: 96,
      track: "Waste of Space",
      artist: "G Flip",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0215628e7293a4ae99aa3274d9"
    },
    {
      placing: 97,
      track: "Betty (Get Money)",
      artist: "Yung Gravy",
      imageSource: "https://i.scdn.co/image/ab67616d00001e02633674a4e5072dabf5173069"
    },
    {
      placing: 98,
      track: "Bad Memories",
      artist: "Meduza and James Carter featuring Elley Duhé and Fast Boy",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0220abd13bf81d8ee406827c38"
    },
    {
      placing: 99,
      track: "Sidelines",
      artist: "Phoebe Bridgers",
      imageSource: "https://i.scdn.co/image/ab67616d00001e0223a9bfabf54914f791bea535"
    },
    {
      placing: 100,
      track: "Danielle (Smile on My Face)",
      artist: "Fred Again",
      imageSource: "https://i.scdn.co/image/ab67616d00001e023df0f9ce9536ac74c7aa4b98"
    }
  ],
  2021: [
    {
      placing: 1,
      artist: 'The Wiggles',
      track: 'Elephant [triple j Like A Version 2021]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024c9b248c07bc361326bb22a7'
    },
    {
      placing: 2,
      artist: 'The Kid LAROI & Justin Bieber',
      track: 'Stay',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0241e31d6ea1d493dd77933ee5'
    },
    {
      placing: 3,
      artist: 'Spacey Jane',
      track: 'Lots Of Nothing',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022d8a9990750ae0c8d61b19ab'
    },
    {
      placing: 4,
      artist: 'Olivia Rodrigo',
      track: 'good 4 u',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a91c10fe9472d9bd89802e5a'
    },
    {
      placing: 5,
      artist: 'Billie Eilish',
      track: 'Happier Than Ever',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022a038d3bf875d23e4aeaa84e'
    },
    {
      placing: 6,
      artist: 'Gang Of Youths',
      track: 'The Angel Of 8th Ave.',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02606d5328452b81683a125450'
    },
    {
      placing: 7,
      artist: 'Doja Cat',
      track: 'Kiss Me More [Ft. SZA]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d28939d0b5385b55c5e4bce2'
    },
    {
      placing: 8,
      artist: 'Lil Nas X & Jack Harlow',
      track: 'INDUSTRY BABY',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ba26678947112dff3c3158bf'
    },
    {
      placing: 9,
      artist: 'RÜFÜS DU SOL',
      track: 'On My Knees',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b0ce5cacc1047fe929e8f7e7'
    },
    {
      placing: 10,
      artist: 'Lil Nas X',
      track: 'MONTERO (Call Me By Your Name)',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02be82673b5f79d9658ec0a9fd'
    },
    {
      placing: 11,
      artist: 'Tom Cardy',
      track: 'H.Y.C.Y.BH',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0278f5c629df4c0dfe7425b5e6'
    },
    {
      placing: 12,
      artist: 'Spacey Jane',
      track: 'Lunchtime',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02039dc8870d655be2008e14b0'
    },
    {
      placing: 13,
      artist: 'Olivia Rodrigo',
      track: 'drivers license',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a91c10fe9472d9bd89802e5a'
    },
    {
      placing: 14,
      artist: 'Lime Cordiale & Idris Elba',
      track: 'Apple Crumble',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025d7fb51583e28093ac160ec7'
    },
    {
      placing: 15,
      artist: 'Vance Joy',
      track: 'Missing Piece',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021e52f02844fd4e9954680869'
    },
    {
      placing: 16,
      artist: 'Sam Fender',
      track: 'Seventeen Going Under',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025918af42c697829fd84de59f'
    },
    {
      placing: 17,
      artist: 'Tom Cardy',
      track: 'Mixed Messages',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0278f5c629df4c0dfe7425b5e6'
    },
    {
      placing: 18,
      artist: 'The Jungle Giants',
      track: 'Love Signs',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026b012ec8d97287ac2088c2f2'
    },
    {
      placing: 19,
      artist: 'RÜFÜS DU SOL',
      track: 'Alive',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02595fa0556b8cd3917949ca1b'
    },
    {
      placing: 20,
      artist: 'Ocean Alley',
      track: 'Touch Back Down',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d8a690e0339239780e1789bd'
    },
    {
      placing: 21,
      artist: 'Ball Park Music',
      track: 'Sunscreen',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02cf3246833cc4cd994822087e'
    },
    {
      placing: 22,
      artist: 'SZA',
      track: 'Good Days',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e023097b1375ab17ae5bf302a0a'
    },
    {
      placing: 23,
      artist: 'Glass Animals',
      track: "I Don't Wanna Talk (I Just Wanna Dance)",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0220154c1edea81bf39988e1a9'
    },
    {
      placing: 24,
      artist: 'bbno$',
      track: 'edamame [Ft. Rich Brian]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027159a8cde4c10aea0aca75e2'
    },
    {
      placing: 25,
      artist: 'RÜFÜS DU SOL',
      track: 'Next To Me',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f1f76fc1b5ab347fcba249ca'
    },
    {
      placing: 26,
      artist: 'Lorde',
      track: 'Solar Power',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0236615a0a60523dd62135ab3a'
    },
    {
      placing: 27,
      artist: 'DOPE LEMON',
      track: 'Rose Pink Cadillac',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ffa89e501a54fc2c0cb127c0'
    },
    {
      placing: 28,
      artist: 'Amyl and The Sniffers',
      track: 'Hertz',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0232827d33802a481c739e4d24'
    },
    {
      placing: 29,
      artist: 'Lil Nas X',
      track: 'THATS WHAT I WANT',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02be82673b5f79d9658ec0a9fd'
    },
    {
      placing: 30,
      artist: 'Spacey Jane',
      track: 'Here Comes The Sun [triple j Like A Version 2021]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02039707261393ed8af2dbc0cb'
    },
    {
      placing: 31,
      artist: 'Doja Cat',
      track: 'Need To Know',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024df3245f26298a1579ecc321'
    },
    {
      placing: 32,
      artist: 'The Wombats',
      track: "If You Ever Leave, I'm Coming With You",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c2aeb78b917f5d8b34f13746'
    },
    {
      placing: 33,
      artist: 'Olivia Rodrigo',
      track: 'deja vu',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a91c10fe9472d9bd89802e5a'
    },
    {
      placing: 34,
      artist: 'Doja Cat',
      track: 'Get Into It (Yuh)',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024df3245f26298a1579ecc321'
    },
    {
      placing: 35,
      artist: 'Peach PRC',
      track: 'Josh',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ec3a0e3be5a5dbef1ee6edcc'
    },
    {
      placing: 36,
      artist: 'WILLOW',
      track: 't r a n s p a r e n t s o u l [Ft. Travis Barker]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e76af696460a8b3aa26bf623'
    },
    {
      placing: 37,
      artist: 'Doja Cat',
      track: 'Woman',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024df3245f26298a1579ecc321'
    },
    {
      placing: 38,
      artist: 'The Rubens',
      track: 'Masterpiece',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025dd892f0dd951a65d233f41d'
    },
    {
      placing: 39,
      artist: 'Middle Kids',
      track: 'Stacking Chairs',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02618b0609aa6f26935c76f300'
    },
    {
      placing: 40,
      artist: 'girl in red',
      track: 'serotonin',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021e84dffc6dfde82a3e3a91d2'
    },
    {
      placing: 41,
      artist: 'Lime Cordiale & Idris Elba',
      track: "What's Not To Like",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02efb542cb8312bd0b5b63ee38'
    },
    {
      placing: 42,
      artist: 'Peking Duk',
      track: 'Chemicals',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026192393b3ec1f67ef3d8a88e'
    },
    {
      placing: 43,
      artist: 'Bring Me The Horizon',
      track: 'DiE4u',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022391bf91fd75328b113d9126'
    },
    {
      placing: 44,
      artist: 'Kanye West',
      track: 'Hurricane',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02cad190f1a73c024e5a40dddd'
    },
    {
      placing: 45,
      artist: 'Wet Leg',
      track: 'Chaise Longue',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ea1126a33b0fb88e79915262'
    },
    {
      placing: 46,
      artist: 'King Stingray',
      track: 'Get Me Out',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0276208de48cc2b266abfa8d4b'
    },
    {
      placing: 47,
      artist: 'Bruno Mars, Anderson .Paak & Silk Sonic',
      track: 'Leave The Door Open',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02fcf75ead8a32ac0020d2ce86'
    },
    {
      placing: 48,
      artist: 'ACRAZE',
      track: 'Do It To It [Ft. Cherish]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0284d413b195aac8a69cc6e5c6'
    },
    {
      placing: 49,
      artist: 'Dune Rats',
      track: 'UP',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02490fd67888005c6aa0604b8c'
    },
    {
      placing: 50,
      artist: 'Kanye West',
      track: 'Jail',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02cad190f1a73c024e5a40dddd'
    },
    {
      placing: 51,
      artist: 'The Rions',
      track: 'Night Light',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c4ee6833f3ce06dd4edd9749'
    },
    {
      placing: 52,
      artist: 'Cardi B',
      track: 'Up',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d619b8baab0619516bb53804'
    },
    {
      placing: 53,
      artist: 'The Weeknd',
      track: 'Take My Breath',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028a4714ec877048616d0fc01e'
    },
    {
      placing: 54,
      artist: 'Ocean Alley',
      track: 'Breathe/Comfortably Numb/Money [triple j Like A Version 2021]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022f9854fd472f3d125cd5b5c8'
    },
    {
      placing: 55,
      artist: 'Skegss',
      track: 'Valhalla',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02be411944d1eb5db4655046e7'
    },
    {
      placing: 56,
      artist: 'King Stingray',
      track: 'Milkumana',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028500b772ebc48e17ed62392a'
    },
    {
      placing: 57,
      artist: 'Gang of Youths',
      track: 'the man himself',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02606d5328452b81683a125450'
    },
    {
      placing: 58,
      artist: 'Ruby Fields',
      track: 'Kitchen',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022406cce190df7d26eb2e3909'
    },
    {
      placing: 59,
      artist: 'Machine Gun Kelly',
      track: 'love race [Ft. Kellin Quinn]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021568ddeeb672c8229f342e26'
    },
    {
      placing: 60,
      artist: 'Olivia Rodrigo',
      track: 'brutal',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a91c10fe9472d9bd89802e5a'
    },
    {
      placing: 61,
      artist: 'Dom Dolla',
      track: 'Pump The Brakes',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e2f062d566e914307bbfbc03'
    },
    {
      placing: 62,
      artist: 'Lorde',
      track: 'Mood Ring',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0236615a0a60523dd62135ab3a'
    },
    {
      placing: 63,
      artist: 'Lana Del Rey',
      track: 'Chemtrails Over The Country Club',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ca929c6e766cb8591a286e0d'
    },
    {
      placing: 64,
      artist: 'G Flip',
      track: 'Queen [Ft. mxmtoon]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e020702fe11b2450029f5ffbbef'
    },
    {
      placing: 65,
      artist: 'Luude',
      track: 'Down Under [Ft. Colin Hay]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02401607d8dec0a6095d944249'
    },
    {
      placing: 66,
      artist: 'Tame Impala',
      track: 'A Girl Like You [triple j Like A Version 2020]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0240932deb0d91cc1acaf1bbe4'
    },
    {
      placing: 67,
      artist: 'Olivia Rodrigo',
      track: 'traitor',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a91c10fe9472d9bd89802e5a'
    },
    {
      placing: 68,
      artist: 'The Jungle Giants',
      track: 'Treat You Right',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026b012ec8d97287ac2088c2f2'
    },
    {
      placing: 69,
      artist: 'Sly Withers',
      track: 'Clarkson',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0290364fb07b6df0e6cc385f19'
    },
    {
      placing: 70,
      artist: 'The Amity Affliction',
      track: 'Like Love',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02538fbc5e24f234e4a16862e0'
    },
    {
      placing: 71,
      artist: 'Halsey',
      track: "I Am Not A Woman, I'm A God",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024765be0831c4a9fb677e9853'
    },
    {
      placing: 72,
      artist: 'Vera Blue',
      track: 'Temper',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d4bfa3ac3772cc2e63022004'
    },
    {
      placing: 73,
      artist: 'Lizzo',
      track: 'Rumors [Ft. Cardi B]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02324290e5b99a797b99e247bb'
    },
    {
      placing: 74,
      artist: 'Tones And I',
      track: 'Cloudy Day',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02dc2effc1ba5c065df8f2a3dd'
    },
    {
      placing: 75,
      artist: 'Genesis Owusu',
      track: 'Gold Chains',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a5be3902609e482a1a9eec9c'
    },
    {
      placing: 76,
      artist: 'The Terrys',
      track: 'Our Paradise',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0248ab7752e27031624316afe7'
    },
    {
      placing: 77,
      artist: 'Tyler, The Creator',
      track: 'WUSYANAME [Ft. YoungBoy Never Broke Again/Ty Dolla $ign]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02894ceceecfd0f4ae5a5dbd66'
    },
    {
      placing: 78,
      artist: 'SZA',
      track: 'I Hate U',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022cb5c3a1013e6be7084da957'
    },
    {
      placing: 79,
      artist: 'Dave',
      track: 'Clash [Ft. Stormzy]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c9d4463f02ee31ed1e96413a'
    },
    {
      placing: 80,
      artist: 'Billie Eilish',
      track: 'Your Power',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022a038d3bf875d23e4aeaa84e'
    },
    {
      placing: 81,
      artist: 'Lorde',
      track: 'Stoned At The Nail Salon',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0236615a0a60523dd62135ab3a'
    },
    {
      placing: 82,
      artist: 'Kanye West',
      track: 'Praise God',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02cad190f1a73c024e5a40dddd'
    },
    {
      placing: 83,
      artist: 'Doja Cat & The Weeknd',
      track: 'You Right',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024df3245f26298a1579ecc321'
    },
    {
      placing: 84,
      artist: 'Teenage Joans',
      track: 'Wine',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025e064f70b8d98bab4b67e69b'
    },
    {
      placing: 85,
      artist: 'The Buoys',
      track: 'Lie To Me Again',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d8261424bbcc57449ec461d6'
    },
    {
      placing: 86,
      artist: 'FISHER',
      track: 'Just Feels Tight',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02854ede8a6938609649144743'
    },
    {
      placing: 87,
      artist: 'Holy Holy',
      track: 'The Aftergone [Ft. CLEWS]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025def91b74d967fa8bd4a6805'
    },
    {
      placing: 88,
      artist: 'Baker Boy',
      track: 'My Mind [Ft. G Flip]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0238e46c13599d39a05352d8e3'
    },
    {
      placing: 89,
      artist: 'Jungle',
      track: 'Keep Moving',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027fa67f6ecc8e28965727ceff'
    },
    {
      placing: 90,
      artist: 'Hayden James',
      track: 'Waiting For Nothing [Ft. Yaeger]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029e17eacc2a47a3bd7b80263d'
    },
    {
      placing: 91,
      artist: 'Telenova',
      track: 'Bones',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a7f9704c5df007f567b46be6'
    },
    {
      placing: 92,
      artist: 'Slowly Slowly',
      track: 'Blueprint',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024664bc6c9dc81c073da7ac15'
    },
    {
      placing: 93,
      artist: 'Baby Keem',
      track: 'family ties [with Kendrick Lamar]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021bfa23b13d0504fb90c37b39'
    },
    {
      placing: 94,
      artist: 'Tyne-James Organ',
      track: 'Sunday Suit',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02962e90cb27797c3dcb029e24'
    },
    {
      placing: 95,
      artist: 'alt-J',
      track: 'U&ME',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029a494f7d8909a6cc4ceb74ac'
    },
    {
      placing: 96,
      artist: 'Client Liaison',
      track: 'House Of Holy',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e023d99e9135a4542017292a776'
    },
    {
      placing: 97,
      artist: 'Kanye West',
      track: 'Moon',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02cad190f1a73c024e5a40dddd'
    },
    {
      placing: 98,
      artist: 'Adam Newling',
      track: 'Sweetness',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02429474f8f8f4d4996ed8e9fa'
    },
    {
      placing: 99,
      artist: 'Ziggy Ramo',
      track: 'Little Things [Ft. Paul Kelly]',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028b74809a7d7c37f6fa89ae15'
    },
    {
      placing: 100,
      artist: 'Holy Holy',
      track: 'How You Been',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025def91b74d967fa8bd4a6805'
    }
  ],
2020: [
  {
    placing: 1,
    artist: 'Glass Animals',
    track: 'Heat Waves',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e029e495fb707973f3390850eea'
  },
  {
    placing: 2,
    artist: 'Spacey Jane',
    track: 'Booster Seat',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e029278c15e5d8564cd8d8527e5'
  },
  {
    placing: 3,
    artist: 'Flume',
    track: 'The Difference [Ft. Toro y Moi]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02a8d74e789b99484e0e169001'
  },
  {
    placing: 4,
    artist: 'Ball Park Music',
    track: 'Cherub',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0273fca5ec2117f82b8784bcde'
  },
  {
    placing: 5,
    artist: 'Tame Impala',
    track: 'Lost In Yesterday',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0258267bd34420a00d5cf83a49'
  },
  {
    placing: 6,
    artist: 'Cardi B',
    track: 'WAP [Ft. Megan Thee Stallion]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0232e98e822cb537ecaaa4e6aa'
  },
  {
    placing: 7,
    artist: 'G Flip',
    track: 'Hyperfine',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02f9becaa9d3ec423b6484cb51'
  },
  {
    placing: 8,
    artist: 'The Jungle Giants',
    track: 'Sending Me Ur Loving',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02de0db0c0646a286a89f7c861'
  },
  {
    placing: 9,
    artist: 'Hilltop Hoods',
    track: "I'm Good?",
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02a8d3c84f750d5fff1745217c'
  },
  {
    placing: 10,
    artist: 'Billie Eilish',
    track: 'Therefore I Am',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e027a152619a7b55bc621d07901'
  },
  {
    placing: 11,
    artist: 'Lime Cordiale',
    track: 'On Our Own',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022ad905ceb12f622bbc5bdafa'
  },
  {
    placing: 12,
    artist: 'Mashd N Kutcher',
    track: 'Get On The Beers [Ft. Dan Andrews]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0262f9abd1582ad5577da6c9ee'
  },
  {
    placing: 13,
    artist: 'Mallrat',
    track: 'Rockstar',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02f38d940f5b9644a7d35eec27'
  },
  {
    placing: 14,
    artist: 'Ocean Alley',
    track: 'Tombstone',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02926fd996b791f44fa54749e5'
  },
  {
    placing: 15,
    artist: 'Spacey Jane',
    track: 'Skin',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e029278c15e5d8564cd8d8527e5'
  },
  {
    placing: 16,
    artist: 'Lime Cordiale',
    track: 'Screw Loose',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022ad905ceb12f622bbc5bdafa'
  },
  {
    placing: 17,
    artist: 'Tame Impala',
    track: 'Is It True',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0258267bd34420a00d5cf83a49'
  },
  {
    placing: 18,
    artist: 'Glass Animals',
    track: 'Tangerine',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02712701c5e263efc8726b1464'
  },
  {
    placing: 19,
    artist: 'Halsey',
    track: 'you should be sad',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e027636e1c9e67eaafc9f49aefd'
  },
  {
    placing: 20,
    artist: 'Lime Cordiale',
    track: 'Addicted To The Sunshine',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022ad905ceb12f622bbc5bdafa'
  },
  {
    placing: 21,
    artist: 'Stace Cadet & KLP',
    track: 'Energy',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022e808d9399b1f9b6d565c3bb'
  },
  {
    placing: 22,
    artist: 'Eves Karydas',
    track: 'Complicated',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02741d0b79082e34c21b3e1d8f'
  },
  {
    placing: 23,
    artist: 'Mac Miller',
    track: 'Good News',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0226b7dd89810cc1a40ada642c'
  },
  {
    placing: 24,
    artist: 'Mac Miller',
    track: 'Blue World',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0226b7dd89810cc1a40ada642c'
  },
  {
    placing: 25,
    artist: 'Lime Cordiale',
    track: 'Reality Check Please',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e026e5b0879511fcfdd98e81e1a'
  },
  {
    placing: 26,
    artist: 'Lime Cordiale',
    track: 'No Plans To Make Plans',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022ad905ceb12f622bbc5bdafa'
  },
  {
    placing: 27,
    artist: 'Skegss',
    track: 'Under The Thunder',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02be411944d1eb5db4655046e7'
  },
  {
    placing: 28,
    artist: 'Spacey Jane',
    track: 'Straightfaced',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e029278c15e5d8564cd8d8527e5'
  },
  {
    placing: 29,
    artist: 'Sycco',
    track: 'Dribble',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0223d8c9e3323707f70069be2d'
  },
  {
    placing: 30,
    artist: 'Birdz',
    track: 'Bagi-la-m Bargan [Ft. Fred Leone]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e026c9316131fddc5dbf66ac0f5'
  },
  {
    placing: 31,
    artist: 'San Cisco',
    track: 'Reasons',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02326fa7ae218b18095522a92f'
  },
  {
    placing: 32,
    artist: "DMA'S",
    track: 'Criminals',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e029f671f52d4cd772b103e1d63'
  },
  {
    placing: 33,
    artist: 'Tame Impala',
    track: 'Breathe Deeper',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0258267bd34420a00d5cf83a49'
  },
  {
    placing: 34,
    artist: 'Amy Shark',
    track: 'Everybody Rise',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e025488144ce2fa23bd91d4725f'
  },
  {
    placing: 35,
    artist: 'The Avalanches',
    track: 'Running Red Lights [Ft. Rivers Cuomo/Pink Siifu]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02b0b3bd1a5f3967705579e6f1'
  },
  {
    placing: 36,
    artist: 'Machine Gun Kelly',
    track: 'forget me too [Ft. Halsey]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e023a1e213a0831228260a55606'
  },
  {
    placing: 37,
    artist: 'The Kid LAROI',
    track: 'SO DONE',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0259adebe50df1ff4bdd92f34c'
  },
  {
    placing: 38,
    artist: 'Bring Me The Horizon',
    track: 'Parasite Eve',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0286bd44e4b3e2eb335c17c625'
  },
  {
    placing: 39,
    artist: 'Juice WRLD',
    track: 'Righteous',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e023e0698e4ae5ffb82a005aeeb'
  },
  {
    placing: 40,
    artist: 'Juice WRLD',
    track: 'Come & Go [Ft. Marshmello]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02fbc71c99f9c1296c56dd51b6'
  },
  {
    placing: 41,
    artist: 'The Smith Street Band',
    track: 'I Still Dream About You',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02a20af1536f9c9fe8edea86c6'
  },
  {
    placing: 42,
    artist: 'Jack Harlow',
    track: 'WHATS POPPIN',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0205a448540b069450ccfba889'
  },
  {
    placing: 43,
    artist: 'Ziggy Alberts',
    track: 'Together',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02ee8450d3921ca069453ca5a5'
  },
  {
    placing: 44,
    artist: 'G Flip',
    track: 'You & I',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e020c2c97099fd6a637ed0aa4a4'
  },
  {
    placing: 45,
    artist: 'Ruel',
    track: 'as long as you care',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e025e2c5053282f63f4e71cafb4'
  },
  {
    placing: 46,
    artist: 'Tash Sultana',
    track: 'Pretty Lady',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02d2b8a729b9e2f630f10585c0'
  },
  {
    placing: 47,
    artist: 'Architects',
    track: 'Animals',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02c78b2edbc9407cea20ccfb9f'
  },
  {
    placing: 48,
    artist: 'Headie One',
    track: "Ain't It Different [Ft. AJ Tracey/Stormzy]",
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02b7aaedf2f728d36f143c5377'
  },
  {
    placing: 49,
    artist: 'Juice WRLD',
    track: 'Wishing Well',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e023e0698e4ae5ffb82a005aeeb'
  },
  {
    placing: 50,
    artist: 'Peking Duk & The Wombats',
    track: 'Nothing To Love About Love',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02189184bc9d3656b44a077727'
  },
  {
    placing: 51,
    artist: 'Glass Animals',
    track: 'Your Love (Déjà Vu)',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02712701c5e263efc8726b1464'
  },
  {
    placing: 52,
    artist: "DMA'S",
    track: 'The Glow',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e029f671f52d4cd772b103e1d63'
  },
  {
    placing: 53,
    artist: 'The Weeknd',
    track: 'In Your Eyes',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e028863bc11d2aa12b54f5aeb36'
  },
  {
    placing: 54,
    artist: 'Eiffel 65',
    track: 'Blue [Flume Remix]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02fab5eacd2aae13133a3f82f9'
  },
  {
    placing: 55,
    artist: 'Alex the Astronaut',
    track: "I Think You're Great",
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02c0f8d7c6e7e0d342bbd40af1'
  },
  {
    placing: 56,
    artist: 'San Cisco',
    track: 'On The Line',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02326fa7ae218b18095522a92f'
  },
  {
    placing: 57,
    artist: 'Beddy Rays',
    track: 'Sobercoaster',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0273ad4e3a837846fdb7265873'
  },
  {
    placing: 58,
    artist: 'Tones And I',
    track: 'Fly Away',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02dc2effc1ba5c065df8f2a3dd'
  },
  {
    placing: 59,
    artist: 'Joji',
    track: 'Gimme Love',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0253f6fa0d2589c6a7174f4b81'
  },
  {
    placing: 60,
    artist: 'The Kid LAROI',
    track: 'GO [Ft. Juice WRLD]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02b44eea2092f7f4ba202f0764'
  },
  {
    placing: 61,
    artist: 'Ruby Fields',
    track: 'Pretty Grim',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022406cce190df7d26eb2e3909'
  },
  {
    placing: 62,
    artist: 'Joji',
    track: 'Run',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0253f6fa0d2589c6a7174f4b81'
  },
  {
    placing: 63,
    artist: 'Ball Park Music',
    track: 'Day & Age',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022e8ed79e177ff6011076f5f0'
  },
  {
    placing: 64,
    artist: 'The Amity Affliction',
    track: 'Soak Me In Bleach',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e026b0927017b909a2670e3cf01'
  },
  {
    placing: 65,
    artist: 'Amy Shark',
    track: "C'MON [Ft. Travis Barker]",
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02008d18b97c846126d1637f64'
  },
  {
    placing: 66,
    artist: 'Skegss',
    track: 'Fantasising',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02e38c0fb620b6c0c52d66ef01'
  },
  {
    placing: 67,
    artist: 'Doja Cat',
    track: 'Boss Bitch',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0210356a0e81371e6644cb1371'
  },
  {
    placing: 68,
    artist: 'Vera Blue',
    track: 'Lie To Me',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02b4d01d5d50810e9d7ef79d6f'
  },
  {
    placing: 69,
    artist: 'Hope D',
    track: 'Second',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02b16d26b7cb799876ec62c55f'
  },
  {
    placing: 70,
    artist: 'Chet Faker',
    track: 'Low',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02d4578972b2088dd0b07ef923'
  },
  {
    placing: 71,
    artist: 'Bring Me The Horizon & YUNGBLUD',
    track: 'Obey',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e025149c948fde506624246a684'
  },
  {
    placing: 72,
    artist: 'Ocean Alley',
    track: 'Way Down',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02dbd36036e239e809541b0e5c'
  },
  {
    placing: 73,
    artist: 'Genesis Owusu',
    track: "Don't Need You",
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02a5be3902609e482a1a9eec9c'
  },
  {
    placing: 74,
    artist: 'LAUREL',
    track: 'Scream Drive Faster',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02cf0eda2b571b373e4eae138e'
  },
  {
    placing: 75,
    artist: 'Remi Wolf',
    track: 'Photo ID',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0260a3dfec16ef34ac0b9cfad8'
  },
  {
    placing: 76,
    artist: 'London Grammar',
    track: "Baby It's You",
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0261dffa60577537365b0da405'
  },
  {
    placing: 77,
    artist: 'Sofi Tukker & Gorgon City',
    track: 'House Arrest',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0251a9aa4cbca93270342c7a8f'
  },
  {
    placing: 78,
    artist: 'G Flip',
    track: 'Lady Marmalade [triple j Like A Version 2020]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e020049eda90d7a729a80e4b79d'
  },
  {
    placing: 79,
    artist: 'Billie Eilish',
    track: 'my future',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02fc22b158e52b280e58126778'
  },
  {
    placing: 80,
    artist: 'FISHER',
    track: 'Freaks',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02d4e9153c26eefedbe5ee39b4'
  },
  {
    placing: 81,
    artist: 'Spacey Jane',
    track: 'Weightless',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e029278c15e5d8564cd8d8527e5'
  },
  {
    placing: 82,
    artist: 'The Chats',
    track: 'The Clap',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0274b6bcb1d3be3bc5ce5a5d30'
  },
  {
    placing: 83,
    artist: "Cosmo's Midnight & Ruel",
    track: 'Down For You',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02453016a40f27d7d4c1f89e81'
  },
  {
    placing: 84,
    artist: 'Dominic Fike',
    track: 'Chicken Tenders',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02cfc824b65a3b1755d98a7e23'
  },
  {
    placing: 85,
    artist: 'Dune Rats',
    track: 'Too Tough Terry',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02d19f3ab890b2b9c4096aa38e'
  },
  {
    placing: 86,
    artist: 'Drake',
    track: 'Laugh Now Cry Later [Ft. Lil Durk]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02235f7c58fd21cc0a01c9cecf'
  },
  {
    placing: 87,
    artist: 'Teenage Joans',
    track: 'Three Leaf Clover',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e024ec76f5fd1500613a3908bcf'
  },
  {
    placing: 88,
    artist: 'BRONSON',
    track: 'HEART ATTACK [Ft. lau.ra]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e025590a81aafa9ae9bea42acd7'
  },
  {
    placing: 89,
    artist: 'The Jungle Giants',
    track: 'In Her Eyes',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e026b012ec8d97287ac2088c2f2'
  },
  {
    placing: 90,
    artist: 'Billie Eilish',
    track: 'No Time To Die',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02f7b7174bef6f3fbfda3a0bb7'
  },
  {
    placing: 91,
    artist: 'Bugs',
    track: 'Charlie [triple j Like A Version 2020]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02af02582870aa14cdb66ed92d'
  },
  {
    placing: 92,
    artist: 'Thelma Plum',
    track: 'These Days',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02883503f715244ba704d04fe8'
  },
  {
    placing: 93,
    artist: 'Internet Money',
    track: 'Lemonade [Ft. Gunna/Don Toliver/NAV]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e026a4e32e837d8645324e3192e'
  },
  {
    placing: 94,
    artist: 'Aitch x AJ Tracey',
    track: 'Rain [Ft. Tay Keith]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e026889b9cce35ba7035d236010'
  },
  {
    placing: 95,
    artist: 'Illy',
    track: 'Loose Ends [Ft. G Flip]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e025f9517b8f5dbea0352600899'
  },
  {
    placing: 96,
    artist: 'Hockey Dad',
    track: 'Germaphobe',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022300a0265437c92df29ea437'
  },
  {
    placing: 97,
    artist: 'Stormzy',
    track: 'Audacity [Ft. Headie One]',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e02a1e8b73748ee972a4c22be16'
  },
  {
    placing: 98,
    artist: 'Joji',
    track: 'Your Man',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e0223c552a7a4fdafac02e08c34'
  },
  {
    placing: 99,
    artist: 'Hockey Dad',
    track: 'Itch',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e022300a0265437c92df29ea437'
  },
  {
    placing: 100,
    artist: 'BENEE',
    track: 'Kool',
    imageSource: 'https://i.scdn.co/image/ab67616d00001e024bd20e01d00de4b35b61f5f7'
  }
],
  2019: [
    {
      placing: 1,
      artist: 'Billie Eilish',
      track: 'bad guy',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0250a3147b4edd7701a876c6ce'
    },
    {
      placing: 2,
      artist: 'Flume',
      track: 'Rushing Back {Ft. Vera Blue}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02683d659e308221da35d3c0ca'
    },
    {
      placing: 3,
      artist: 'Mallrat',
      track: 'Charlie',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0270c740a64c51a85e8fb7523e'
    },
    {
      placing: 4,
      artist: 'Tones and I',
      track: 'Dance Monkey',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0238802659d156935ada63c9e3'
    },
    {
      placing: 5,
      artist: 'Denzel Curry',
      track: 'Bulls On Parade {triple j Like A Version 2019}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026b470a1425fd95cc4ce4e991'
    },
    {
      placing: 6,
      artist: 'G Flip',
      track: 'Drink Too Much',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b9b0a6c76d87e3e06d95fe2b'
    },
    {
      placing: 7,
      artist: 'Lime Cordiale',
      track: 'Robbery',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022ad905ceb12f622bbc5bdafa'
    },
    {
      placing: 8,
      artist: 'The Jungle Giants',
      track: 'Heavy Hearted',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b6dafc689c3d5fc6342c08d5'
    },
    {
      placing: 9,
      artist: 'Thelma Plum',
      track: 'Better In Blak',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c130e7e517b6fed1a9b13735'
    },
    {
      placing: 10,
      artist: 'Hilltop Hoods',
      track: 'Exit Sign {Ft. Illy/Ecca Vandal}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0256c049718b5e3590a656fe74'
    },
    {
      placing: 11,
      artist: 'Post Malone',
      track: 'Circles',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029478c87599550dd73bfa7e02'
    },
    {
      placing: 12,
      artist: 'FIDLAR',
      track: 'By Myself',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029173e5f8786d0ff03d7b2cc1'
    },
    {
      placing: 13,
      artist: 'Lime Cordiale',
      track: 'Inappropriate Behaviour',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022ad905ceb12f622bbc5bdafa'
    },
    {
      placing: 14,
      artist: 'Sofi Tukker',
      track: 'Purple Hat',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02818685825c4be7951a2ec4bb'
    },
    {
      placing: 15,
      artist: 'Tones and I',
      track: 'Never Seen The Rain',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b3bb18369a24bad5cba321b0'
    },
    {
      placing: 16,
      artist: 'Billie Eilish',
      track: 'everything i wanted',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f2248cf6dad1d6c062587249'
    },
    {
      placing: 17,
      artist: 'Lime Cordiale',
      track: 'I Touch Myself {triple j Like A Version 2019}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0214751ea9e402c3c2052603e6'
    },
    {
      placing: 18,
      artist: 'Tame Impala',
      track: 'Borderline',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0258267bd34420a00d5cf83a49'
    },
    {
      placing: 19,
      artist: 'BENEE',
      track: 'Glitter',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02447289301b37e205337ddd61'
    },
    {
      placing: 20,
      artist: "DMA'S",
      track: 'Silver',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029f671f52d4cd772b103e1d63'
    },
    {
      placing: 21,
      artist: 'The Chats',
      track: 'Pub Feed',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0274b6bcb1d3be3bc5ce5a5d30'
    },
    {
      placing: 22,
      artist: 'Ruel',
      track: 'Painkiller',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f7406e9cbd7f974e7ddf7b0e'
    },
    {
      placing: 23,
      artist: 'Tyler, The Creator',
      track: 'EARFQUAKE',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027005885df706891a3c182a57'
    },
    {
      placing: 24,
      artist: 'Ocean Alley',
      track: 'Infinity',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02898e7d1d6be8d8a8f954456b'
    },
    {
      placing: 25,
      artist: 'BENEE',
      track: 'Find An Island',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0282f4ec53c54bdd5be4eed4a0'
    },
    {
      placing: 26,
      artist: 'Tones and I',
      track: 'Johnny Run Away',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02de55f06362d3b5a4f0adcbd4'
    },
    {
      placing: 27,
      artist: 'Stormzy',
      track: 'Vossi Bop',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a1e8b73748ee972a4c22be16'
    },
    {
      placing: 28,
      artist: 'Lizzo',
      track: 'Juice',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0206af802e2ea241b6ba1ce0f2'
    },
    {
      placing: 29,
      artist: 'MEDUZA',
      track: 'Piece Of Your Heart {Ft. Goodboys}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c94cdb6001b63d6f8bd44474'
    },
    {
      placing: 30,
      artist: 'Flume',
      track: 'Friends {Ft. Reo Cragun}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021d7dc9072977fd651a68cee7'
    },
    {
      placing: 31,
      artist: 'Skegss',
      track: 'Save It For The Weekend',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e9019a066f253480a186a0f3'
    },
    {
      placing: 32,
      artist: 'Lime Cordiale',
      track: 'Money',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022ad905ceb12f622bbc5bdafa'
    },
    {
      placing: 33,
      artist: 'Dom Dolla',
      track: 'San Frandisco',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026e8eb4b6aa89aedf63f9adfd'
    },
    {
      placing: 34,
      artist: 'Glass Animals',
      track: 'Tokyo Drifting {Ft. Denzel Curry}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029e495fb707973f3390850eea'
    },
    {
      placing: 35,
      artist: 'Billie Eilish',
      track: 'bury a friend',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0250a3147b4edd7701a876c6ce'
    },
    {
      placing: 36,
      artist: 'PNAU',
      track: 'Solid Gold {Ft. Kira Divine/Marques Toliver}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e023b2481e3fef8c241bcac8904'
    },
    {
      placing: 37,
      artist: 'Duke Dumont',
      track: 'Red Light Green Light {Ft. Shaun Ross}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c703a92731a78852ef32b3d2'
    },
    {
      placing: 38,
      artist: 'Ruel',
      track: 'Face To Face',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f7406e9cbd7f974e7ddf7b0e'
    },
    {
      placing: 39,
      artist: 'Catfish and the Bottlemen',
      track: 'Longshot',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02835a9e77dae1c928f871ac73'
    },
    {
      placing: 40,
      artist: 'Halsey',
      track: 'Graveyard',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027636e1c9e67eaafc9f49aefd'
    },
    {
      placing: 41,
      artist: 'Travis Scott',
      track: 'HIGHEST IN THE ROOM',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e42b5fea4ac4c3d6328b622b'
    },
    {
      placing: 42,
      artist: 'Juice WRLD',
      track: 'Robbery',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028b27d35aa2c6dcf99895d664'
    },
    {
      placing: 43,
      artist: 'Tame Impala',
      track: 'It Might Be Time',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0258267bd34420a00d5cf83a49'
    },
    {
      placing: 44,
      artist: 'Baker Boy',
      track: 'Cool As Hell',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0238e46c13599d39a05352d8e3'
    },
    {
      placing: 45,
      artist: 'Denzel Curry',
      track: 'RICKY',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025d46e4578c8e1adad9206480'
    },
    {
      placing: 46,
      artist: 'Dean Lewis',
      track: '7 Minutes',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a787f718fb485b66d6219247'
    },
    {
      placing: 47,
      artist: 'BROCKHAMPTON',
      track: 'SUGAR',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0246f07fa4f28bf824840ddacb'
    },
    {
      placing: 48,
      artist: 'Ziggy Alberts',
      track: 'Intentions (22)',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029c06f6738079a0cff8598681'
    },
    {
      placing: 49,
      artist: 'Ruel',
      track: 'Free Time',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f7406e9cbd7f974e7ddf7b0e'
    },
    {
      placing: 50,
      artist: 'Holy Holy',
      track: 'Teach Me About Dying',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02066b3dc4a643526de5a09904'
    },
    {
      placing: 51,
      artist: 'BENEE',
      track: 'Evil Spider',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02447289301b37e205337ddd61'
    },
    {
      placing: 52,
      artist: 'Tame Impala',
      track: 'Patience',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e023daf1e477cbdcb84220b006a'
    },
    {
      placing: 53,
      artist: 'FISHER',
      track: 'You Little Beauty',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02cb4bef27969e9ca78e1cd041'
    },
    {
      placing: 54,
      artist: 'Ocean Alley',
      track: 'Stained Glass',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ea2b37fa2825db7e6bf4bb3b'
    },
    {
      placing: 55,
      artist: 'Khalid',
      track: 'Talk {Ft. Disclosure}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b361ce46dbadbf8a11081b60'
    },
    {
      placing: 56,
      artist: 'J. Cole',
      track: 'MIDDLE CHILD',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027f37a4001ae7d8be430bd91b'
    },
    {
      placing: 57,
      artist: 'Slowly Slowly',
      track: 'Jellyfish',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02dc55ab68ae4e78a9cd208d92'
    },
    {
      placing: 58,
      artist: 'G Flip',
      track: 'Lover',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b9b0a6c76d87e3e06d95fe2b'
    },
    {
      placing: 59,
      artist: 'Mallrat x Basenji',
      track: "Nobody's Home",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0284f079825ee480d5a6cf3413'
    },
    {
      placing: 60,
      artist: 'Hockey Dad',
      track: 'I Missed Out',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022300a0265437c92df29ea437'
    },
    {
      placing: 61,
      artist: 'Holy Holy',
      track: 'Maybe You Know',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02066b3dc4a643526de5a09904'
    },
    {
      placing: 62,
      artist: 'Bakar',
      track: 'Hell N Back',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b6aa65ea45fef5e36dfe1a45'
    },
    {
      placing: 63,
      artist: 'Halsey',
      track: 'Nightmare',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e020db100d30d1ef9a91aefeabf'
    },
    {
      placing: 64,
      artist: 'George Alice',
      track: 'Circles',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f3cf5d3b39c55f98ec902546'
    },
    {
      placing: 65,
      artist: 'Thelma Plum',
      track: 'Homecoming Queen',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c130e7e517b6fed1a9b13735'
    },
    {
      placing: 66,
      artist: 'G Flip',
      track: 'Stupid',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b9b0a6c76d87e3e06d95fe2b'
    },
    {
      placing: 67,
      artist: 'Billie Eilish',
      track: 'wish you were gay',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0250a3147b4edd7701a876c6ce'
    },
    {
      placing: 68,
      artist: 'Illy',
      track: 'Then What',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02dce2b28a9be99b36e7081d6b'
    },
    {
      placing: 69,
      artist: 'Violent Soho',
      track: 'Vacation Forever',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028c11836da1b1bc7a9e9a722b'
    },
    {
      placing: 70,
      artist: 'E^ST',
      track: 'Talk Deep',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02746244d399c20df51695bdfe'
    },
    {
      placing: 71,
      artist: 'The Weeknd',
      track: 'Blinding Lights',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028863bc11d2aa12b54f5aeb36'
    },
    {
      placing: 72,
      artist: 'Angie McMahon',
      track: 'Pasta',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0209d5426c8b1d28273381af3c'
    },
    {
      placing: 73,
      artist: 'Cub Sport',
      track: 'Party Pill',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d294ebc7a6f45bbc3dbe71b1'
    },
    {
      placing: 74,
      artist: 'Hayden James and NAATIONS',
      track: 'Nowhere To Go',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ab2228f272e4929418a4f55b'
    },
    {
      placing: 75,
      artist: 'Golden Features x The Presets',
      track: 'Paradise',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02bce3e0a0c87aaeafea02b9de'
    },
    {
      placing: 76,
      artist: 'Meg Mac',
      track: 'Something Tells Me',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022fffd154fe7f99a997b55a0c'
    },
    {
      placing: 77,
      artist: 'G Flip',
      track: 'I Am Not Afraid',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b9b0a6c76d87e3e06d95fe2b'
    },
    {
      placing: 78,
      artist: 'Thelma Plum',
      track: 'Not Angry Anymore',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c130e7e517b6fed1a9b13735'
    },
    {
      placing: 79,
      artist: 'Dean Lewis',
      track: 'Stay Awake',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a787f718fb485b66d6219247'
    },
    {
      placing: 80,
      artist: 'Spacey Jane',
      track: 'Good For You',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029278c15e5d8564cd8d8527e5'
    },
    {
      placing: 81,
      artist: 'Flume',
      track: 'Let You Know {Ft. London Grammar}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02eea49bfbb0f213e54f6e6db7'
    },
    {
      placing: 82,
      artist: 'Peking Duk & Jack River',
      track: 'Sugar',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02724dd8fd549dc4d1e92c395b'
    },
    {
      placing: 83,
      artist: 'Alex Lahey',
      track: 'Welcome To The Black Parade {triple j Like A Version 2019}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028a29bce87fdc643ca4001b13'
    },
    {
      placing: 84,
      artist: 'DOPE LEMON',
      track: 'Hey You',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026b53724beba8752b700e20d4'
    },
    {
      placing: 85,
      artist: 'Lana Del Rey',
      track: "Doin' Time",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02879e9318cb9f4e05ee552ac9'
    },
    {
      placing: 86,
      artist: 'Slipknot',
      track: 'Unsainted',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0226f20b4d67c0c7b0f137ce4f'
    },
    {
      placing: 87,
      artist: 'Kanye West',
      track: 'Follow God',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021bb797bbfe2480650b6c2964'
    },
    {
      placing: 88,
      artist: 'Meduza x Becky Hill x Goodboys',
      track: 'Lose Control',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d43c59e52d6a8032a4e27fc4'
    },
    {
      placing: 89,
      artist: 'Sampa the Great',
      track: 'Final Form',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0255179ddb49feab5af9cf1870'
    },
    {
      placing: 90,
      artist: 'Skegss',
      track: 'Here Comes Your Man {triple j Like A Version 2019}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02a63be75e10892033fc206537'
    },
    {
      placing: 91,
      artist: 'Billie Eilish',
      track: 'all the good girls go to hell',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0250a3147b4edd7701a876c6ce'
    },
    {
      placing: 92,
      artist: 'Client Liaison',
      track: 'The Real Thing',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025610a6c500c26ced1301b2cb'
    },
    {
      placing: 93,
      artist: 'Post Malone',
      track: 'Wow.',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029478c87599550dd73bfa7e02'
    },
    {
      placing: 94,
      artist: 'Allday',
      track: 'Protection',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026071159ae3e41d7a1a9fcdc3'
    },
    {
      placing: 95,
      artist: 'Adrian Eagle',
      track: 'A.O.K.',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02bd380b44512df30db2bbf71e'
    },
    {
      placing: 96,
      artist: 'Bring Me The Horizon',
      track: 'Ludens',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ab0b0448520d99ff0aa7b1eb'
    },
    {
      placing: 97,
      artist: 'San Cisco',
      track: 'Skin',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02326fa7ae218b18095522a92f'
    },
    {
      placing: 98,
      artist: 'Baker Boy',
      track: 'Meditjin {Ft. JessB}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0238e46c13599d39a05352d8e3'
    },
    {
      placing: 99,
      artist: "Cosmo's Midnight",
      track: 'C.U.D.I. (Can U Dig It)',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c244c88cbb5e200d886a5d2c'
    },
    {
      placing: 100,
      artist: 'Dune Rats',
      track: 'No Plans',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c54d8403c7445a38d08dd22b'
    }
  ],
  2018: [
    {
      placing: 1,
      artist: 'Ocean Alley',
      track: 'Confidence',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0246329ac2946a9313ff6b52f3'
    },
    {
      placing: 2,
      artist: 'FISHER',
      track: 'Losing It',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c4ccc131831321088462a9e6'
    },
    {
      placing: 3,
      artist: 'Travis Scott',
      track: 'SICKO MODE',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02072e9faef2ef7b6db63834a3'
    },
    {
      placing: 4,
      artist: 'Childish Gambino',
      track: 'This Is America',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0227b61d416f074313d5a35b2f'
    },
    {
      placing: 5,
      artist: 'Amy Shark',
      track: 'I Said Hi',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d8b44ee2b3636fd5e9287eac'
    },
    {
      placing: 6,
      artist: 'Dean Lewis',
      track: 'Be Alright',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0213480e8127119dcb882eb53d'
    },
    {
      placing: 7,
      artist: 'Mallrat',
      track: 'Groceries',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02af91e995c275d845643e791c'
    },
    {
      placing: 8,
      artist: 'Billie Eilish',
      track: "when the party's over",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0250a3147b4edd7701a876c6ce'
    },
    {
      placing: 9,
      artist: 'Ruby Fields',
      track: 'Dinosaurs',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f2407060a63dc05a5bb055da'
    },
    {
      placing: 10,
      artist: 'Ocean Alley',
      track: 'Knees',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0246329ac2946a9313ff6b52f3'
    },
    {
      placing: 11,
      artist: 'Skegss',
      track: 'Up In The Clouds',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02bafd4f895553cf639e2c8950'
    },
    {
      placing: 12,
      artist: 'The Wombats',
      track: 'Turn',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b94b7a3be37618498c01fcda'
    },
    {
      placing: 13,
      artist: 'A$AP Rocky',
      track: 'Praise The Lord (Da Shine) {Ft. Skepta}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0228d1672cb27e1d67dea4b7bb'
    },
    {
      placing: 14,
      artist: 'Wafia',
      track: "I'm Good",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02858b979f90456755ec2a0b50'
    },
    {
      placing: 15,
      artist: 'Hayden James',
      track: 'Just Friends {Ft. Boy Matthews}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ab2228f272e4929418a4f55b'
    },
    {
      placing: 16,
      artist: 'Ocean Alley',
      track: 'Baby Come Back {triple j Like A Version 2018}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024c3cdf1c5b1a20e06ad4d7aa'
    },
    {
      placing: 17,
      artist: 'Billie Eilish',
      track: 'lovely {with Khalid}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028a3f0a3ca7929dea23cd274c'
    },
    {
      placing: 18,
      artist: 'Hockey Dad',
      track: 'Join The Club',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027a7863c850cf05c63e85d5b9'
    },
    {
      placing: 19,
      artist: 'Broods',
      track: 'Peach',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027d45852c60de0b2e046f8ad3'
    },
    {
      placing: 20,
      artist: 'KIAN',
      track: 'Waiting',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e094d40000d1da1a70812602'
    },
    {
      placing: 21,
      artist: 'The Rubens',
      track: 'Never Ever {Ft. Sarah}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026e57a9f81cc78b3353c5c1bf'
    },
    {
      placing: 22,
      artist: 'RÜFÜS DU SOL',
      track: 'Underwater',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c44a36068acb26e65022c3d7'
    },
    {
      placing: 23,
      artist: 'RÜFÜS DU SOL',
      track: 'Treat You Better',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c44a36068acb26e65022c3d7'
    },
    {
      placing: 24,
      artist: 'Hilltop Hoods',
      track: 'Leave Me Lonely',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0256c049718b5e3590a656fe74'
    },
    {
      placing: 25,
      artist: 'Thundamentals',
      track: 'I Miss You',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e5cf23546555cbf85d5420c6'
    },
    {
      placing: 26,
      artist: 'Vera Blue',
      track: 'All The Pretty Girls',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021006f506d95e63cb0297d183'
    },
    {
      placing: 27,
      artist: 'Post Malone & Swae Lee',
      track: 'Sunflower',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e2e352d89826aef6dbd5ff8f'
    },
    {
      placing: 28,
      artist: 'Kendrick Lamar & SZA',
      track: 'All The Stars',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c027ad28821777b00dcaa888'
    },
    {
      placing: 29,
      artist: 'Peking Duk',
      track: 'Fire',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ceef2875aec040ad99eeb3a1'
    },
    {
      placing: 30,
      artist: 'Cub Sport',
      track: 'Sometimes',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d294ebc7a6f45bbc3dbe71b1'
    },
    {
      placing: 31,
      artist: 'Alison Wonderland',
      track: 'Church',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0248362f277592191f882a2b14'
    },
    {
      placing: 32,
      artist: 'The Rubens',
      track: 'God Forgot',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026e57a9f81cc78b3353c5c1bf'
    },
    {
      placing: 33,
      artist: 'Post Malone',
      track: 'Better Now',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b1c4b76e23414c9f20242268'
    },
    {
      placing: 34,
      artist: 'King Princess',
      track: 1950,
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02580625d3d07c5b16238c2e69'
    },
    {
      placing: 35,
      artist: 'Mac Miller',
      track: 'Ladders',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02175c577a61aa13d4fb4b6534'
    },
    {
      placing: 36,
      artist: 'Amy Shark',
      track: 'All Loved Up',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d8b44ee2b3636fd5e9287eac'
    },
    {
      placing: 37,
      artist: 'Ziggy Alberts',
      track: 'Love Me Now',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027256003f8705f348b8180be1'
    },
    {
      placing: 38,
      artist: 'G Flip',
      track: 'About You',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b9b0a6c76d87e3e06d95fe2b'
    },
    {
      placing: 39,
      artist: 'Ball Park Music',
      track: 'The Perfect Life Does Not Exist',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0261623a8f1eb902efdd8be7af'
    },
    {
      placing: 40,
      artist: 'Drake',
      track: 'Nice For What',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f907de96b9a4fbc04accc0d5'
    },
    {
      placing: 41,
      artist: "DMA'S",
      track: 'In The Air',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029dd43a03a596e76ad9821251'
    },
    {
      placing: 42,
      artist: 'Ziggy Alberts',
      track: 'Laps Around The Sun',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027256003f8705f348b8180be1'
    },
    {
      placing: 43,
      artist: 'Drake',
      track: "God's Plan",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02f907de96b9a4fbc04accc0d5'
    },
    {
      placing: 44,
      artist: 'Hilltop Hoods',
      track: 'Clark Griswold {Ft. Adrian Eagle}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0256c049718b5e3590a656fe74'
    },
    {
      placing: 45,
      artist: 'Bring Me The Horizon',
      track: 'MANTRA',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0249e3b7e85829da2fbc68bc46'
    },
    {
      placing: 46,
      artist: 'Billie Eilish',
      track: 'you should see me in a crown',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0250a3147b4edd7701a876c6ce'
    },
    {
      placing: 47,
      artist: 'DZ Deathrays',
      track: 'Like People',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02bda954cbbbd514c573c2e65d'
    },
    {
      placing: 48,
      artist: 'San Cisco',
      track: 'When I Dream',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02326fa7ae218b18095522a92f'
    },
    {
      placing: 49,
      artist: 'Angie McMahon',
      track: 'Missing Me',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0209d5426c8b1d28273381af3c'
    },
    {
      placing: 50,
      artist: 'RÜFÜS DU SOL',
      track: 'No Place',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c44a36068acb26e65022c3d7'
    },
    {
      placing: 51,
      artist: 'Baker Boy',
      track: 'Mr La Di Da Di',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02bac751356493bb84de8b8a21'
    },
    {
      placing: 52,
      artist: 'Khalid',
      track: 'Better',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0260624c0781fd787c9aa4699c'
    },
    {
      placing: 53,
      artist: 'Vance Joy',
      track: "We're Going Home",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029790acd52066ad1e8fec8ed3'
    },
    {
      placing: 54,
      artist: 'Vance Joy',
      track: 'Saturday Sun',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029790acd52066ad1e8fec8ed3'
    },
    {
      placing: 55,
      artist: 'Peking Duk',
      track: 'Wasted',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e3cea68573c668dfb230a1c8'
    },
    {
      placing: 56,
      artist: 'Halsey',
      track: 'Without Me',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c42acc1b86597285c2c79559'
    },
    {
      placing: 57,
      artist: 'The Presets',
      track: 'Martini',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0246fc4fa20347d7ea7b60bef1'
    },
    {
      placing: 58,
      artist: 'Bene',
      track: 'Soaked',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02447289301b37e205337ddd61'
    },
    {
      placing: 59,
      artist: 'Meg Mac',
      track: 'Give Me My Name Back',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026b7f424f2ce7cae99ff53259'
    },
    {
      placing: 60,
      artist: "DMA'S",
      track: 'The End',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029dd43a03a596e76ad9821251'
    },
    {
      placing: 61,
      artist: 'Hockey Dad',
      track: 'I Wanna Be Everybody',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027a7863c850cf05c63e85d5b9'
    },
    {
      placing: 62,
      artist: 'G Flip',
      track: 'Killing My Time',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b9b0a6c76d87e3e06d95fe2b'
    },
    {
      placing: 63,
      artist: 'BROCKHAMPTON',
      track: 'BOOGIE',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ef51817e6a6563b6f7ce5872'
    },
    {
      placing: 64,
      artist: 'Middle Kids',
      track: 'Mistake',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02190f4a5269b2542f1edff147'
    },
    {
      placing: 65,
      artist: 'Methyl Ethel',
      track: 'Scream Whole',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02be08b908f873cbb83150e135'
    },
    {
      placing: 66,
      artist: 'Flight Facilities',
      track: 'Need You {Ft. NIKA}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027b1aa1e767ed1986dc6217e4'
    },
    {
      placing: 67,
      artist: 'Anderson .Paak',
      track: 'Tints {Ft. Kendrick Lamar}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e028c309d5c3c4ad7b606ebece6'
    },
    {
      placing: 68,
      artist: 'benny blanco',
      track: 'Eastside {Ft. Halsey/Khalid}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02393b66a0c4eb0c35d0c7fc0b'
    },
    {
      placing: 69,
      artist: 'The Wombats',
      track: 'Cheetah Tongue',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b94b7a3be37618498c01fcda'
    },
    {
      placing: 70,
      artist: 'Mallrat',
      track: 'UFO {Ft. Allday}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02af91e995c275d845643e791c'
    },
    {
      placing: 71,
      artist: 'Skegss',
      track: 'Smogged Out',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02bafd4f895553cf639e2c8950'
    },
    {
      placing: 72,
      artist: 'Nothing But Thieves',
      track: 'What Can I Do If The Fire Goes Out {triple j Like A Version 2018}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e023433fa464c9b2206493c88ef'
    },
    {
      placing: 73,
      artist: 'Hockey Dad',
      track: 'Sweet Release',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e027a7863c850cf05c63e85d5b9'
    },
    {
      placing: 74,
      artist: 'King Princess',
      track: 'Pussy Is God',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e020b73f9c0b28314ae14ad9e20'
    },
    {
      placing: 75,
      artist: 'Kira Puru',
      track: 'Molotov',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0257d734a24dec653e581043b0'
    },
    {
      placing: 76,
      artist: 'Polish Club',
      track: 'Clarity',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024066785fc751cc3d29cff00e'
    },
    {
      placing: 77,
      artist: 'CHVRCHES',
      track: 'Miracle',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e023770bbc899e5a5a0f81504d8'
    },
    {
      placing: 78,
      artist: 'Hayden James',
      track: 'Better Together {Ft. Running Touch}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ab2228f272e4929418a4f55b'
    },
    {
      placing: 79,
      artist: 'Thelma Plum',
      track: 'Clumsy Love',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c130e7e517b6fed1a9b13735'
    },
    {
      placing: 80,
      artist: 'Mark Ronson',
      track: 'Nothing Breaks Like A Heart {Ft. Miley Cyrus}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02ff500d1042accb8d4cee1c6c'
    },
    {
      placing: 81,
      artist: 'Jack River',
      track: 'Ballroom',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02bb5b798c28c037a362b37dd8'
    },
    {
      placing: 82,
      artist: 'Florence + The Machine',
      track: 'Hunger',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d36d0f06096fa07f18b8e8a2'
    },
    {
      placing: 83,
      artist: 'BROCKHAMPTON',
      track: '1999 WILDFIRE',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021f807d30b5db720d1938a7ba'
    },
    {
      placing: 84,
      artist: 'Tash Sultana',
      track: 'Cigarettes',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e025a8cb6a1b9f801c742f47672'
    },
    {
      placing: 85,
      artist: 'Anderson .Paak',
      track: "Bubblin'",
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026357eef63bcab4f5b551b216'
    },
    {
      placing: 86,
      artist: 'Lime Cordiale',
      track: 'Dirt Cheap',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e022ad905ceb12f622bbc5bdafa'
    },
    {
      placing: 87,
      artist: 'Ruel',
      track: 'Younger',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02392a8d959bae5d7c22e1a3f5'
    },
    {
      placing: 88,
      artist: 'WAAX',
      track: 'Labrador',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d687299c1c747b9b10aa6a9a'
    },
    {
      placing: 89,
      artist: 'Ruel',
      track: 'Dazed & Confused {Prod. M-Phazes}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e283e53690ec01436b11bdf2'
    },
    {
      placing: 90,
      artist: 'Post Malone',
      track: 'Psycho {Ft. Ty Dolla $ign}',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e026e0de7b30452549aadec8b07'
    },
    {
      placing: 91,
      artist: 'A$AP Rocky',
      track: 'Sundress',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e021ae967e4a02c7a39eb3c189b'
    },
    {
      placing: 92,
      artist: 'Thundamentals',
      track: 'Everybody But You',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02e5cf23546555cbf85d5420c6'
    },
    {
      placing: 93,
      artist: 'Trophy Eyes',
      track: 'You Can Count On Me',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e024605398b788f401f5ea95d7b'
    },
    {
      placing: 94,
      artist: 'The Amity Affliction',
      track: 'Ivy (Doomsday)',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02b7d00bebdf6300a90ee836e5'
    },
    {
      placing: 95,
      artist: 'Arctic Monkeys',
      track: 'Four Out Of Five',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0257d2b6cd196a517e74f3cd14'
    },
    {
      placing: 96,
      artist: 'Odette',
      track: 'Take It To The Heart',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02d6c1fc498c44d13b41a7bbe5'
    },
    {
      placing: 97,
      artist: "DMA'S",
      track: 'Do I Need You Now?',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e029dd43a03a596e76ad9821251'
    },
    {
      placing: 98,
      artist: 'Kanye West',
      track: 'Ghost Town',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e020cd942c1a864afa4e92d04f2'
    },
    {
      placing: 99,
      artist: 'YUNGBLUD',
      track: 'Polygraph Eyes',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e02c783f6b606da7235a9c79dd3'
    },
    {
      placing: 100,
      artist: 'Ocean Alley',
      track: 'Happy Sad',
      imageSource: 'https://i.scdn.co/image/ab67616d00001e0246329ac2946a9313ff6b52f3'
    }
  ]
}

const recentlyPlayedTripleJ = [
    {
        "entity": "Play",
        "arid": "mprKGjOENr",
        "played_time": "2022-09-01T00:04:18+00:00",
        "service_id": "triplej",
        "recording": {
            "entity": "Recording",
            "arid": "mtVkPpeO6k",
            "title": "Bona",
            "metadata": null,
            "description": null,
            "duration": 158,
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maAznN1BzW",
                    "name": "Sampa the Great",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlo59m3Wq5",
                            "url": "http://musicbrainz.org/artist/7eed2339-2e68-4ab6-abf7-aa96b94bd6e0",
                            "id_component": "7eed2339-2e68-4ab6-abf7-aa96b94bd6e0",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                }
            ],
            "releases": [
                {
                    "entity": "Release",
                    "arid": "mrOAJ3xvG5",
                    "title": "As Above, So Below",
                    "format": null,
                    "artwork": [
                        {
                            "entity": "Artwork",
                            "arid": "mi2ewKbLKe",
                            "url": "https://www.abc.net.au/triplej/albums/66322/covers/original.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 1000,
                            "height": 1000,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/AXj8dKdI9w11UkdYO4nMMClGf2c=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/xBTd0nR7uKdCANHHhpGthu34PJM=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/S4yJLr9ryXKhgdlUVKbPEam91c8=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/6ydwnzg0iJRHgFTzIAip9YX4D_A=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 580,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/XjWI8i76sfMxl-lYJmmAAF5EiHM=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 940,
                                    "height": 940,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/_vWl5RH64_yLREi2WawHvKJBM8g=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/8fiblk6Y4G-b-YHTSCmZ7cast1M=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/sF_hGUE50uczjp2QUPkh1mKSgHQ=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/uazBvqkuhZYdL-peq_wQEN09nr0=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 326,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/Y7W21YfEGAE0EdJomiRPrdNhncI=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                                    "width": 940,
                                    "height": 529,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        },
                        {
                            "entity": "Artwork",
                            "arid": "mi2ewKQwKe",
                            "url": "https://www.abc.net.au/triplej/albums/sampathegreat-asabovesobelow.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 1000,
                            "height": 1000,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/Fx1LUGJIMWlmKGQu0OWAgGYWaQc=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/vA7m-wP3IrFjeIfdNw6JofCycWg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/BBU5jygQF1picMPi-R-t-O_r0Cc=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/q3v5vmfsLAQumgmKuhtA6k6NXwg=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 580,
                                    "height": 580,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/wGQuvWUtZ9kitpGEhZBGvxcotP0=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 940,
                                    "height": 940,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/CkTFPsylDJtpR4uTlinj7E-gzRE=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/3MmSpJVBSPM9-ED3ughwtL7VCv8=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/aBO8xdrT4Q40wR0kBeQpRwUaZPU=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/-TSlyX16CzeLx1jms8Uylr8efEQ=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 580,
                                    "height": 326,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/lA-LhMyhU4qRFIfPfmPN_v4eQpk=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                                    "width": 940,
                                    "height": 529,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        }
                    ],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlLy3AB1dk",
                            "url": "http://musicbrainz.org/release-group/5109248b-cdb0-466a-a02f-05e51e2d26dc",
                            "id_component": "5109248b-cdb0-466a-a02f-05e51e2d26dc",
                            "title": "MusicBrainz release-group",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "artists": [
                        {
                            "entity": "Artist",
                            "arid": "maAznN1BzW",
                            "name": "Sampa the Great",
                            "artwork": [],
                            "links": [
                                {
                                    "entity": "Link",
                                    "arid": "mlo59m3Wq5",
                                    "url": "http://musicbrainz.org/artist/7eed2339-2e68-4ab6-abf7-aa96b94bd6e0",
                                    "id_component": "7eed2339-2e68-4ab6-abf7-aa96b94bd6e0",
                                    "title": "MusicBrainz artist",
                                    "mini_synopsis": null,
                                    "short_synopsis": null,
                                    "medium_synopsis": null,
                                    "type": "service",
                                    "provider": "musicbrainz",
                                    "external": true
                                }
                            ],
                            "is_australian": null,
                            "type": "primary",
                            "role": null
                        }
                    ],
                    "record_label": null,
                    "release_year": "2022",
                    "release_album_id": "66322"
                }
            ],
            "artwork": [],
            "links": []
        },
        "release": {
            "entity": "Release",
            "arid": "mrOAJ3xvG5",
            "title": "As Above, So Below",
            "format": null,
            "artwork": [
                {
                    "entity": "Artwork",
                    "arid": "mi2ewKbLKe",
                    "url": "https://www.abc.net.au/triplej/albums/66322/covers/original.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 1000,
                    "height": 1000,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/AXj8dKdI9w11UkdYO4nMMClGf2c=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/xBTd0nR7uKdCANHHhpGthu34PJM=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/S4yJLr9ryXKhgdlUVKbPEam91c8=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/6ydwnzg0iJRHgFTzIAip9YX4D_A=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 580,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/XjWI8i76sfMxl-lYJmmAAF5EiHM=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 940,
                            "height": 940,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/_vWl5RH64_yLREi2WawHvKJBM8g=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/8fiblk6Y4G-b-YHTSCmZ7cast1M=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/sF_hGUE50uczjp2QUPkh1mKSgHQ=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/uazBvqkuhZYdL-peq_wQEN09nr0=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 326,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/Y7W21YfEGAE0EdJomiRPrdNhncI=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F66322%2Fcovers%2Foriginal.jpg",
                            "width": 940,
                            "height": 529,
                            "aspect_ratio": "16x9"
                        }
                    ]
                },
                {
                    "entity": "Artwork",
                    "arid": "mi2ewKQwKe",
                    "url": "https://www.abc.net.au/triplej/albums/sampathegreat-asabovesobelow.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 1000,
                    "height": 1000,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/Fx1LUGJIMWlmKGQu0OWAgGYWaQc=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/vA7m-wP3IrFjeIfdNw6JofCycWg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/BBU5jygQF1picMPi-R-t-O_r0Cc=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/q3v5vmfsLAQumgmKuhtA6k6NXwg=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 580,
                            "height": 580,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/wGQuvWUtZ9kitpGEhZBGvxcotP0=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 940,
                            "height": 940,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/CkTFPsylDJtpR4uTlinj7E-gzRE=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/3MmSpJVBSPM9-ED3ughwtL7VCv8=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/aBO8xdrT4Q40wR0kBeQpRwUaZPU=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/-TSlyX16CzeLx1jms8Uylr8efEQ=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 580,
                            "height": 326,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/lA-LhMyhU4qRFIfPfmPN_v4eQpk=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fsampathegreat-asabovesobelow.jpg",
                            "width": 940,
                            "height": 529,
                            "aspect_ratio": "16x9"
                        }
                    ]
                }
            ],
            "links": [
                {
                    "entity": "Link",
                    "arid": "mlLy3AB1dk",
                    "url": "http://musicbrainz.org/release-group/5109248b-cdb0-466a-a02f-05e51e2d26dc",
                    "id_component": "5109248b-cdb0-466a-a02f-05e51e2d26dc",
                    "title": "MusicBrainz release-group",
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "type": "service",
                    "provider": "musicbrainz",
                    "external": true
                }
            ],
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maAznN1BzW",
                    "name": "Sampa the Great",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlo59m3Wq5",
                            "url": "http://musicbrainz.org/artist/7eed2339-2e68-4ab6-abf7-aa96b94bd6e0",
                            "id_component": "7eed2339-2e68-4ab6-abf7-aa96b94bd6e0",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                }
            ],
            "record_label": null,
            "release_year": "2022",
            "release_album_id": "66322"
        }
    },
    {
        "entity": "Play",
        "arid": "mprJb4OEzr",
        "played_time": "2022-09-01T00:06:58+00:00",
        "service_id": "triplej",
        "recording": {
            "entity": "Recording",
            "arid": "mtOKjXmRdk",
            "title": "Flake",
            "metadata": null,
            "description": null,
            "duration": 226,
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maVYyBp2eq",
                    "name": "FIDLAR",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlBydj1AkV",
                            "url": "http://musicbrainz.org/artist/6910222b-74c2-48fc-8c13-a6b88cc28fc0",
                            "id_component": "6910222b-74c2-48fc-8c13-a6b88cc28fc0",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                }
            ],
            "releases": [
                {
                    "entity": "Release",
                    "arid": "mr2ydaP61X",
                    "title": "Almost Free",
                    "format": null,
                    "artwork": [
                        {
                            "entity": "Artwork",
                            "arid": "miQ9vlLlDP",
                            "url": "https://www.abc.net.au/triplej/albums/57144/covers/original.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 3000,
                            "height": 3000,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/cupIJJEzOsaPRN4Q2-DtAR6FEpo=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/9CtJ8gbuuQPu_yL2L-XsUZj5D9Q=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/IQDDnLVW3aBct6uqIT2QLZ_3wY8=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/jpZqTvRerl_B4es4wF5oA_KEe3w=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 580,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/xc-QVXNEarQo4GKwDF47FN0clE8=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 940,
                                    "height": 940,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/Al_60CNAN4Z5-lH0CcNm7EB5oSQ=/1300x1300/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 1300,
                                    "height": 1300,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/fkPz8NS2k7t2P64QleWoAaIGBr4=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/0EFrfoGwa0GZ8Mw2eOiD50KmpV0=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/3Dr1A14ccpc_Ux35TokOiwDfKb8=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/7BaSf2ez1gCA9JCoChcx5fHQMhI=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 326,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/NOJDNyvbLbNmq66YCzQ_d_J9fek=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 940,
                                    "height": 529,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/t7JR3Tm9RwtOHQbkgiMyhu-TTSE=/1300x731/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                                    "width": 1300,
                                    "height": 731,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        },
                        {
                            "entity": "Artwork",
                            "arid": "miQeRlj5yg",
                            "url": "https://www.abc.net.au/triplej/albums/57744/covers/original.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 1500,
                            "height": 1500,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/fDgELlxFUCDctZoNEA5O-qcHDEo=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/gzkwqLmnlpRv9kmboHHUpFIQ71A=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/v0AkCmqr1WDTADZrFWpyZBlCKtk=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/Qw1zCK2-Td2yci1Ecjp9qn6gPXA=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 580,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/GzGt1GgWI-NQHl7Z0SBRh_9j9-8=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 940,
                                    "height": 940,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/yihmoyhWcxI2m0K6zMzre6QD4DE=/1300x1300/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 1300,
                                    "height": 1300,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/AgjvQvrY_Qj83EUNSeZHwHKD75s=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/rwnLVi1GNtk5r3Wlpy4Zqnr2ia4=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/OuGybmzhKOVneSdEg5WjKLazWIg=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/og96PYbk4zQCo9BQYzhXxaItP1M=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 326,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/Xe_wNIi7tlvGRqdnUOg0Xssf9U8=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 940,
                                    "height": 529,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/l-aIDqiN77kk45miuJo7vqMyhfE=/1300x731/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                                    "width": 1300,
                                    "height": 731,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        },
                        {
                            "entity": "Artwork",
                            "arid": "miKexd6EQe",
                            "url": "https://www.abc.net.au/triplej/albums/57623/covers/original.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 600,
                            "height": 600,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/mQl6omfdCfwOWb8796Q_IhlX4pI=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/plPlVmIcmSMrkJjDQFRQS-RN-7U=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/UE_Rr2pLtRGmd9pqKiy4XoqJopE=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/eutyv-77o5Jbkwl_b6kiFEmKyQQ=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 580,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/7D8fdvbnczKxINJzr62YEIPKssk=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/9Fb6gskIroGgpDXmlOiCWn3zolI=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/YEsne2g0ZpeCtZtBeyi4LQRLwWI=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/fNz2sCF6nWgHgYPwBdIFC-C73fU=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 326,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        }
                    ],
                    "links": [],
                    "artists": [
                        {
                            "entity": "Artist",
                            "arid": "maVYyBp2eq",
                            "name": "FIDLAR",
                            "artwork": [],
                            "links": [
                                {
                                    "entity": "Link",
                                    "arid": "mlBydj1AkV",
                                    "url": "http://musicbrainz.org/artist/6910222b-74c2-48fc-8c13-a6b88cc28fc0",
                                    "id_component": "6910222b-74c2-48fc-8c13-a6b88cc28fc0",
                                    "title": "MusicBrainz artist",
                                    "mini_synopsis": null,
                                    "short_synopsis": null,
                                    "medium_synopsis": null,
                                    "type": "service",
                                    "provider": "musicbrainz",
                                    "external": true
                                }
                            ],
                            "is_australian": null,
                            "type": "primary",
                            "role": null
                        }
                    ],
                    "record_label": null,
                    "release_year": "2019",
                    "release_album_id": "57744"
                }
            ],
            "artwork": [],
            "links": [
                {
                    "entity": "Link",
                    "arid": "mlw56mvbOy",
                    "url": "http://musicbrainz.org/recording/5e69b823-cfb0-4612-9f56-4b1d72d6b371",
                    "id_component": "5e69b823-cfb0-4612-9f56-4b1d72d6b371",
                    "title": "MusicBrainz recording",
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "type": "service",
                    "provider": "musicbrainz",
                    "external": true
                }
            ]
        },
        "release": {
            "entity": "Release",
            "arid": "mr2ydaP61X",
            "title": "Almost Free",
            "format": null,
            "artwork": [
                {
                    "entity": "Artwork",
                    "arid": "miQ9vlLlDP",
                    "url": "https://www.abc.net.au/triplej/albums/57144/covers/original.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 3000,
                    "height": 3000,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/cupIJJEzOsaPRN4Q2-DtAR6FEpo=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/9CtJ8gbuuQPu_yL2L-XsUZj5D9Q=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/IQDDnLVW3aBct6uqIT2QLZ_3wY8=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/jpZqTvRerl_B4es4wF5oA_KEe3w=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 580,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/xc-QVXNEarQo4GKwDF47FN0clE8=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 940,
                            "height": 940,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/Al_60CNAN4Z5-lH0CcNm7EB5oSQ=/1300x1300/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 1300,
                            "height": 1300,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/fkPz8NS2k7t2P64QleWoAaIGBr4=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/0EFrfoGwa0GZ8Mw2eOiD50KmpV0=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/3Dr1A14ccpc_Ux35TokOiwDfKb8=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/7BaSf2ez1gCA9JCoChcx5fHQMhI=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 326,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/NOJDNyvbLbNmq66YCzQ_d_J9fek=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 940,
                            "height": 529,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/t7JR3Tm9RwtOHQbkgiMyhu-TTSE=/1300x731/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57144%2Fcovers%2Foriginal.jpg",
                            "width": 1300,
                            "height": 731,
                            "aspect_ratio": "16x9"
                        }
                    ]
                },
                {
                    "entity": "Artwork",
                    "arid": "miQeRlj5yg",
                    "url": "https://www.abc.net.au/triplej/albums/57744/covers/original.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 1500,
                    "height": 1500,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/fDgELlxFUCDctZoNEA5O-qcHDEo=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/gzkwqLmnlpRv9kmboHHUpFIQ71A=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/v0AkCmqr1WDTADZrFWpyZBlCKtk=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/Qw1zCK2-Td2yci1Ecjp9qn6gPXA=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 580,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/GzGt1GgWI-NQHl7Z0SBRh_9j9-8=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 940,
                            "height": 940,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/yihmoyhWcxI2m0K6zMzre6QD4DE=/1300x1300/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 1300,
                            "height": 1300,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/AgjvQvrY_Qj83EUNSeZHwHKD75s=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/rwnLVi1GNtk5r3Wlpy4Zqnr2ia4=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/OuGybmzhKOVneSdEg5WjKLazWIg=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/og96PYbk4zQCo9BQYzhXxaItP1M=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 326,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/Xe_wNIi7tlvGRqdnUOg0Xssf9U8=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 940,
                            "height": 529,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/l-aIDqiN77kk45miuJo7vqMyhfE=/1300x731/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57744%2Fcovers%2Foriginal.jpg",
                            "width": 1300,
                            "height": 731,
                            "aspect_ratio": "16x9"
                        }
                    ]
                },
                {
                    "entity": "Artwork",
                    "arid": "miKexd6EQe",
                    "url": "https://www.abc.net.au/triplej/albums/57623/covers/original.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 600,
                    "height": 600,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/mQl6omfdCfwOWb8796Q_IhlX4pI=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/plPlVmIcmSMrkJjDQFRQS-RN-7U=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/UE_Rr2pLtRGmd9pqKiy4XoqJopE=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/eutyv-77o5Jbkwl_b6kiFEmKyQQ=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 580,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/7D8fdvbnczKxINJzr62YEIPKssk=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/9Fb6gskIroGgpDXmlOiCWn3zolI=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/YEsne2g0ZpeCtZtBeyi4LQRLwWI=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/fNz2sCF6nWgHgYPwBdIFC-C73fU=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F57623%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 326,
                            "aspect_ratio": "16x9"
                        }
                    ]
                }
            ],
            "links": [],
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maVYyBp2eq",
                    "name": "FIDLAR",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlBydj1AkV",
                            "url": "http://musicbrainz.org/artist/6910222b-74c2-48fc-8c13-a6b88cc28fc0",
                            "id_component": "6910222b-74c2-48fc-8c13-a6b88cc28fc0",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                }
            ],
            "record_label": null,
            "release_year": "2019",
            "release_album_id": "57744"
        }
    },
    {
        "entity": "Play",
        "arid": "mprPBDOEVL",
        "played_time": "2022-09-01T00:10:45+00:00",
        "service_id": "triplej",
        "recording": {
            "entity": "Recording",
            "arid": "mtQge2wr4K",
            "title": "Free",
            "metadata": null,
            "description": null,
            "duration": 207,
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maAzkra8eJ",
                    "name": "Florence + The Machine",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "ml4yOWzKyB",
                            "url": "http://musicbrainz.org/artist/5fee3020-513b-48c2-b1f7-4681b01db0c6",
                            "id_component": "5fee3020-513b-48c2-b1f7-4681b01db0c6",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                },
                {
                    "entity": "Artist",
                    "arid": "maMYmRQ2eJ",
                    "name": "Florence",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlD5AOER0J",
                            "url": "http://musicbrainz.org/artist/a45dd855-4840-4e5c-be0c-a92116806632",
                            "id_component": "a45dd855-4840-4e5c-be0c-a92116806632",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "performer",
                    "role": null
                },
                {
                    "entity": "Artist",
                    "arid": "maAzn3Od5W",
                    "name": "The Machine",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlV5ElW2yd",
                            "url": "http://musicbrainz.org/artist/69039782-28d6-4db6-8259-56ce18710ebe",
                            "id_component": "69039782-28d6-4db6-8259-56ce18710ebe",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "performer",
                    "role": null
                }
            ],
            "releases": [
                {
                    "entity": "Release",
                    "arid": "mrq5xneVLX",
                    "title": "Free",
                    "format": "Single",
                    "artwork": [
                        {
                            "entity": "Artwork",
                            "arid": "miQ9vDbnoP",
                            "url": "https://www.abc.net.au/triplej/albums/65733/covers/original.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 1000,
                            "height": 1000,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/micbKsQVwU3iaKBmAFd5e_9I6Zw=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/8YGjB3aTo1JVQMtZd9wQBXkapVw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/ETFg9Ckn1YBHreoBRJY-qRx-FF4=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/1VrDGSuhxZSOldTNQMgXWtGY2no=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 580,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/3_6-KZrwQSMm0dRaW2K28e17OKc=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 940,
                                    "height": 940,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/qUfFR39JWawRPrJeC1sCdY7GAxM=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/iWNBd2A6eYSLjSJJRwth0sWtE2Y=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/zmEC6kUkH2mmSOOp1MQFFpiPMJ0=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/xJLQq-nUGXCpgrnmOBiDkm22Rl8=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 580,
                                    "height": 326,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/NV2r-z4SxzRQ4fWKmyOeW0uS8M4=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                                    "width": 940,
                                    "height": 529,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        }
                    ],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlp0BGxary",
                            "url": "http://musicbrainz.org/release-group/6325b156-bae6-448b-a544-a690485dc806",
                            "id_component": "6325b156-bae6-448b-a544-a690485dc806",
                            "title": "MusicBrainz release-group",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "artists": [
                        {
                            "entity": "Artist",
                            "arid": "maAzkra8eJ",
                            "name": "Florence + The Machine",
                            "artwork": [],
                            "links": [
                                {
                                    "entity": "Link",
                                    "arid": "ml4yOWzKyB",
                                    "url": "http://musicbrainz.org/artist/5fee3020-513b-48c2-b1f7-4681b01db0c6",
                                    "id_component": "5fee3020-513b-48c2-b1f7-4681b01db0c6",
                                    "title": "MusicBrainz artist",
                                    "mini_synopsis": null,
                                    "short_synopsis": null,
                                    "medium_synopsis": null,
                                    "type": "service",
                                    "provider": "musicbrainz",
                                    "external": true
                                }
                            ],
                            "is_australian": null,
                            "type": "primary",
                            "role": null
                        },
                        {
                            "entity": "Artist",
                            "arid": "maMYmRQ2eJ",
                            "name": "Florence",
                            "artwork": [],
                            "links": [
                                {
                                    "entity": "Link",
                                    "arid": "mlD5AOER0J",
                                    "url": "http://musicbrainz.org/artist/a45dd855-4840-4e5c-be0c-a92116806632",
                                    "id_component": "a45dd855-4840-4e5c-be0c-a92116806632",
                                    "title": "MusicBrainz artist",
                                    "mini_synopsis": null,
                                    "short_synopsis": null,
                                    "medium_synopsis": null,
                                    "type": "service",
                                    "provider": "musicbrainz",
                                    "external": true
                                }
                            ],
                            "is_australian": null,
                            "type": "performer",
                            "role": null
                        },
                        {
                            "entity": "Artist",
                            "arid": "maAzn3Od5W",
                            "name": "The Machine",
                            "artwork": [],
                            "links": [
                                {
                                    "entity": "Link",
                                    "arid": "mlV5ElW2yd",
                                    "url": "http://musicbrainz.org/artist/69039782-28d6-4db6-8259-56ce18710ebe",
                                    "id_component": "69039782-28d6-4db6-8259-56ce18710ebe",
                                    "title": "MusicBrainz artist",
                                    "mini_synopsis": null,
                                    "short_synopsis": null,
                                    "medium_synopsis": null,
                                    "type": "service",
                                    "provider": "musicbrainz",
                                    "external": true
                                }
                            ],
                            "is_australian": null,
                            "type": "performer",
                            "role": null
                        }
                    ],
                    "record_label": null,
                    "release_year": "2022",
                    "release_album_id": "65733"
                }
            ],
            "artwork": [],
            "links": [
                {
                    "entity": "Link",
                    "arid": "mlD5A4Z19y",
                    "url": "http://musicbrainz.org/recording/21940ab6-ec9e-4bdb-bcec-68c6696e297c",
                    "id_component": "21940ab6-ec9e-4bdb-bcec-68c6696e297c",
                    "title": "MusicBrainz recording",
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "type": "service",
                    "provider": "musicbrainz",
                    "external": true
                }
            ]
        },
        "release": {
            "entity": "Release",
            "arid": "mrq5xneVLX",
            "title": "Free",
            "format": "Single",
            "artwork": [
                {
                    "entity": "Artwork",
                    "arid": "miQ9vDbnoP",
                    "url": "https://www.abc.net.au/triplej/albums/65733/covers/original.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 1000,
                    "height": 1000,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/micbKsQVwU3iaKBmAFd5e_9I6Zw=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/8YGjB3aTo1JVQMtZd9wQBXkapVw=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/ETFg9Ckn1YBHreoBRJY-qRx-FF4=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/1VrDGSuhxZSOldTNQMgXWtGY2no=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 580,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/3_6-KZrwQSMm0dRaW2K28e17OKc=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 940,
                            "height": 940,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/qUfFR39JWawRPrJeC1sCdY7GAxM=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/iWNBd2A6eYSLjSJJRwth0sWtE2Y=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/zmEC6kUkH2mmSOOp1MQFFpiPMJ0=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/xJLQq-nUGXCpgrnmOBiDkm22Rl8=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 580,
                            "height": 326,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/NV2r-z4SxzRQ4fWKmyOeW0uS8M4=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2F65733%2Fcovers%2Foriginal.jpg",
                            "width": 940,
                            "height": 529,
                            "aspect_ratio": "16x9"
                        }
                    ]
                }
            ],
            "links": [
                {
                    "entity": "Link",
                    "arid": "mlp0BGxary",
                    "url": "http://musicbrainz.org/release-group/6325b156-bae6-448b-a544-a690485dc806",
                    "id_component": "6325b156-bae6-448b-a544-a690485dc806",
                    "title": "MusicBrainz release-group",
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "type": "service",
                    "provider": "musicbrainz",
                    "external": true
                }
            ],
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maAzkra8eJ",
                    "name": "Florence + The Machine",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "ml4yOWzKyB",
                            "url": "http://musicbrainz.org/artist/5fee3020-513b-48c2-b1f7-4681b01db0c6",
                            "id_component": "5fee3020-513b-48c2-b1f7-4681b01db0c6",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                },
                {
                    "entity": "Artist",
                    "arid": "maMYmRQ2eJ",
                    "name": "Florence",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlD5AOER0J",
                            "url": "http://musicbrainz.org/artist/a45dd855-4840-4e5c-be0c-a92116806632",
                            "id_component": "a45dd855-4840-4e5c-be0c-a92116806632",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "performer",
                    "role": null
                },
                {
                    "entity": "Artist",
                    "arid": "maAzn3Od5W",
                    "name": "The Machine",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlV5ElW2yd",
                            "url": "http://musicbrainz.org/artist/69039782-28d6-4db6-8259-56ce18710ebe",
                            "id_component": "69039782-28d6-4db6-8259-56ce18710ebe",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "performer",
                    "role": null
                }
            ],
            "record_label": null,
            "release_year": "2022",
            "release_album_id": "65733"
        }
    },
    {
        "entity": "Play",
        "arid": "mpLAVMag4d",
        "played_time": "2022-09-01T00:14:10+00:00",
        "service_id": "triplej",
        "recording": {
            "entity": "Recording",
            "arid": "mtDKpenRaY",
            "title": "Love Calculator",
            "metadata": null,
            "description": null,
            "duration": 158,
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maoe3dMWJz",
                    "name": "Psychic Sally",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlVk8Aar30",
                            "url": "http://musicbrainz.org/artist/8c6bd539-5fc5-4f09-9828-981768ea42e9",
                            "id_component": "8c6bd539-5fc5-4f09-9828-981768ea42e9",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                }
            ],
            "releases": [
                {
                    "entity": "Release",
                    "arid": "mrq5xOO1NA",
                    "title": "Love Calculator",
                    "format": null,
                    "artwork": [
                        {
                            "entity": "Artwork",
                            "arid": "mi7eM2j0xP",
                            "url": "https://www.abc.net.au/triplej/albums/psychicsally-lovecalculatorsingle.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 500,
                            "height": 500,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/MI7zgfZ04_6cN5isUFLoRtGZibU=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/5SHRvCGYN-wvfBS2syQZhWvu_jg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/gKEMfaQoE5RvIQ9Rw-IHQxb65Rw=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/AArp9VcsP28aEUjvGMWGNpqybjg=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/hVRu7DMRPjizn0RD2mikF7LkT_E=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/TGRgQjgRzD9tSn4cyMCBgezNHVw=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        }
                    ],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mllkrYVmo5",
                            "url": "http://musicbrainz.org/release-group/2d30f516-37c2-39f1-b620-9b03e3c83b3d",
                            "id_component": "2d30f516-37c2-39f1-b620-9b03e3c83b3d",
                            "title": "MusicBrainz release-group",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "artists": [
                        {
                            "entity": "Artist",
                            "arid": "maoe3dMWJz",
                            "name": "Psychic Sally",
                            "artwork": [],
                            "links": [
                                {
                                    "entity": "Link",
                                    "arid": "mlVk8Aar30",
                                    "url": "http://musicbrainz.org/artist/8c6bd539-5fc5-4f09-9828-981768ea42e9",
                                    "id_component": "8c6bd539-5fc5-4f09-9828-981768ea42e9",
                                    "title": "MusicBrainz artist",
                                    "mini_synopsis": null,
                                    "short_synopsis": null,
                                    "medium_synopsis": null,
                                    "type": "service",
                                    "provider": "musicbrainz",
                                    "external": true
                                }
                            ],
                            "is_australian": null,
                            "type": "primary",
                            "role": null
                        }
                    ],
                    "record_label": null,
                    "release_year": null,
                    "release_album_id": null
                }
            ],
            "artwork": [],
            "links": [
                {
                    "entity": "Link",
                    "arid": "mlLyel1360",
                    "url": "http://musicbrainz.org/recording/2980e934-1a1c-443a-8b2e-bb3af58cf0ee",
                    "id_component": "2980e934-1a1c-443a-8b2e-bb3af58cf0ee",
                    "title": "MusicBrainz recording",
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "type": "service",
                    "provider": "musicbrainz",
                    "external": true
                }
            ]
        },
        "release": {
            "entity": "Release",
            "arid": "mrq5xOO1NA",
            "title": "Love Calculator",
            "format": null,
            "artwork": [
                {
                    "entity": "Artwork",
                    "arid": "mi7eM2j0xP",
                    "url": "https://www.abc.net.au/triplej/albums/psychicsally-lovecalculatorsingle.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 500,
                    "height": 500,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/MI7zgfZ04_6cN5isUFLoRtGZibU=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/5SHRvCGYN-wvfBS2syQZhWvu_jg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/gKEMfaQoE5RvIQ9Rw-IHQxb65Rw=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/AArp9VcsP28aEUjvGMWGNpqybjg=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/hVRu7DMRPjizn0RD2mikF7LkT_E=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/TGRgQjgRzD9tSn4cyMCBgezNHVw=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fpsychicsally-lovecalculatorsingle.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        }
                    ]
                }
            ],
            "links": [
                {
                    "entity": "Link",
                    "arid": "mllkrYVmo5",
                    "url": "http://musicbrainz.org/release-group/2d30f516-37c2-39f1-b620-9b03e3c83b3d",
                    "id_component": "2d30f516-37c2-39f1-b620-9b03e3c83b3d",
                    "title": "MusicBrainz release-group",
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "type": "service",
                    "provider": "musicbrainz",
                    "external": true
                }
            ],
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maoe3dMWJz",
                    "name": "Psychic Sally",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlVk8Aar30",
                            "url": "http://musicbrainz.org/artist/8c6bd539-5fc5-4f09-9828-981768ea42e9",
                            "id_component": "8c6bd539-5fc5-4f09-9828-981768ea42e9",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                }
            ],
            "record_label": null,
            "release_year": null,
            "release_album_id": null
        }
    },
    {
        "entity": "Play",
        "arid": "mpnNgONEEL",
        "played_time": "2022-09-01T00:17:41+00:00",
        "service_id": "triplej",
        "recording": {
            "entity": "Recording",
            "arid": "mt5YmZ8Jek",
            "title": "Her",
            "metadata": null,
            "description": null,
            "duration": 136,
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maG5rpJMeR",
                    "name": "Megan Thee Stallion",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlA0PpAWO0",
                            "url": "http://musicbrainz.org/artist/ee27b2d8-648c-4a9d-a68c-e55066959975",
                            "id_component": "ee27b2d8-648c-4a9d-a68c-e55066959975",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                }
            ],
            "releases": [
                {
                    "entity": "Release",
                    "arid": "mrbAoYWv1y",
                    "title": "Traumazine",
                    "format": null,
                    "artwork": [
                        {
                            "entity": "Artwork",
                            "arid": "miKexoZBY9",
                            "url": "https://www.abc.net.au/triplej/albums/megantheestallion-traumazine.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 1000,
                            "height": 1000,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/hZD-yHRgr4YvxUqySZirMfk1pAE=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/AIkncwCTcKWk9oYvZRCCJsY_MEg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/KjG2RXoG6-GI3nJTivo5MF4BTN0=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/booVlImNZru7KLLrwvKERn1xP1Q=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 580,
                                    "height": 580,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/dRlfbVPVVSyazDiQ6ysHjnLknzE=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 940,
                                    "height": 940,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/ClTqMXajIqWeVw6K20a1olriJ6Y=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/vZl8enyK0qf0JsCNVPT6R_eC9vU=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/tlhRcsyqp64cHInhGqdovCPIw3g=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/V6VY3SVK7um4jWvjbkYe4feMaIo=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 580,
                                    "height": 326,
                                    "aspect_ratio": "16x9"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/QUrcZoRl1zj1lNzE5jVwOKfIDEk=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                                    "width": 940,
                                    "height": 529,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        },
                        {
                            "entity": "Artwork",
                            "arid": "miKgEa4n4g",
                            "url": "https://abc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com/playlist/f9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                            "type": "cover",
                            "title": null,
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "width": 500,
                            "height": 500,
                            "sizes": [
                                {
                                    "url": "https://resize.abcradio.net.au/ZjQJmiOXyoltr-kQMPkv89Yb86Q=/100x100/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                                    "width": 100,
                                    "height": 100,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/1XSJI35nwUc5SsjSsiVWR_jA1tg=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                                    "width": 160,
                                    "height": 160,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/dvwf9zv639oYHdBqwMxdZ6xSbQc=/340x340/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                                    "width": 340,
                                    "height": 340,
                                    "aspect_ratio": "1x1"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/8KPAzHUexKG9cPh1wGttO5-4zH4=/100x75/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                                    "width": 100,
                                    "height": 75,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/pWNu05LzTZBuZFXtSMF3oQNIIo4=/160x120/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                                    "width": 160,
                                    "height": 120,
                                    "aspect_ratio": "4x3"
                                },
                                {
                                    "url": "https://resize.abcradio.net.au/Y-L_CBFme_8j-0Jc0FWghI2Xj-4=/220x124/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                                    "width": 220,
                                    "height": 124,
                                    "aspect_ratio": "16x9"
                                }
                            ]
                        }
                    ],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlA0mjRvoy",
                            "url": "http://musicbrainz.org/release-group/d20d5d07-bcb1-4aa2-a1a2-d8d2144ae782",
                            "id_component": "d20d5d07-bcb1-4aa2-a1a2-d8d2144ae782",
                            "title": "MusicBrainz release-group",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "artists": [
                        {
                            "entity": "Artist",
                            "arid": "maG5rpJMeR",
                            "name": "Megan Thee Stallion",
                            "artwork": [],
                            "links": [
                                {
                                    "entity": "Link",
                                    "arid": "mlA0PpAWO0",
                                    "url": "http://musicbrainz.org/artist/ee27b2d8-648c-4a9d-a68c-e55066959975",
                                    "id_component": "ee27b2d8-648c-4a9d-a68c-e55066959975",
                                    "title": "MusicBrainz artist",
                                    "mini_synopsis": null,
                                    "short_synopsis": null,
                                    "medium_synopsis": null,
                                    "type": "service",
                                    "provider": "musicbrainz",
                                    "external": true
                                }
                            ],
                            "is_australian": null,
                            "type": "primary",
                            "role": null
                        }
                    ],
                    "record_label": null,
                    "release_year": "2022",
                    "release_album_id": null
                }
            ],
            "artwork": [],
            "links": [
                {
                    "entity": "Link",
                    "arid": "mlD5A4Ll1y",
                    "url": "http://musicbrainz.org/recording/0ee580aa-5012-4b15-928f-668023ed1d33",
                    "id_component": "0ee580aa-5012-4b15-928f-668023ed1d33",
                    "title": "MusicBrainz recording",
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "type": "service",
                    "provider": "musicbrainz",
                    "external": true
                }
            ]
        },
        "release": {
            "entity": "Release",
            "arid": "mrbAoYWv1y",
            "title": "Traumazine",
            "format": null,
            "artwork": [
                {
                    "entity": "Artwork",
                    "arid": "miKexoZBY9",
                    "url": "https://www.abc.net.au/triplej/albums/megantheestallion-traumazine.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 1000,
                    "height": 1000,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/hZD-yHRgr4YvxUqySZirMfk1pAE=/100x100/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/AIkncwCTcKWk9oYvZRCCJsY_MEg=/160x160/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/KjG2RXoG6-GI3nJTivo5MF4BTN0=/340x340/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/booVlImNZru7KLLrwvKERn1xP1Q=/580x580/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 580,
                            "height": 580,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/dRlfbVPVVSyazDiQ6ysHjnLknzE=/940x940/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 940,
                            "height": 940,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/ClTqMXajIqWeVw6K20a1olriJ6Y=/100x75/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/vZl8enyK0qf0JsCNVPT6R_eC9vU=/160x120/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/tlhRcsyqp64cHInhGqdovCPIw3g=/220x124/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/V6VY3SVK7um4jWvjbkYe4feMaIo=/580x326/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 580,
                            "height": 326,
                            "aspect_ratio": "16x9"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/QUrcZoRl1zj1lNzE5jVwOKfIDEk=/940x529/center/middle/https%3A%2F%2Fwww.abc.net.au%2Ftriplej%2Falbums%2Fmegantheestallion-traumazine.jpg",
                            "width": 940,
                            "height": 529,
                            "aspect_ratio": "16x9"
                        }
                    ]
                },
                {
                    "entity": "Artwork",
                    "arid": "miKgEa4n4g",
                    "url": "https://abc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com/playlist/f9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                    "type": "cover",
                    "title": null,
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "width": 500,
                    "height": 500,
                    "sizes": [
                        {
                            "url": "https://resize.abcradio.net.au/ZjQJmiOXyoltr-kQMPkv89Yb86Q=/100x100/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                            "width": 100,
                            "height": 100,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/1XSJI35nwUc5SsjSsiVWR_jA1tg=/160x160/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                            "width": 160,
                            "height": 160,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/dvwf9zv639oYHdBqwMxdZ6xSbQc=/340x340/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                            "width": 340,
                            "height": 340,
                            "aspect_ratio": "1x1"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/8KPAzHUexKG9cPh1wGttO5-4zH4=/100x75/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                            "width": 100,
                            "height": 75,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/pWNu05LzTZBuZFXtSMF3oQNIIo4=/160x120/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                            "width": 160,
                            "height": 120,
                            "aspect_ratio": "4x3"
                        },
                        {
                            "url": "https://resize.abcradio.net.au/Y-L_CBFme_8j-0Jc0FWghI2Xj-4=/220x124/center/middle/https%3A%2F%2Fabc-dn-jjj-rapidplaylist-prod.s3.ap-southeast-2.amazonaws.com%2Fplaylist%2Ff9e1e7a4642cbc61d27f51a98a479b584194.jpg",
                            "width": 220,
                            "height": 124,
                            "aspect_ratio": "16x9"
                        }
                    ]
                }
            ],
            "links": [
                {
                    "entity": "Link",
                    "arid": "mlA0mjRvoy",
                    "url": "http://musicbrainz.org/release-group/d20d5d07-bcb1-4aa2-a1a2-d8d2144ae782",
                    "id_component": "d20d5d07-bcb1-4aa2-a1a2-d8d2144ae782",
                    "title": "MusicBrainz release-group",
                    "mini_synopsis": null,
                    "short_synopsis": null,
                    "medium_synopsis": null,
                    "type": "service",
                    "provider": "musicbrainz",
                    "external": true
                }
            ],
            "artists": [
                {
                    "entity": "Artist",
                    "arid": "maG5rpJMeR",
                    "name": "Megan Thee Stallion",
                    "artwork": [],
                    "links": [
                        {
                            "entity": "Link",
                            "arid": "mlA0PpAWO0",
                            "url": "http://musicbrainz.org/artist/ee27b2d8-648c-4a9d-a68c-e55066959975",
                            "id_component": "ee27b2d8-648c-4a9d-a68c-e55066959975",
                            "title": "MusicBrainz artist",
                            "mini_synopsis": null,
                            "short_synopsis": null,
                            "medium_synopsis": null,
                            "type": "service",
                            "provider": "musicbrainz",
                            "external": true
                        }
                    ],
                    "is_australian": null,
                    "type": "primary",
                    "role": null
                }
            ],
            "record_label": null,
            "release_year": "2022",
            "release_album_id": null
        }
    }
]
  

module.exports = { spotifyMenuItems, hottest100MenuItems, tripleJMenuItems, webIcon, 
                   sideMenuItems, socials, hottest100Results, recentlyPlayedTripleJ,
                   companionInfos, top3Outside3MenuItems
                 };